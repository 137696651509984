<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { type EnumTranslationPath, translateEnum } from "@/base/i18n/i18n.ts";

const props = defineProps<{
  state: string;
  outputPresentCount: number;
  outputAllCount: number;
  enumTranslationPath: EnumTranslationPath<string>;
  tooltipsTranslationPath?: EnumTranslationPath<string>;
  cachedLabels?: Record<string, string>;
}>();

const { t } = useI18n();

const itemState = computed(() => props.state);

const tooltipValue = computed(() => {
  const totalOutputs = `${props.outputAllCount} ${t("processes.output", props.outputAllCount)}`;
  const outputResult = `${props.outputPresentCount} / ${totalOutputs}`;

  if (!props.tooltipsTranslationPath) {
    return `${props.outputAllCount > 0 ? outputResult : ""}`;
  }

  const stateDescription = translateEnum(
    // This is a stupid rule, we should deactivate it sooner or later
    // eslint-disable-next-line no-restricted-syntax
    props.tooltipsTranslationPath,
    itemState.value,
  );

  return `${stateDescription}${props.outputAllCount > 0 ? `\n\n${outputResult}` : ""}`;
});
</script>

<template>
  <div class="flex flex-row gap-4 justify-between items-center">
    <PChip
      v-tooltip.left="{
        value: tooltipValue,
        showDelay: 500,
      }"
      :label="
        cachedLabels?.[itemState] ??
        translateEnum(enumTranslationPath, itemState)
      "
      :pt="{
        root: { class: 'h-6! bg-gray-200!' },
      }"
    />
  </div>
</template>
