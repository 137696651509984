import { getFieldValue } from "@/app/process/components/field/FieldHelper.ts";
import ValueField from "@/app/process/components/field/ValueField.vue";
import { type CellContent } from "@/base/components/filterdatatable/TableTypes.ts";
import {
  FieldType,
  type FieldValueEto,
} from "@/base/graphql/generated/types.ts";
import { i18n as i18nInstance } from "@/base/i18n/i18n.ts";
import { useFieldService } from "@/base/services/FieldService.ts";

export enum DataTableContextKeys {
  personList = "personList",
  messageList = "messageList",
  processActivityList = "processActivityList",
  processList = "processList",
  processTemplateList = "processTemplateList",
  documentParameterList = "documentParameter",
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const { t } = i18nInstance.global;

const i18n = {
  yes: t("boolean.yes"),
  no: t("boolean.no"),
};

export function createFieldCells(entityId?: string | null) {
  if (!entityId) {
    return {};
  }
  const cells: Record<string, CellContent> = {};
  const fields = useFieldService().getFieldKeysWithValues(entityId);

  for (const [key, values] of fields) {
    if (key.type !== FieldType.Tag) {
      const value = values.map((it) =>
        getFieldValue(key.type ?? FieldType.String, it, i18n.yes, i18n.no),
      );

      cells[key.key ?? ""] = {
        content: value,
        component: ValueField,
        type: key.type,
        props: {
          fieldValueId: values.map((it) => it.id),
          targetEntityId: entityId,
          readonly: true,
          showLabel: false,
          wrap: true,
          hasMultipleValues: values.length > 1,
        },
      };
    }
  }

  return cells;
}

export function createFieldCellsByValues(fieldValues: FieldValueEto[]) {
  const groupedFieldValues: Record<string, FieldValueEto[]> =
    fieldValues.reduce((acc, fieldValue) => {
      const entityId = fieldValue.fieldKeyId;
      if (!acc[entityId]) {
        acc[entityId] = [];
      }
      acc[entityId].push(fieldValue);
      return acc;
    }, {});

  return createFieldCellsByValuesForEntity(groupedFieldValues);
}

function createFieldCellsByValuesForEntity(
  values: Record<string, FieldValueEto[]>,
) {
  const cells: Record<string, CellContent> = {};

  Object.entries(values).forEach(([entityId, fieldValues]) => {
    if (!fieldValues) {
      return;
    }

    const fieldValue = fieldValues[0];
    const fieldKey = useFieldService().getFieldKey(fieldValue?.fieldKeyId);
    const keyName = fieldKey?.key ?? "";

    if (fieldKey?.type !== FieldType.Tag) {
      const value = fieldValues.map((it) =>
        getFieldValue(
          fieldKey?.type ?? FieldType.String,
          it,
          i18n.yes,
          i18n.no,
        ),
      );

      cells[keyName] = {
        content: value,
        component: ValueField,
        type: fieldKey?.type,
        props: {
          fieldValueId: fieldValues.map((it) => it.id),
          targetEntityId: entityId,
          readonly: true,
          showLabel: false,
          hasMultipleValues: fieldValues.length > 1,
        },
      };
    }
  });

  return cells;
}
