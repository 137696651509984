import {
  ActivityInputDocument,
  type ActivityInputDto,
  type ActivityInputEto,
  DeleteActivityInputDocument,
} from "@/base/graphql/generated/types.ts";
import {
  defineEntityStore,
  EntityProvider,
} from "@/base/stores/entity/EntityStore.ts";
import { PendingPersistedLookupDual } from "@/base/utils/IdRelationLookups.ts";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual(
    (eto: ActivityInputEto) => ({ id: eto.id }) as ActivityInputDto,
  ),
  {
    queryName: "activityInput",
    mutation: ActivityInputDocument,
    deleteMutation: DeleteActivityInputDocument,
  },
  {
    indexFields: ["mid", "activityId", "outputId"],
  },
);
export const useActivityInputStore = defineEntityStore(
  "activityInput",
  entityProvider,
);
