import {
  DeleteFieldKeyDocument,
  FieldKeyDocument,
  type FieldKeyDto,
  type FieldKeyEto,
  GetFieldKeyDocument,
  GetFieldKeysDocument,
} from "@/base/graphql/generated/types.ts";
import { useHexColorService } from "@/base/services/color/HexColorService.ts";
import {
  defineEntityStore,
  EntityProvider,
} from "@/base/stores/entity/EntityStore.ts";
import { PendingPersistedLookupDual } from "@/base/utils/IdRelationLookups.ts";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual(
    (eto: FieldKeyEto) => ({ id: eto.id }) as FieldKeyDto,
  ),
  {
    queryName: "fieldKey",
    listQuery: GetFieldKeysDocument,
    singleQuery: GetFieldKeyDocument,
    mutation: FieldKeyDocument,
    deleteMutation: DeleteFieldKeyDocument,
  },
  {
    onAfterRegister: (etos: FieldKeyEto[]) => {
      etos.forEach((eto) => {
        if (eto.colorHex) {
          useHexColorService().updateNextColorIndex(eto.colorHex);
        }
      });
    },
  },
);

export const useFieldKeyStore = defineEntityStore("fieldKey", entityProvider);
