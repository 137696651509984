import {
  ActivityOutputDocument,
  type ActivityOutputDto,
  type ActivityOutputEto,
  DeleteActivityOutputDocument,
} from "@/base/graphql/generated/types.ts";
import {
  defineEntityStore,
  EntityProvider,
} from "@/base/stores/entity/EntityStore.ts";
import { PendingPersistedLookupDual } from "@/base/utils/IdRelationLookups.ts";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual(
    (eto: ActivityOutputEto) => ({ id: eto.id }) as ActivityOutputDto,
  ),
  {
    queryName: "activityOutput",
    mutation: ActivityOutputDocument,
    deleteMutation: DeleteActivityOutputDocument,
  },
  {
    indexFields: ["mid", "activityId"],
  },
);

export const useActivityOutputStore = defineEntityStore(
  "activityOutput",
  entityProvider,
);
