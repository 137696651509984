<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  item?: string;
  items?: string;
}>();

const { t } = useI18n();

const loadingText = computed(() => {
  if (props.items) {
    return t("loading.loading", 2, { named: { items: props.items } });
  } else if (props.item) {
    return t("loading.loading", 1, { named: { item: props.item } });
  } else {
    return t("loading.loadingData");
  }
});
</script>

<template>
  <div class="flex flex-row justify-center items-center gap-2">
    <PProgressSpinner class="h-4! w-4!" strokeWidth="7.0" />
    <p class="no-underline">{{ loadingText }}</p>
  </div>
</template>
