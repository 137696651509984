<script lang="ts" setup>
import { v4 as uuidv4 } from "uuid";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import EditFieldEntry from "@/app/process/components/field/action/EditFieldEntry.vue";
import { type EntityType, FieldType } from "@/base/graphql/generated/types.ts";
import { UI_DIALOG, useDialogService } from "@/base/services/DialogService.ts";
import { useFieldService } from "@/base/services/FieldService.ts";

const { t } = useI18n();

const props = defineProps<{
  entityId: string;
  entityType: EntityType;
}>();
const emits = defineEmits<(e: "delete", id: string) => void>();

const fieldService = useFieldService();
const dialogService = useDialogService();
const dialogOpen = computed(() =>
  dialogService.isDialogOpen(UI_DIALOG.EDIT_GENERIC_FIELDS),
);

const fieldItems = computed(() => new Map());

function cancel() {
  dialogService.closeDialog(UI_DIALOG.EDIT_GENERIC_FIELDS);
}

function save() {
  dialogService.closeDialog(UI_DIALOG.EDIT_GENERIC_FIELDS);
}
</script>

<template>
  <VDialog v-model="dialogOpen" maxWidth="600">
    <VCard class="pa-3">
      <VCardTitle
        >{{ t("action.editSomething", { name: t("processes.field", 2) }) }}
      </VCardTitle>
      <VCardText class="overflow-y-auto">
        <div class="d-flex flex-column align-start ga-2">
          <EditFieldEntry
            v-for="key in fieldItems.keys()"
            :key="key"
            :entityId="props.entityId"
            :fieldName="key.toString()"
            :items="fieldItems.get(key)"
            :entityType="props.entityType"
            @delete="(id) => emits('delete', id)"
          />
          <VBtn
            variant="text"
            class="w-100"
            color="caeli5"
            prependIcon="mdi-plus"
            size="small"
            @click="
              () =>
                fieldService.createOrUpdateFieldKey({
                  id: uuidv4(),
                  name: ':',
                  type: FieldType.Tag,
                  entityTypes: [props.entityType],
                })
            "
          >
            {{ t("action.addSomething", { name: t("processes.field") }) }}
          </VBtn>
        </div>
      </VCardText>
      <div class="d-flex flex-column align-center pa-2">
        <VBtn variant="flat" color="caeli5" :block="true" @click="save">
          {{ t("action.save") }}
        </VBtn>
        <VBtn variant="flat" :block="true" @click="cancel">
          {{ t("action.cancel") }}
        </VBtn>
      </div>
    </VCard>
  </VDialog>
</template>

<style>
.v-field__input {
  opacity: 1;
  flex-wrap: wrap;
  font-size: 0.85em;
}

.v-text-field input {
  font-size: 0.85em;
}

.v-field-label {
  font-size: 0.75em;
}
</style>
