<script setup lang="ts">
import { watchDebounced } from "@vueuse/core";
import {
  type DataTableFilterEvent,
  type DataTableFilterMeta,
  type DataTableFilterMetaData,
  type DataTablePageEvent,
  type DataTableRowContextMenuEvent,
  type DataTableRowEditCancelEvent,
  type DataTableRowEditInitEvent,
  type DataTableRowEditSaveEvent,
  type DataTableSortMeta,
} from "primevue/datatable";
import {
  computed,
  onBeforeMount,
  reactive,
  ref,
  useTemplateRef,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";

import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseCard from "@/base/components/card/BaseCard.vue";
import CFilteredDataTableColumnBody from "@/base/components/filterdatatable/table/CFilteredDataTableColumnBody.vue";
import CFilteredDataTableColumnBodyEditor, {
  type ColumnEditorUpdateEvent,
} from "@/base/components/filterdatatable/table/CFilteredDataTableColumnBodyEditor.vue";
import CFilteredDataTableColumnFilter from "@/base/components/filterdatatable/table/CFilteredDataTableColumnFilter.vue";
import CFilteredDataTableHeader from "@/base/components/filterdatatable/table/CFilteredDataTableHeader.vue";
import { isEditableColumn } from "@/base/components/filterdatatable/table/CFilteredDataTableUtils";
import {
  createFilters,
  type DataTableColumn,
  isDataTableFilterMetaData,
} from "@/base/components/filterdatatable/table/CFilteredDataTableUtils.ts";
import { type RowItem } from "@/base/components/filterdatatable/TableTypes.ts";
import {
  mapMatchModeForDto,
  mapOperatorForDto,
  useDataTableFilter,
} from "@/base/components/filterdatatable/useDataTableFilter.ts";
import {
  type FieldKeyDto,
  type FilterEntryDto,
  FilterMatchMode,
  FilterOperator,
  SortDirection,
} from "@/base/graphql/generated/types.ts";

const props = withDefaults(
  defineProps<{
    contextKey: string;
    rowItems: RowItem[];
    exposedColumns?: DataTableColumn[];
    mandatoryColumns: DataTableColumn[];
    availableTags: FieldKeyDto[];
    sortField?: string;
    sortDirection?: SortDirection;
    showGridlines?: boolean;
    isLoading?: boolean;
    flat?: boolean;
    stripedRows?: boolean;
    clientSide?: boolean;
    withEditor?: boolean;
    paginatorPosition?: "top" | "bottom" | "both" | undefined;
  }>(),
  {
    exposedColumns: undefined,
    sortField: undefined,
    sortDirection: undefined,
    paginatorPosition: "both",
    stripedRows: true,
  },
);

const emits = defineEmits<{
  "update:row": [value: ColumnEditorUpdateEvent];
  "delete:row": [value: string | undefined];
}>();

const paginatorTemplate =
  "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown";

const { t } = useI18n();

const contextMenuRef = useTemplateRef("contextMenuRef");
const selectedRow = ref<RowItem | undefined>();
const editingRows = ref<RowItem | undefined>();
const temporaryRowData = ref<Record<string, string>>({});
const selectedColumns = ref<string[]>(
  props.mandatoryColumns.map((item) => item.key ?? item.name) ?? [],
);
const contextMenuItems = computed(() => {
  return [
    ...selectedColumns.value
      .filter((columnKey) => {
        const content = selectedRow.value?.cells[columnKey]?.content;
        const parsedContent = Array.isArray(content) ? content[0] : content;
        return parsedContent !== undefined;
      })
      .map((columnKey) => {
        const displayName = props.exposedColumns?.find(
          (item) => item.key === columnKey,
        )?.name;
        const content = selectedRow.value?.cells[columnKey].content;
        const mappedContent = selectedRow.value?.cells[columnKey].mappedContent;
        const parsedContent = Array.isArray(content) ? content[0] : content;
        const parsedMappedContent = Array.isArray(mappedContent)
          ? mappedContent[0]
          : mappedContent;
        const label = t("table.contextMenu.addToFilter", {
          col: displayName,
          value: parsedMappedContent?.toString() ?? content?.toString(),
        });

        return {
          label,
          icon: "mdi mdi-plus",
          command: () => {
            applyFilter({
              filterEntries: [
                ...(listFilter.value.filterEntries ?? []),
                {
                  technicalKey: columnKey,
                  operator: FilterOperator.And,
                  constraints: [
                    {
                      value: parsedContent,
                      matchMode: FilterMatchMode.Contains,
                    },
                  ],
                },
              ] as FilterEntryDto[],
            });

            initFilterState();
          },
        };
      }),
  ];
});
const { listFilter, totalElements, applyFilter, clear } = useDataTableFilter(
  props.contextKey,
  selectedColumns,
);
const technicalKeys = reactive(new Map<string, string>());
const filters = ref<DataTableFilterMeta>({});
const globalFilterFields = computed(() => [
  ...selectedColumns.value.map((item) => `cells.${item}.content`),
]);
const isPaginationAvailable = computed(
  () =>
    listFilter?.value?.pagination?.pageNumber !== undefined &&
    listFilter?.value?.pagination?.pageSize !== undefined,
);
const sort = computed<DataTableSortMeta[]>(() => {
  return (
    listFilter.value.sort?.map((item) => ({
      field: `cells.${item.key}.content`,
      order: item.direction === SortDirection.Asc ? 1 : -1,
    })) ?? []
  );
});

watch(
  [() => props.exposedColumns, () => props.contextKey],
  () => {
    initFilterState();
  },
  { immediate: true },
);

watchDebounced(
  () => (filters.value.global as DataTableFilterMetaData)?.value,
  () => {
    applyFilter({
      fullTextSearch: (filters.value.global as DataTableFilterMetaData)
        ?.value as string,
    });
  },
  { debounce: 500 },
);

onBeforeMount(() => {
  if (listFilter.value.sort?.length === 0 && props.sortField) {
    applyFilter({
      sort: [
        {
          key: props.sortField,
          direction: props.sortDirection ?? SortDirection.Asc,
        },
      ],
    });
  }
});

function initFilterState() {
  filters.value = {
    global: { value: listFilter.value.fullTextSearch, matchMode: "contains" },
    ...createFilters(
      props.exposedColumns ?? [],
      technicalKeys,
      listFilter.value.filterEntries,
    ),
  };
}

function onPage(event: DataTablePageEvent) {
  applyFilter({
    pagination: {
      pageNumber: event.page,
      pageSize: event.rows,
    },
  });
}

function onFilter(event: DataTableFilterEvent) {
  const activeFilters = [...Object.entries(event.filters)]
    .map(([key, value]) => {
      if (typeof value !== "object") {
        return {};
      }

      if (isDataTableFilterMetaData(value)) {
        return {};
      }

      if (value.constraints.every((constraint) => !constraint.value)) {
        return {};
      }

      return {
        technicalKey: technicalKeys.get(key),
        operator: mapOperatorForDto(value.operator),
        constraints: value.constraints.map((constraint) => ({
          value: constraint.value,
          matchMode: mapMatchModeForDto(constraint.matchMode ?? "contains"),
        })),
      };
    })
    .filter((object) => Object.keys(object).length !== 0);

  applyFilter({
    filterEntries: activeFilters as FilterEntryDto[],
  });
}

function onMultiSort(event: DataTableSortMeta[] | null | undefined) {
  if (!event) {
    return;
  }

  applyFilter({
    sort: event
      .map((sortMeta) => {
        const key = technicalKeys.get(
          (sortMeta.field as string | undefined) ?? "",
        );

        if (!key) {
          return { key: undefined, direction: SortDirection.None };
        }

        const direction =
          sortMeta.order === 1 ? SortDirection.Asc : SortDirection.Desc;

        return { key, direction };
      })
      .filter((item) => item.key),
  });
}

function onApplyFilterDialog(applyFilterCallback: () => void) {
  applyFilterCallback();
  (document?.activeElement as HTMLElement)?.blur();
}

function onRowContextMenu(event: DataTableRowContextMenuEvent) {
  contextMenuRef.value?.show(event.originalEvent);
}

function onRowEditInit(event: DataTableRowEditInitEvent) {
  editingRows.value = event.data as RowItem;
}

function onRowEditCancel(event: DataTableRowEditCancelEvent) {
  editingRows.value = undefined;
  Object.entries(temporaryRowData.value).forEach(([key, value]) => {
    event.data.cells[key].content = value;
  });
  temporaryRowData.value = {};
}

function onRowTemporaryEdit(
  column: string,
  rowItem: RowItem,
  event: ColumnEditorUpdateEvent,
) {
  const tempContent = rowItem.cells[column].content as string | undefined;

  if (!tempContent) {
    return;
  }

  temporaryRowData.value = {
    ...temporaryRowData.value,
    [column]: tempContent,
  };

  rowItem.cells[column].content = event[column];
}

function onRowEditSave(event: DataTableRowEditSaveEvent) {
  const { data } = event;
  const editKeys = Object.keys(data.cells)
    .map((columnKey) => {
      const fittingColumn = props.exposedColumns?.find(
        (item) => item.key === columnKey,
      );

      if (!isEditableColumn(fittingColumn)) {
        return undefined;
      }

      return {
        columnKey,
        editKey: fittingColumn?.editKey,
      };
    })
    .filter((item) => item?.editKey);

  const updatedData = editKeys.reduce(
    (acc, keyPair) => {
      if (!keyPair) {
        return acc;
      }
      acc[keyPair.editKey] = data.cells[keyPair.columnKey].content;
      return acc;
    },
    {} as Record<string, string>,
  );

  emits("update:row", {
    id: data.key,
    ...updatedData,
  });

  editingRows.value = undefined;
  temporaryRowData.value = {};
}
</script>

<template>
  <BaseCard class="text-[13px]" flat>
    <template #subtitle>
      <CFilteredDataTableHeader
        :selectedColumns="selectedColumns"
        :listFilter="listFilter"
        :contextKey="props.contextKey"
        :exposedColumns="props.exposedColumns ?? []"
        :mandatoryColumns="props.mandatoryColumns"
        :availableTags="props.availableTags"
        :filters="filters"
        @update:filters="applyFilter"
        @update:selectedColumns="selectedColumns = $event"
        @clear:filters="() => clear(initFilterState)"
        @update:fullTextSearch="
          (value) => {
            filters.global = { value, matchMode: 'contains' };
          }
        "
      >
        <template #additionalControls>
          <slot name="additionalControls" />
        </template>
      </CFilteredDataTableHeader>
    </template>

    <div class="h-1 scale-y-10">
      <PProgressbar v-if="isLoading" mode="indeterminate" />
    </div>

    <PContextMenu
      ref="contextMenuRef"
      :model="contextMenuItems"
      @hide="selectedRow = undefined"
    />

    <PDataTable
      v-model:filters="filters"
      v-model:contextMenuSelection="selectedRow"
      removableSort
      filterDisplay="menu"
      size="small"
      scrollable
      sortMode="multiple"
      rowHover
      contextMenu
      editMode="row"
      :editingRows="[editingRows]"
      :paginatorTemplate="paginatorTemplate"
      :currentPageReportTemplate="`${t('table.entries', !!totalElements ? totalElements : rowItems.length)}`"
      :stripedRows="props.stripedRows"
      :showGridlines="showGridlines"
      :lazy="!clientSide && isPaginationAvailable"
      :paginator="isPaginationAvailable"
      :paginatorPosition="props.paginatorPosition"
      :totalRecords="
        clientSide !== true ? (totalElements ?? rowItems.length) : undefined
      "
      :pageLinkSize="5"
      :rows="listFilter.pagination?.pageSize ?? rowItems.length"
      :rowsPerPageOptions="[10, 25, 50]"
      :loading="props.isLoading"
      :multiSortMeta="props.rowItems.length > 0 ? sort : []"
      :value="props.rowItems"
      :first="
        listFilter.pagination && isPaginationAvailable
          ? listFilter.pagination.pageNumber * listFilter.pagination.pageSize
          : 0
      "
      :globalFilterFields="globalFilterFields"
      :pt="{
        mask: { class: 'opacity-0!' },
        bodyRow: {
          'data-testid': 'filtered-data-table-row',
        },
      }"
      @rowContextmenu="onRowContextMenu"
      @page="onPage"
      @filter="onFilter"
      @update:multiSortMeta="onMultiSort"
      @rowEditInit="onRowEditInit"
      @rowEditCancel="onRowEditCancel"
      @rowEditSave="onRowEditSave"
    >
      <template #empty>
        {{ t("table.noResult") }}
      </template>

      <template
        v-for="(column, index) in selectedColumns.map((item) =>
          props.exposedColumns?.find((col) => col.key === item),
        )"
      >
        <PColumn
          v-if="column"
          :key="column.key"
          class="min-w-[20rem]"
          :class="!column.dynamicWidth && 'max-w-[20rem]'"
          :field="`cells.${column.key ?? column.name}.content`"
          :sortable="column.isSortable ?? true"
          :header="column.name"
          :maxConstraints="5"
          :showFilterMenu="column.isFilterable ?? true"
          :pt="{
            columnHeaderContent: {
              class: 'h-6 text-[#3C707B] text-nowrap',
            },
            columnTitle: { class: 'grow' },
          }"
        >
          <template #filtericon>
            <span
              class="mdi text-h6 mt-1"
              :class="
                listFilter?.filterEntries?.some(
                  (entry) => column.key === entry.technicalKey,
                )
                  ? 'mdi-filter-menu text-black!'
                  : 'mdi-filter-menu-outline'
              "
            />
          </template>

          <template #body="{ data }">
            <CFilteredDataTableColumnBody
              :data="data"
              :column="column.key ?? column.name"
            />
          </template>

          <template v-if="isEditableColumn(column)" #editor="{ data }">
            <CFilteredDataTableColumnBodyEditor
              :data="data"
              :column="column"
              @update="
                (event) =>
                  onRowTemporaryEdit(column.key ?? column.name, data, event)
              "
            />
          </template>

          <template #filter="{ filterModel, applyFilter: applyPrimeVueFilter }">
            <CFilteredDataTableColumnFilter
              v-model="filterModel.value"
              :options="column.options"
              @keydown.enter.prevent="onApplyFilterDialog(applyPrimeVueFilter)"
            />
          </template>
        </PColumn>

        <PColumn
          v-if="
            props.withEditor && column && index === selectedColumns.length - 1
          "
          :key="`${column.key}-rowEditor`"
          rowEditor
        >
          <template #body="{ data, editorInitCallback }">
            <div v-if="!editingRows" class="flex flex-row">
              <BaseButton
                icon="mdi mdi-pencil"
                text
                severity="secondary"
                @click="editorInitCallback"
              />

              <BaseButton
                icon="mdi mdi-delete"
                text
                severity="danger"
                @click="emits('delete:row', data.key)"
              />
            </div>
          </template>

          <template #editor="{ editorSaveCallback, editorCancelCallback }">
            <div class="flex flex-row">
              <BaseButton
                icon="mdi mdi-content-save-outline"
                text
                @click="editorSaveCallback"
              />

              <BaseButton
                icon="mdi mdi-close"
                text
                severity="secondary"
                @click="editorCancelCallback"
              />
            </div>
          </template>
        </PColumn>
      </template>
    </PDataTable>
  </BaseCard>
</template>
