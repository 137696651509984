<script lang="ts" setup>
import { computed } from "vue";

import OptionField from "@/app/process/components/field/OptionField.vue";
import ReadonlyField from "@/app/process/components/field/ReadonlyField.vue";
import TextField from "@/base/components/form/value/TextField.vue";
import {
  type FieldKeyEto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  fieldKey: FieldKeyEto;
  fieldValue: FieldValueDto;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
}>();

const emit = defineEmits<(e: "update", value: FieldValueDto) => void>();

const isOptionField = computed(() => (props.fieldKey.options?.length ?? 0) > 0);
</script>

<template>
  <ReadonlyField
    v-if="props.readonly"
    :label="props.label"
    :value="props.fieldValue?.value?.valueString ?? ''"
  />
  <OptionField
    v-else-if="isOptionField"
    :fieldKey="props.fieldKey"
    :fieldValue="props.fieldValue"
    :label="props.label"
    itemTitle="valueString"
    @update="
      (value) => emit('update', { ...props.fieldValue, optionId: value })
    "
  />
  <TextField
    v-else
    :initialValue="props.fieldValue?.value?.valueString ?? undefined"
    :label="props.fieldKey.name ?? ''"
    data-testid="string-field-input"
    @update="
      (value) =>
        emit('update', {
          ...props.fieldValue,
          value: {
            valueString: value,
          },
        })
    "
  />
</template>
