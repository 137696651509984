<script setup lang="ts">
import { v4 as uuidv4 } from "uuid";
import { computed } from "vue";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import FieldByOutputType from "@/app/process/components/output/FieldByOutputType.vue";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import { type ProcessOutputDto } from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  processActivityId: string;
  activityOutputId: string;
  showLabel?: boolean;
}>();

const activityService = useActivityService();
const processService = useProcessService();

const processOutput = computed(() => {
  return processService.getProcessOutput(
    props.processActivityId,
    props.activityOutputId,
  );
});

const activityOutput = computed(() => {
  return activityService.getOutput(props.activityOutputId);
});

async function updateProcessOutputValue(
  valuePartial: Partial<ProcessOutputDto>,
) {
  await processService.createOrUpdateOutput({
    id: processOutput.value?.id ?? uuidv4(),
    ...valuePartial,
    processActivityId: props.processActivityId,
    activityOutputId: props.activityOutputId,
  });
}

async function deleteOutput() {
  if (!processOutput.value) {
    return;
  }
  const id = processOutput.value.id;
  await processService.deleteOutput(id);
}
</script>

<template>
  <div v-if="activityOutput" class="d-flex align-center ga-4 w-100">
    <div
      v-if="props.showLabel"
      class="text-caption text-right"
      style="min-width: 8rem"
    >
      {{ activityOutput.name }}
    </div>
    <div class="d-flex justify-space-between ga-1 w-100">
      <FieldByOutputType
        :contextKey="`${processActivityId}.${activityOutputId}`"
        :outputType="activityOutput.type!"
        :processOutputDto="processOutput"
        @change="(changedFields) => updateProcessOutputValue(changedFields)"
        @delete="deleteOutput"
      />
    </div>
  </div>
</template>
