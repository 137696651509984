<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import RenameTagEntry from "@/app/process/components/field/action/RenameTagEntry.vue";
import { type EntityType } from "@/base/graphql/generated/types.ts";
import { UI_DIALOG, useDialogService } from "@/base/services/DialogService.ts";
import { useFieldService } from "@/base/services/FieldService.ts";

const props = defineProps<{
  entityType?: EntityType;
}>();
const emits = defineEmits<(e: "delete", id: string) => void>();

const { t } = useI18n();

const fieldService = useFieldService();
const dialogService = useDialogService();

const dialogOpen = computed(() =>
  dialogService.isDialogOpen(UI_DIALOG.RENAME_TAG),
);

const availableTags = computed(() => {
  return fieldService.getTagFieldKeys(props.entityType);
});

function close() {
  dialogService.closeDialog(UI_DIALOG.RENAME_TAG);
}
</script>

<template>
  <VDialog v-model="dialogOpen" maxWidth="600">
    <VCard class="pa-3">
      <VCardTitle>
        {{ t("action.renameSomething", { name: t("processes.tag", 2) }) }}
      </VCardTitle>
      <VCardText>
        <div class="d-flex flex-column ga-3">
          <RenameTagEntry
            v-for="fieldKey in availableTags"
            :key="fieldKey.id"
            :fieldKey="fieldKey"
            @delete="(id) => emits('delete', id)"
          />
        </div>
      </VCardText>
      <div class="d-flex flex-column align-center pa-2">
        <VBtn
          variant="flat"
          block
          data-testid="rename-tags-cancel"
          @click="close"
        >
          {{ t("action.close") }}
        </VBtn>
      </div>
    </VCard>
  </VDialog>
</template>
