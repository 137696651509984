<script setup lang="ts">
import { computed, onBeforeMount } from "vue";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import UpgradeProcessDialog from "@/app/process/components/action/upgrade/UpgradeProcessDialog.vue";
import ProcessSingleViewContent from "@/app/process/components/singleview/ProcessSingleViewContent.vue";
import ProcessSingleViewHeader from "@/app/process/components/singleview/ProcessSingleViewHeader.vue";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import BaseCard from "@/base/components/card/BaseCard.vue";

export interface ProcessSingleViewProps {
  processId: string;
}

const props = defineProps<ProcessSingleViewProps>();

const activityService = useActivityService();
const processService = useProcessService();

const process = computed(() => {
  return processService.getProcess(props.processId);
});

const rootActivity = computed(() => {
  if (!process.value?.startActivityId) {
    return undefined;
  }
  return activityService.getActivity(process.value.startActivityId);
});

onBeforeMount(() => {
  processService.markProcessRefetch(props.processId);
});
</script>

<template>
  <UpgradeProcessDialog :processId="props.processId" />

  <div class="flex flex-col gap-3">
    <BaseCard v-if="process" class="text-sm" :rounded="false" noShadow>
      <template #title>
        <ProcessSingleViewHeader :process />
      </template>

      <template #subtitle>
        <div class="flex flex-row gap-4 items-center">
          <RouterLink
            :style="{ color: 'inherit' }"
            :to="{
              name: 'processTemplate',
              params: { rootActivityId: rootActivity?.id },
            }"
          >
            <p class="text-sm">{{ rootActivity?.name }}</p>
          </RouterLink>
        </div>
      </template>

      <ProcessSingleViewContent :process />
    </BaseCard>
  </div>
</template>
