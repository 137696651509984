<script setup lang="ts">
import { type CardPassThroughOptions } from "primevue";
import { computed } from "vue";

export interface CCardProps {
  title?: string;
  subtitle?: string;
  rounded?: boolean;
  flat?: boolean;
  noShadow?: boolean;
  noPadding?: boolean;
  pt?: CardPassThroughOptions;
}

const props = withDefaults(defineProps<CCardProps>(), {
  title: undefined,
  subtitle: undefined,
  rounded: true,
  flat: false,
  noShadow: false,
  pt: undefined,
});

const rootClasses = computed(() => {
  const rounded = props.rounded ? "rounded-md" : "rounded-0";
  const flat = props.flat ? "shadow-none!" : "";
  const noShadow = props.noShadow ? "shadow-none!" : "";
  return [rounded, flat, noShadow].join(" ");
});

const bodyClasses = computed(() => {
  const flat = props.flat ? "shadow-none! p-0!" : "";
  const noPadding = props.noPadding ? "py-0!" : "";
  return [flat, noPadding].join(" ");
});
</script>

<template>
  <PCard
    :pt="{
      ...pt,
      root: { class: rootClasses },
      body: { class: bodyClasses },
    }"
  >
    <template #title>
      <slot name="title">
        <p v-if="title" class="text-sm font-bold">{{ title }}</p>
      </slot>
    </template>

    <template #subtitle>
      <slot name="subtitle">
        <p v-if="subtitle" class="text-sm">{{ subtitle }}</p>
      </slot>
    </template>

    <template #content>
      <slot />
    </template>

    <template #footer>
      <slot name="footer" />
    </template>
  </PCard>
</template>
