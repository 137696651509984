import { useDebounceFn } from "@vueuse/core";

import {
  type ActivityOutputDto,
  ActivityOutputType,
  type ProcessOutputDto,
} from "@/base/graphql/generated/types.ts";

export const PROCESS_START_DATE_OUTPUT_NAME = "Startdatum";

function checkNotUndefinedOrNull(value: unknown) {
  return value !== undefined && value !== null;
}

export function isValueProvided(
  activityOutput: ActivityOutputDto,
  processOutput?: ProcessOutputDto,
): boolean {
  const outputValue = processOutput?.value;

  const valueCheckMap: Record<
    ActivityOutputType,
    (value: ProcessOutputDto["value"]) => boolean
  > = {
    [ActivityOutputType.String]: (value) =>
      checkNotUndefinedOrNull(value?.valueString),
    [ActivityOutputType.File]: (value) =>
      checkNotUndefinedOrNull(value?.valueEntityId),
    [ActivityOutputType.Document]: (value) =>
      checkNotUndefinedOrNull(value?.valueEntityId),
    [ActivityOutputType.Boolean]: (value) =>
      checkNotUndefinedOrNull(value?.valueBoolean),
    [ActivityOutputType.Number]: (value) =>
      checkNotUndefinedOrNull(value?.valueNumber),
    [ActivityOutputType.Date]: (value) =>
      checkNotUndefinedOrNull(value?.valueDate),
    [ActivityOutputType.DateTime]: (value) =>
      checkNotUndefinedOrNull(value?.valueDateTime),
    [ActivityOutputType.Url]: (value) =>
      checkNotUndefinedOrNull(value?.valueString),
    [ActivityOutputType.Person]: (value) =>
      checkNotUndefinedOrNull(value?.valueEntityId),
    [ActivityOutputType.People]: (value) =>
      !!value?.valueEntityIds?.every(checkNotUndefinedOrNull),
    [ActivityOutputType.Json]: (value) =>
      checkNotUndefinedOrNull(value?.valueJson),
  };
  if (activityOutput.type === undefined || activityOutput.type === null) {
    return false;
  } else {
    const valueChecker = valueCheckMap[activityOutput.type];

    // Call the appropriate checker for this type, or return false if not found
    return valueChecker ? valueChecker(outputValue) : false;
  }
}

export function compareByName<T extends { name?: string | null }>(a: T, b: T) {
  return (a.name ?? "").localeCompare(b.name ?? "");
}

export function compareBySortOrder<T extends { sortOrder?: number | null }>(
  a: T,
  b: T,
) {
  return (a.sortOrder ?? -1) - (b.sortOrder ?? -1);
}

const iconByOutputType = {
  [ActivityOutputType.String]: "mdi-format-text",
  [ActivityOutputType.File]: "mdi-file",
  [ActivityOutputType.Boolean]: "mdi-thumbs-up-down",
  [ActivityOutputType.Number]: "mdi-numeric",
  [ActivityOutputType.Date]: "mdi-calendar",
  [ActivityOutputType.DateTime]: "mdi-calendar-clock",
  [ActivityOutputType.Url]: "mdi-link",
  [ActivityOutputType.Person]: "mdi-account",
  [ActivityOutputType.Document]: "mdi-file-document-multiple",
  [ActivityOutputType.Json]: "mdi-code-json",
};

export function iconifyType(type: ActivityOutputType) {
  return iconByOutputType[type] ?? "mdi-help";
}

export function uniqueById<T extends { id: string }>(
  item: T,
  index: number,
  self: T[],
) {
  return index === self.findIndex((t) => t.id === item.id);
}

export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export interface ListDebouncerConfig {
  bufferTime: number;
}

export class ListDebouncer<T> {
  private readonly debouncedFunction;
  // This is only undefined because Typescript fails to correctly infer the type
  // This is just for programming safety!
  private entityList: T[] | undefined = Array.of();

  constructor(config?: ListDebouncerConfig) {
    this.debouncedFunction = useDebounceFn((cb) => {
      if (!this.entityList || this.entityList?.length === 0) {
        return;
      }

      cb(this.entityList);

      this.entityList = Array.of();
    }, config?.bufferTime ?? 300);
  }

  add(value: T) {
    this.entityList?.push(value);
  }

  run(cb: (ids: T[]) => void) {
    return this.debouncedFunction(cb);
  }
}

export function withoutTypename<E>(obj: E & { __typename?: string }): E {
  const newObj = { ...obj };
  delete newObj.__typename;
  return newObj;
}
