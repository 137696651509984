<script setup lang="ts">
import { ref, useTemplateRef, watch } from "vue";

import { useValidators } from "@/base/components/form/validators.ts";

const props = defineProps<{
  initialValue?: string;
  name?: string;
  readonly?: boolean;
}>();
const emits = defineEmits<(event: "update", value: string) => void>();
const lastEmittedValue = ref<string | undefined>(props.initialValue ?? "");

const { webUrl, isWebUrl } = useValidators();

const textField = useTemplateRef("textField");

watch(
  () => props.initialValue,
  (current) => {
    valueString.value = current ?? "";
    lastEmittedValue.value = current ?? "";

    if (props.initialValue == null) {
      resetValidation();
    }
  },
);

const valueString = ref(props.initialValue ?? "");
const checkUrlAndEmit = () => {
  if (!isWebUrl(valueString.value)) {
    return;
  }

  if (lastEmittedValue.value === valueString.value) {
    return;
  }

  emits("update", valueString.value);
  lastEmittedValue.value = valueString.value;
};

function resetValidation() {
  if (textField.value) {
    textField.value.reset();
  }
}
</script>

<template>
  <VTextField
    ref="textField"
    v-model="valueString"
    class="w-100 rounded-lg"
    variant="outlined"
    density="compact"
    :rules="[webUrl]"
    hideDetails="auto"
    :readonly="props.readonly"
    @blur="checkUrlAndEmit"
  />
</template>
