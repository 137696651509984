<script lang="ts" setup>
import { computed, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import TagsDisplay from "@/app/process/components/field/TagsDisplay.vue";
import ProcessInputPanel from "@/app/process/components/input/ProcessInputPanel.vue";
import ProcessOutputCard from "@/app/process/components/output/ProcessOutputCard.vue";
import ProcessTaskCard from "@/app/process/components/task/ProcessTaskCard.vue";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import EntityFieldsCard from "@/base/components/fields/EntityFieldsCard.vue";
import TextDisplay from "@/base/components/form/TextDisplay.vue";
import { useTitle } from "@/base/composables/useTitle.ts";
import {
  type ActivityOutputEto,
  type ActivityTaskEto,
  EntityType,
  ProcessActivityState,
} from "@/base/graphql/generated/types.ts";
import { compareBySortOrder } from "@/base/services/utils.ts";

const props = defineProps<{
  processId: string;
  processActivityId: string;
}>();

const { t } = useI18n();
const activityService = useActivityService();
const processService = useProcessService();

useTitle(t("processes.activity", 1));

onBeforeMount(() => {
  processService.markProcessActivityRefetch(props.processActivityId);
  processService.markProcessRefetch(props.processId);
});

const process = computed(() => {
  return processService.getProcess(props.processId);
});

const processActivity = computed(() => {
  return processService.getProcessActivity(props.processActivityId);
});

const isProcessActivitySkipped = computed(
  () => processActivity.value?.status.state === ProcessActivityState.Skipped,
);

const activity = computed(() => {
  return activityService.getActivity(
    processActivity.value?.activity.id ?? "undefined",
  );
});

const activityInputs = computed(() => {
  return activityService.getInputs(
    processActivity.value?.activity.id ?? "undefined",
  );
});

const activityTasks = computed(() => {
  return activityService
    .getTasks(processActivity.value?.activity.id ?? "undefined")
    .slice()
    .sort(compareBySortOrder);
});

const activityOutputs = computed(() => {
  return activityService.getOutputs(
    processActivity.value?.activity.id ?? "undefined",
  );
});

const sortedTasksAndOutputs = computed(
  (): (ActivityOutputEto | ActivityTaskEto)[] => {
    return [...activityTasks.value, ...activityOutputs.value].sort(
      compareBySortOrder,
    );
  },
);

const togglingSkippedState = ref(false);

function toggleSkippedState() {
  if (!activity.value) {
    return;
  }

  togglingSkippedState.value = true;

  processService
    .createOrUpdateProcessActivity({
      id: props.processActivityId,
      activityId: activity.value?.id,
      processId: props.processId,
      skipped: !isProcessActivitySkipped.value,
    })
    .catch((reason) => console.error(reason))
    .finally(() => (togglingSkippedState.value = false));
}
</script>

<template>
  <div
    v-if="
      process !== undefined &&
      activity !== undefined &&
      processActivity !== undefined
    "
  >
    <div class="d-flex flex-column ga-3">
      <div class="title-bar">
        <RouterLink
          class="process-title"
          :style="{ color: 'inherit' }"
          :to="{ name: 'process', params: { processId: process.id } }"
        >
          <p class="text-subtitle-1">
            {{ process.name }}
          </p>
        </RouterLink>
        <p class="activity-title text-h5">
          {{ activity.name }}
        </p>
        <VTooltip
          :text="
            isProcessActivitySkipped
              ? t('processes.singleView.unsetSkipped')
              : t('processes.singleView.setSkipped')
          "
          bottom
        >
          <template #activator="{ props: activator }">
            <VBtn
              v-bind="activator"
              class="activity-actions"
              variant="plain"
              size="medium"
              icon="mdi-cancel"
              :loading="togglingSkippedState"
              :color="isProcessActivitySkipped ? 'caeli5' : undefined"
              @click="toggleSkippedState"
            />
          </template>
        </VTooltip>
        <TagsDisplay
          class="activity-tags mt-1"
          :entityId="processActivity.activity.id"
          justify="start"
          density="compact"
        />
      </div>

      <VCard class="bg-white border-card">
        <VExpansionPanels :multiple="true" variant="accordion">
          <ProcessInputPanel
            :activityInputs="activityInputs"
            :processActivity="processActivity"
          />
          <VExpansionPanel elevation="0" class="border-b rounded-0">
            <VExpansionPanelTitle
              class="text-caeli6"
              data-testid="entity-fields-panel"
            >
              <VIcon icon="mdi-tag-text-outline" size="small" class="mr-2" />
              {{ t("processes.field", 2) }}
            </VExpansionPanelTitle>
            <VExpansionPanelText>
              <div class="d-flex flex-column ga-4">
                <EntityFieldsCard
                  :entityId="processActivityId"
                  :ancestorIds="[
                    process.startActivityId,
                    process.id,
                    activity.id,
                  ]"
                  :entityType="[EntityType.Activity]"
                />
              </div>
            </VExpansionPanelText>
          </VExpansionPanel>
        </VExpansionPanels>
        <div
          v-if="activity.description || activity.goal"
          class="d-flex pa-2 border-b flex-column"
        >
          <TextDisplay
            class="px-4 py-2"
            style="min-width: 20rem"
            :label="t('processes.singleView.goalLabel')"
            :value="activity.goal ?? ''"
            markdown
          />
          <TextDisplay
            class="px-4 py-2"
            style="min-width: 20rem"
            :label="t('processes.singleView.descriptionLabel')"
            :value="activity.description ?? ''"
            markdown
          />
        </div>
        <div class="pa-3 ga-3 d-flex flex-column">
          <div v-if="sortedTasksAndOutputs.length">
            <div class="d-flex flex-column ga-3">
              <VCard
                v-for="taskOrOutput in sortedTasksAndOutputs"
                :key="taskOrOutput.id"
                variant="flat"
                class="pa-2"
              >
                <ProcessOutputCard
                  v-if="'type' in taskOrOutput"
                  :processActivityId="props.processActivityId"
                  :activityOutputId="taskOrOutput.id"
                  :customCaption="
                    activityService.isRoot(activity.id)
                      ? t('processes.startParameter')
                      : undefined
                  "
                />
                <div v-else>
                  <ProcessTaskCard
                    :activityTask="taskOrOutput"
                    :processActivityId="props.processActivityId"
                  />
                </div>
              </VCard>
            </div>
          </div>
        </div>
      </VCard>
    </div>
  </div>
</template>

<style scoped>
.title-bar {
  margin-top: 1rem;
  display: grid;
  grid-template-areas:
    ". processTitle ."
    ". activityTitle activityActions"
    ". activityTags .";
  grid-template-columns: 1fr 90% 1fr;
}

.process-title {
  grid-area: processTitle;
  justify-self: center;
}

.activity-title {
  grid-area: activityTitle;
  justify-self: center;
}

.activity-actions {
  grid-area: activityActions;
  justify-self: end;
  margin-right: 1rem;
}

.activity-tags {
  grid-area: activityTags;
  justify-self: center;
}
</style>
