import {
  type FieldKeyDto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";

export interface EntityFieldsCardItem {
  key: FieldKeyDto;
  value: FieldValueDto;
  readonly: boolean;
}
