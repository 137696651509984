<script setup lang="ts">
import { useStorage } from "@vueuse/core";
import { v4 as uuidv4 } from "uuid";
import { computed, ref, useTemplateRef } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import { EditorMode } from "@/app/graphviewer/types/EditorMode.ts";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import BaseTieredMenu from "@/base/components/tieredmenu/BaseTieredMenu.vue";
import { useTitle } from "@/base/composables/useTitle.ts";
import {
  EntityType,
  type ProcessDto,
  ProcessState,
} from "@/base/graphql/generated/types.ts";
import { UI_DIALOG, useDialogService } from "@/base/services/DialogService.ts";
import { useFieldService } from "@/base/services/FieldService.ts";
import { StorageKeys } from "@/config.ts";

export interface ProcessSingleViewHeaderProps {
  process: ProcessDto;
}

const props = defineProps<ProcessSingleViewHeaderProps>();

const { t } = useI18n();
const router = useRouter();
const processService = useProcessService();
const activityService = useActivityService();
const fieldService = useFieldService();
const dialogService = useDialogService();

const isLoading = ref<boolean>(false);

const rootActivity = computed(() => {
  if (!props.process?.startActivityId) {
    return undefined;
  }
  return activityService.getActivity(props.process?.startActivityId);
});

const isProcessUpgradeAvailable = computed(() => {
  return (
    rootActivity.value &&
    rootActivity.value.id !==
      activityService.getLastReleasedVersion(rootActivity.value.id)?.id
  );
});

const newProcessName = ref<string | undefined>(undefined);
const processName = computed<string>({
  get: () => props.process?.name ?? "",
  set: (value: string) => (newProcessName.value = value),
});

useTitle(processName);

const activeTab = useStorage<string>(
  StorageKeys.process.activeTab.key,
  "features",
  StorageKeys.process.activeTab.storage,
);

const actionsMenuRef = useTemplateRef("actionsMenuRef");
const actionsMenuItems = computed<BaseMenuItem[]>(() => [
  {
    testId: "upgrade-process-button",
    label: t("processes.upgrade.checkChanges"),
    icon: "mdi mdi-chevron-double-up",
    visible: isProcessUpgradeAvailable.value,
    command: () => dialogService.openDialog(UI_DIALOG.UPGRADE_PROCESS),
  },
  {
    label: t("processes.showGraphButton.title"),
    icon: "mdi mdi-graph-outline",
    disabled: rootActivity.value === undefined,
    command: async () => {
      if (!rootActivity.value) {
        return;
      }
      await navigateToGraphView(EditorMode.PROCESS, rootActivity.value.id);
    },
  },
  {
    testId: "add-field-button",
    label: t("action.addSomething", { name: t("processes.field") }),
    icon: "mdi mdi-plus",
    command: () => {
      activeTab.value = "features";
      fieldService.openFieldDialog();
    },
  },
  {
    testId: "create-custom-activity",
    label: t("processes.createCustomActivity"),
    icon: "mdi mdi-plus-box-multiple-outline",
    command: createCustomActivity,
  },
  {
    testId: "generate-document-button",
    label: t("document.generate"),
    icon: "mdi mdi-file-document-multiple-outline",
    command: () => {
      activeTab.value = "documents";
      dialogService.openGenerateDocumentDialog(EntityType.Process);
    },
  },
  {
    label: t("processes.singleView.actions.stateGroupTitle"),
    icon: "mdi mdi-state-machine",
    items: [
      {
        label: t("processes.singleView.actions.stateActionTitle.DONE"),
        icon: "mdi mdi-flag-checkered",
        visible: props.process?.state !== ProcessState.Done,
        command: () => updateProcessState(ProcessState.Done),
      },
      {
        label: t("processes.singleView.actions.stateActionTitle.IN_PROGRESS"),
        icon: "mdi mdi-play",
        visible: props.process?.state !== ProcessState.InProgress,
        command: () => updateProcessState(ProcessState.InProgress),
      },
      {
        label: t("processes.singleView.actions.stateActionTitle.CANCELLED"),
        icon: "mdi mdi-cancel",
        visible: props.process?.state !== ProcessState.Cancelled,
        command: () => updateProcessState(ProcessState.Cancelled),
      },
    ],
  },
]);

async function createCustomActivity() {
  try {
    const activityId = uuidv4();
    await activityService.createOrUpdateActivity(
      {
        id: activityId,
        custom: true,
      },
      true,
    );

    await processService.createOrUpdateProcessActivity({
      id: uuidv4(),
      activityId,
      processId: props.process.id ?? uuidv4(),
    });

    await router.push({
      name: "customActivity",
      params: { activityId, processId: props.process.id },
    });
  } catch (error) {
    console.error(error);
  }
}

async function saveProcessName(closeCallback: () => void) {
  if (newProcessName.value) {
    isLoading.value = true;
    await processService.updateProcess({
      id: props.process.id,
      name: newProcessName.value,
    });
    isLoading.value = false;
  }
  closeCallback();
}

async function navigateToGraphView(
  editorMode: EditorMode,
  rootActivityId: string,
) {
  if (editorMode === EditorMode.ACTIVITY) {
    await router.push({
      name: "activityGraph",
      params: {
        rootActivityId,
      },
    });
    return;
  }

  await router.push({
    name: "processGraph",
    params: {
      rootActivityId,
      processId: props.process.id,
    },
  });
}

async function updateProcessState(state: ProcessState) {
  isLoading.value = true;
  await processService.updateProcess({ id: props.process.id, state });
  isLoading.value = false;
}
</script>

<template>
  <div class="flex flex-row justify-between">
    <div class="flex flex-row gap-4 items-center">
      <PInplace
        v-if="process.id && process.name"
        :disabled="process?.state !== ProcessState.InProgress"
        :pt="{ display: { class: 'py-0! px-2! -ml-3!' } }"
      >
        <template #display>
          <p
            class="text-3xl font-bold"
            :class="{
              'line-through': process?.state === ProcessState.Cancelled,
            }"
          >
            {{ process.name }}
          </p>
        </template>

        <template #content="{ closeCallback }">
          <div v-focustrap>
            <BaseTextField
              autofocus
              class="my-1"
              :initialValue="processName"
              @update="processName = $event"
              @keydown.enter="saveProcessName(closeCallback)"
              @keydown.esc="closeCallback"
              @focusout="saveProcessName(closeCallback)"
            />
          </div>
        </template>
      </PInplace>

      <PTag
        v-if="process.state === ProcessState.Done"
        severity="primary"
        class="max-h-6!"
        :value="t('processes.singleView.actions.stateTag.DONE')"
      >
        <template #icon>
          <i class="mdi mdi-check" />
        </template>
      </PTag>

      <PTag
        v-else-if="process.state === ProcessState.Cancelled"
        severity="danger"
        class="max-h-6!"
        :value="t('processes.singleView.actions.stateTag.CANCELLED')"
      >
        <template #icon>
          <i class="mdi mdi-close" />
        </template>
      </PTag>

      <PTag
        v-else-if="isProcessUpgradeAvailable"
        v-tooltip.bottom="
          t('processes.newVersionAvailableTooltip', {
            name: rootActivity?.name,
          })
        "
        class="max-h-6! hover:cursor-pointer hover:opacity-80 active:opacity-40"
        severity="primary"
        :value="t('processes.newVersionAvailable')"
        @click="dialogService.openDialog(UI_DIALOG.UPGRADE_PROCESS)"
      />
    </div>

    <div class="flex flex-row gap-2">
      <BaseButton
        data-testid="actions-button"
        icon="mdi mdi-chevron-down"
        iconPos="right"
        :label="t('processes.singleView.actions.buttonTitle')"
        @click="actionsMenuRef?.toggle($event)"
      />

      <BaseTieredMenu
        ref="actionsMenuRef"
        :model="actionsMenuItems"
        :pt="{
          item: ({ context }) => ({
            'data-testid': context.item.testId,
          }),
        }"
      />
    </div>
  </div>
</template>
