<script setup lang="ts">
import { Controls } from "@vue-flow/controls";
import { PanelPosition, useVueFlow } from "@vue-flow/core";
import { useStorage } from "@vueuse/core";
import { useI18n } from "vue-i18n";

import GraphViewerToolbarButton from "@/app/graphviewer/components/GraphViewerToolbarButton.vue";
import { StorageKeys } from "@/config.ts";

const { t } = useI18n();
const { fitView, zoomIn, zoomOut } = useVueFlow();

const useSimpleMode = useStorage(StorageKeys.graph.useSimpleMode.key, true);
const showMiniMap = useStorage(StorageKeys.graph.showMiniMap.key, true);
</script>

<template>
  <Controls
    class="icons"
    :showZoom="false"
    :showInteractive="false"
    :showFitView="false"
    :position="PanelPosition.TopLeft"
  >
    <GraphViewerToolbarButton
      :tooltip="t('processes.graphViewer.toolbar.zoomInTooltip')"
      icon="mdi-plus"
      @click="zoomIn()"
    />

    <GraphViewerToolbarButton
      :tooltip="t('processes.graphViewer.toolbar.zoomOutTooltip')"
      icon="mdi-minus"
      @click="zoomOut()"
    />

    <GraphViewerToolbarButton
      :tooltip="t('processes.graphViewer.toolbar.centerViewTooltip')"
      icon="mdi-fit-to-screen-outline"
      @click="fitView()"
    />

    <GraphViewerToolbarButton
      :tooltip="t('processes.graphViewer.toolbar.toggleMiniMapTooltip')"
      :icon="showMiniMap ? 'mdi-map' : 'mdi-map-outline'"
      @click="showMiniMap = !showMiniMap"
    />

    <GraphViewerToolbarButton
      :tooltip="t('processes.graphViewer.toolbar.toggleSimpleModeTooltip')"
      :icon="useSimpleMode ? 'mdi-circle-medium' : 'mdi-dots-circle'"
      @click="useSimpleMode = !useSimpleMode"
    />
  </Controls>
</template>

<style scoped>
.icons {
  color: black;
}
</style>
