<script setup lang="ts" generic="T extends { id: string }">
import { useI18n } from "vue-i18n";

import { type ChangeLookup } from "@/app/process/components/action/upgrade/UpgradeUtils.ts";
import TextDisplay from "@/base/components/form/TextDisplay.vue";

const { t } = useI18n();

const props = defineProps<{
  /* eslint-disable no-undef -- Generic is defined in the setup */
  change: ChangeLookup<T>;
  entityNameProvider: (entity: T) => string;
  categoryLabel: string;
  excludeKeys?: (keyof T)[];
}>();

const ARROW_RIGHT = "→";

function commonFieldLabel(key: string) {
  switch (key) {
    case "name":
      return t("common.name");
    case "description":
      return t("common.description");
    case "type":
      return t("common.type");
    case "title":
      return t("common.title");
    case "goal":
      return t("common.goal");
    case "important":
      return t("processes.important");
    default:
      return undefined;
  }
}
</script>

<template>
  <div v-if="props.change.current && props.change.previous">
    <TextDisplay
      :markdown="true"
      :value="
        entityNameProvider(props.change.current) ===
        entityNameProvider(props.change.previous)
          ? t('action.somethingWasChanged', {
              name: t('common.categoryName', {
                category: props.categoryLabel,
                name: '**' + entityNameProvider(props.change.previous) + '**',
              }),
            })
          : t('action.somethingWasRenamed', {
              old: t('common.categoryName', {
                category: props.categoryLabel,
                name:
                  '**' +
                  entityNameProvider(props.change.previous).trim() +
                  '**',
              }),
              new: t('common.quoted', {
                text:
                  '**' + entityNameProvider(props.change.current).trim() + '**',
              }),
            })
      "
    >
    </TextDisplay>
    <table
      :aria-label="t('common.change', 2)"
      style="border-collapse: collapse"
    >
      <tbody>
        <tr v-for="key in props.change.changedKeys" :key="key">
          <template v-if="!excludeKeys?.includes(key as keyof T)">
            <th>{{ commonFieldLabel(key) }}:</th>
            <td
              class="text-right"
              :class="{ 'font-italic': !props.change.previous[key as keyof T] }"
            >
              <TextDisplay
                :value="
                  props.change.previous[key as keyof T]?.toString() ??
                  t('processes.placeholders.emptyValue')
                "
                :markdown="true"
              />
            </td>
            <td>{{ ARROW_RIGHT }}</td>
            <td>
              <TextDisplay
                :value="
                  props.change.current[key as keyof T]?.toString() ??
                  t('processes.placeholders.emptyValue')
                "
                :markdown="true"
              />
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
  <span
    v-if="props.change.current && !props.change.previous"
    class="text-green-darken-2"
  >
    {{
      t("action.somethingWasAdded", {
        name: t("common.categoryName", {
          category: props.categoryLabel,
          name: entityNameProvider(props.change.current),
        }),
      })
    }}
  </span>
  <span
    v-if="!props.change.current && props.change.previous"
    class="text-red-darken-4"
  >
    {{
      t("action.somethingWasRemoved", {
        name: t("common.categoryName", {
          category: props.categoryLabel,
          name: entityNameProvider(props.change.previous),
        }),
      })
    }}
  </span>
</template>
<style scoped>
th,
td {
  padding: 0.2rem 0.3rem;
}
</style>
