<script lang="ts" setup>
import { ref } from "vue";

import { useFieldService } from "@/base/services/FieldService.ts";

const props = defineProps<{
  entityId: string;
  id: string;
  fieldName: string;
  name: string;
}>();
const emits = defineEmits<(e: "delete", id: string) => void>();

const fieldService = useFieldService();

const editName = ref(props.name);

function updateFieldName() {
  fieldService.createOrUpdateFieldKey({
    id: props.id,
    name: `${props.fieldName}:${editName.value}`,
  });
}
</script>

<template>
  <div class="d-flex align-center">
    <div style="width: 80%">
      <VTextField
        v-model="editName"
        density="compact"
        variant="outlined"
        hideDetails="auto"
        @focusout="updateFieldName"
      />
    </div>
    <div style="width: 20%" class="d-flex align-start flex-column pl-1">
      <VBtn
        variant="plain"
        color="red-darken-2"
        icon="mdi-delete-outline"
        size="tiny"
        class="pa-1 text-caption"
        @click="() => emits('delete', props.id)"
      />
    </div>
  </div>
</template>

<style scoped></style>
