<script lang="ts" setup>
import { computed } from "vue";

import { useFieldService } from "@/base/services/FieldService.ts";

const props = withDefaults(
  defineProps<{
    entityId: string;
    justify?: "start" | "center" | "end";
    density?: "default" | "compact" | "comfortable";
  }>(),
  {
    justify: undefined,
    density: "compact",
  },
);

const fieldService = useFieldService();

const fieldValues = computed(() => {
  return fieldService.getTagFieldValues(props.entityId);
});

const justifyClass = computed(() => {
  return props.justify ? `justify-${props.justify}` : undefined;
});
</script>

<template>
  <div
    v-if="fieldValues.length"
    class="flex flex-row flex-wrap gap-2"
    :class="justifyClass"
    data-testid="tags-display"
  >
    <PChip
      v-for="fieldValue in fieldValues"
      :key="fieldValue.id"
      :label="fieldService.getFieldKey(fieldValue.fieldKeyId)?.name"
      :pt:root:style="{
        background: `#${fieldService.getFieldKey(fieldValue.fieldKeyId)?.colorHex}`,
      }"
      pt:root:class="text-sm"
      class="h-6"
    />
  </div>
</template>
