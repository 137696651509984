<script setup lang="ts">
import { type DataTableFilterMeta } from "primevue/datatable";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import BaseButton from "@/base/components/button/BaseButton.vue";
import {
  type DataTableColumn,
  isDataTableFilterMetaData,
} from "@/base/components/filterdatatable/table/CFilteredDataTableUtils.ts";
import { type MappedListFilterDto } from "@/base/components/filterdatatable/useDataTableFilter.ts";
import {
  type FieldKeyDto,
  type ListFilterDto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  contextKey: string;
  exposedColumns?: DataTableColumn[];
  mandatoryColumns?: DataTableColumn[];
  availableTags: FieldKeyDto[];
  selectedColumns: string[];
  listFilter: MappedListFilterDto;
  filters: DataTableFilterMeta;
}>();

const emits = defineEmits<{
  (e: "update:fullTextSearch", value: string | undefined): void;
  (e: "update:filters", value: ListFilterDto): void;
  (e: "clear:filters"): void;
  (e: "update:selectedColumns", value: string[]): void;
}>();

const { t } = useI18n();

const isFilterActive = computed(() => {
  return (
    (props.listFilter.tags?.length ?? 0) > 0 ||
    Object.keys(props.filters).some((key) => {
      const filter = props.filters?.[key];
      if (typeof filter === "string") {
        return filter?.length > 0;
      } else if (isDataTableFilterMetaData(filter)) {
        return filter.value?.length > 0;
      } else {
        return filter.constraints?.some(
          (constraint) => constraint.value?.length > 0,
        );
      }
    })
  );
});

const availableColumns = computed<DataTableColumn[]>(() => [
  ...(
    props.exposedColumns?.map((item) => {
      return {
        name: item.name,
        key: item.key ?? item.name,
        type: item.type,
        isFilterable: item.isFilterable,
        isSortable: item.isSortable,
      } as DataTableColumn;
    }) ?? []
  ).sort((a, b) => a.name.localeCompare(b.name)),
]);

function isColumnDisabled(column: DataTableColumn | undefined) {
  if (!column) {
    return true;
  }
  return (
    props.mandatoryColumns?.map((col) => col.key).includes(column.key) ?? false
  );
}

function clearFilter() {
  emits("clear:filters");
}
</script>

<template>
  <div class="flex flex-row justify-space-between flex-wrap items-center gap-2">
    <div class="flex flex-row flex-wrap gap-2">
      <PIconField>
        <PInputIcon class="mdi mdi-magnify" />
        <PInputText
          data-testid="full-text-search"
          size="small"
          fluid
          :defaultValue="listFilter.fullTextSearch"
          :placeholder="t('table.search')"
          @valueChange="emits('update:fullTextSearch', $event)"
        />
      </PIconField>

      <PMultiSelect
        v-if="!availableColumns.every((col) => isColumnDisabled(col))"
        data-testid="filtered-data-table-column-selection-button"
        class="w-60"
        filter
        size="small"
        :modelValue="selectedColumns"
        :placeholder="t('table.columnsSelected', 0)"
        :options="availableColumns"
        :optionLabel="(option) => option.name"
        :optionValue="(option) => option.key"
        :optionDisabled="isColumnDisabled"
        :showToggleAll="false"
        @update:modelValue="emits('update:selectedColumns', $event)"
      >
        <template #value>
          <div class="flex flex-row gap-2">
            <span class="mdi mdi-table-column"></span>
            <span class="ml-1">
              {{ t("table.columnsSelected", selectedColumns.length) }}
            </span>
          </div>
        </template>

        <template #option="{ option, index }">
          <span :data-testid="`filtered-data-table-column-${option.name}`"
            >{{ option.name }}
            {{
              isColumnDisabled(availableColumns?.[index])
                ? `(${t("table.mandatoryColumn")})`
                : ""
            }}</span
          >
        </template>
      </PMultiSelect>

      <PMultiSelect
        v-if="availableTags.length > 0"
        class="w-60"
        filter
        size="small"
        :modelValue="listFilter.tags ?? []"
        :placeholder="t('table.tagsSelected', 0)"
        :options="availableTags"
        :optionLabel="(tag) => tag.name"
        :optionValue="(tag) => tag.id"
        :showToggleAll="false"
        @update:modelValue="emits('update:filters', { tags: $event })"
      >
        <template #value>
          <div class="flex flex-row gap-2">
            <span class="mdi mdi-tag-multiple-outline"></span>
            <span class="ml-1">
              {{ t("table.tagsSelected", listFilter.tags?.length ?? 0) }}
            </span>
          </div>
        </template>
      </PMultiSelect>

      <BaseButton
        v-if="isFilterActive"
        v-tooltip.bottom="t('table.filter.clear')"
        data-testid="btn-clear-table-filter"
        icon="mdi mdi-filter-remove-outline"
        severity="danger"
        outlined
        @click="clearFilter()"
      />
    </div>

    <div class="flex flex-row items-center gap-2">
      <slot name="additionalControls" />
    </div>
  </div>
</template>
