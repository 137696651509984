<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { EditorMode } from "@/app/graphviewer/types/EditorMode.ts";
import FloatingActionButton from "@/base/components/button/FloatingActionButton.vue";

const props = defineProps<{
  primary?: boolean;
  first?: boolean;
  color?: string;
  rootActivityId: string;
  processId?: string;
  editorMode: EditorMode;
}>();

const { t } = useI18n();

const router = useRouter();

async function navigateToGraphView() {
  if (props.editorMode === EditorMode.ACTIVITY) {
    await router.push({
      name: "activityGraph",
      params: {
        rootActivityId: props.rootActivityId,
      },
    });
    return;
  }

  await router.push({
    name: "processGraph",
    params: {
      rootActivityId: props.rootActivityId,
      processId: props.processId ?? "undefined",
    },
  });
}
</script>

<template>
  <VTooltip location="start" :text="t('processes.showGraphButton.title')">
    <template #activator="{ props: activator }">
      <FloatingActionButton
        v-bind="activator"
        icon="mdi-graph-outline"
        :primary="props.primary"
        @click="navigateToGraphView"
      />
    </template>
  </VTooltip>
</template>
