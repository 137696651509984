<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import StartProcessButton from "@/app/process/components/action/start/StartProcessButton.vue";
import { UI_DIALOG, useDialogService } from "@/base/services/DialogService.ts";

const { t } = useI18n();

const activityService = useActivityService();
const dialogService = useDialogService();

const { lastReleasedRootActivities } = storeToRefs(activityService);
const dialogOpen = computed(() =>
  dialogService.isDialogOpen(UI_DIALOG.START_ANY),
);

const processName = ref("");
const rootActivityId = ref<string | undefined>(undefined);
</script>

<template>
  <VDialog v-model="dialogOpen" maxWidth="600">
    <VCard>
      <VCardTitle class="text-wrap"
        >{{ t("processes.startButton.startProcess") }}
      </VCardTitle>
      <VCardText class="d-flex flex-column ga-2">
        <VSelect
          v-model="rootActivityId"
          density="compact"
          variant="outlined"
          label="Prozessvorlage"
          :items="lastReleasedRootActivities"
          itemTitle="name"
          itemValue="id"
          hideDetails="auto"
        />
        <VTextField
          v-model="processName"
          density="compact"
          variant="outlined"
          label="Prozessname"
          hideDetails="auto"
        />
      </VCardText>
      <VCardActions class="d-flex flex-column">
        <StartProcessButton
          :processName="processName"
          :rootActivityId="rootActivityId"
          :onAfterApply="() => dialogService.closeDialog(UI_DIALOG.START_ANY)"
        />
        <VBtn
          variant="flat"
          block
          @click.stop.prevent="
            () => dialogService.closeDialog(UI_DIALOG.START_ANY)
          "
          >{{ t("action.cancel") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
