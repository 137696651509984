import { DataTableContextKeys } from "@/base/components/filterdatatable/FilteredDataTableUtil.ts";
import {
  DeleteProcessDocument,
  GetProcessDocument,
  GetProcessesListDocument,
  ProcessDocument,
  type ProcessDto,
  type ProcessEto,
} from "@/base/graphql/generated/types.ts";
import { defineEntityStoreV2 } from "@/base/stores/entity/EntityStoreV2.ts";

export const useProcessStoreV2 = defineEntityStoreV2<ProcessEto, ProcessDto>({
  key: "ProcessStoreV2",
  singleViewRouteParam: "processId",
  contextKey: DataTableContextKeys.processList,
  listQuery: GetProcessesListDocument,
  singleQuery: GetProcessDocument,
  createOrUpdateMutation: ProcessDocument,
  deleteMutation: DeleteProcessDocument,
});
