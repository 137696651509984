import { definePreset } from "@primevue/themes";
import Aura from "@primevue/themes/aura";
import { type ColumnPassThroughOptions } from "primevue";

const colors = {
  primary: "#00CA90",
  secondary: "#4C5760",
  warn: "#C13232",
};

const caeliTheme = definePreset(Aura, {
  semantic: {
    colorScheme: {
      light: {
        primary: {
          color: colors.primary,
        },
        secondary: {
          color: colors.secondary,
        },
        warn: {
          color: colors.warn,
        },
      },
    },
  },
  components: {
    progressspinner: {
      colorScheme: {
        light: {
          color: {
            "1": colors.primary,
            "2": colors.primary,
            "3": colors.primary,
            "4": colors.primary,
          },
        },
      },
    },
  },
});

const columnPt: Record<string, ColumnPassThroughOptions> = {
  column: {
    pcFilterAddRuleButton: {
      icon: { class: "text-primary!" },
      label: { class: "text-primary!" },
    },
  },
};

const globalPt = {
  ...columnPt,
};

export const primeVueConfig = {
  pt: globalPt,
  ripple: true,
  theme: {
    preset: caeliTheme,
    options: {
      darkModeSelector: false,
      order: "tailwind-theme, tailwind-base, primevue, tailwind-utilities",
    },
  },
};
