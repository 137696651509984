<script setup lang="ts">
import { useStorage } from "@vueuse/core";
import { v4 as uuidv4 } from "uuid";
import { useI18n } from "vue-i18n";

import logoIcon from "@/base/assets/img/logoIcon.svg";
import logoText from "@/base/assets/img/logoText.svg";
import AppMenuItem from "@/base/components/navigation/AppMenuItem.vue";
import { menuItems } from "@/base/components/navigation/AppMenuItems";
import { StorageKeys } from "@/config.ts";

const { t } = useI18n();

const isExpanded = useStorage(StorageKeys.app.drawerExpanded.key, true);
</script>

<template>
  <div
    data-testid="app-menu"
    class="flex flex-col gap-4 items-start pt-4 px-2 border-r border-surface background-image transition-all ease-out duration-200"
    :class="isExpanded ? 'w-48' : 'w-13'"
  >
    <div class="-m-1! ml-1! w-full">
      <RouterLink class="flex flex-row gap-2" to="/">
        <img
          id="pisys-logo-icon"
          :src="logoIcon"
          class="pl-[0.2rem]! h-5"
          alt="Logo"
        />
        <img
          id="pisys-logo-text"
          class="pl-2 h-4 opacity-0 transition-opacity duration-200 ease-out"
          :class="isExpanded && 'opacity-100'"
          :src="logoText"
          :alt="t('app.logoText')"
        />
      </RouterLink>
    </div>

    <PDivider class="-my-1!" />

    <AppMenuItem
      v-for="item in menuItems"
      :key="item.label ?? uuidv4()"
      :menuItem="item"
      :isExpanded
      data-testid="app-menu-item"
    />

    <PDivider class="-my-1!" />

    <AppMenuItem
      data-testid="app-menu-collapse-button"
      :label="t('drawer.collapse')"
      :icon="isExpanded ? 'mdi mdi-chevron-left' : 'mdi mdi-chevron-right'"
      :isExpanded
      @click="isExpanded = !isExpanded"
    />
  </div>
</template>

<style scoped>
.background-image {
  background-image:
    linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.3) 100%),
    url("../../assets/img/drawerBackground.jpg");
  background-repeat: no-repeat;
  background-position: 80% center;
  background-size: cover;
}
</style>
