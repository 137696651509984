import {
  type EntityEventEto,
  GetEntityEventListDocument,
} from "@/base/graphql/generated/types.ts";
import {
  defineEntityStore,
  EntityProvider,
} from "@/base/stores/entity/EntityStore.ts";
import { PendingPersistedLookupDual } from "@/base/utils/IdRelationLookups.ts";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual((eto: EntityEventEto) => ({
    id: eto.id,
  })),
  {
    queryName: "entityEvent",
    listQuery: GetEntityEventListDocument,
  },
);

export const useEntityEventStore = defineEntityStore(
  "entityEvent",
  entityProvider,
);
