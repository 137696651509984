<script setup lang="ts">
import { useRouter } from "vue-router";

import BaseButton from "@/base/components/button/BaseButton.vue";
import { usePreviousRoute } from "@/base/utils/navigation.ts";

const router = useRouter();
const previousRoute = usePreviousRoute();
</script>

<template>
  <PToolbar
    v-if="previousRoute"
    class="bg-surface-50/40!"
    :pt="{
      root: {
        class: 'rounded-none! border-l-0! border-t-0! border-r-0! pa-1',
      },
    }"
  >
    <template #start>
      <BaseButton
        v-if="previousRoute"
        data-testid="back-button"
        icon="mdi mdi-chevron-left"
        text
        severity="secondary"
        class="h-8"
        :label="previousRoute.label"
        @click="router.go(-1)"
      />
    </template>
  </PToolbar>
</template>
