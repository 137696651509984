// Styles
import "@mdi/font/css/materialdesignicons.css";
import "@/base/assets/styles/variables.scss";

// Vuetify
import { createVuetify, type ThemeDefinition } from "vuetify";

export { VApp } from "vuetify/components";

const theme: ThemeDefinition = {
  dark: false,
  colors: {
    background: "#FFFFFF",
    surface: "#FFFFFF",
    "on-surface": "#3C707B",
    caeli1: "#FFFFFF",
    caeli2: "#024452",
    // caeli3: '',
    // caeli4: '',
    caeli5: "#00CA90",
    // Text color on caeli5 background
    "on-caeli5": "#FFF",
    caeli6: "#3C707B",
    // caeli7: '',
    caeli8: "#C3D6DB",
    "caeli-link": "#4b96e6",
    gradient: "#EFF5F7",
    error: "#C13232",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107",
    warning_bg: "#FFF8EE",
    funnel1: "#284751",
    funnel2: "#518EA2",
    funnel3: "#9EC2CE",
    funnel4: "#CFE0E7",
  },
};

export default createVuetify({
  directives: {
    tooltip: undefined,
  },
  theme: {
    themes: {
      light: theme,
      dark: theme,
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
