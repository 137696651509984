<script setup lang="ts">
import { computed, reactive, watch, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import DocumentParameterTable from "@/app/document/components/DocumentParameterTable.vue";
import { useDocumentService } from "@/app/document/services/DocumentService";
import DocumentFileField from "@/app/process/components/field/DocumentFileField.vue";
import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseCard from "@/base/components/card/BaseCard.vue";
import TextEditor from "@/base/components/form/TextEditor.vue";
import TextField from "@/base/components/form/value/TextField.vue";
import { TitleMode, useTitle } from "@/base/composables/useTitle";
import { type DocumentDto, EntityType } from "@/base/graphql/generated/types";
import { translateEnum } from "@/base/i18n/i18n";

const props = defineProps<{
  documentId: string;
}>();

const { t } = useI18n();
const router = useRouter();
const documentService = useDocumentService();

const pendingDocument = reactive<DocumentDto>({
  id: props.documentId,
  name: "Neu",
  entityType: null,
  templateFileId: null,
});

const existingDocument = computed(() =>
  documentService.getById(props.documentId),
);

useTitle(
  existingDocument.value?.name ??
    pendingDocument.name ??
    t("document.document", 1),
  TitleMode.EDIT,
);

const entityTypeOptions = computed(() => {
  return Object.values(EntityType).map((value) => ({
    id: value,
    title: translateEnum("entityTypes", value),
  }));
});

const pendingDocumentEntityType = computed({
  get: () =>
    entityTypeOptions.value.find(
      (option) => option.id === pendingDocument.entityType,
    ),
  set: (value) => {
    const entityType = value?.id;
    updatePendingDocument({ entityType });
  },
});

const isValidDocument = computed(() => {
  return Object.entries(pendingDocument).every(([key, value]) => {
    if (key === "description") {
      return true;
    }
    if (key === "iterator") {
      return true;
    }
    return value !== undefined && value !== null && value !== "";
  });
});

watchEffect(() => {
  Object.assign(pendingDocument, documentService.getById(props.documentId));
});

watch([isValidDocument, pendingDocument], async ([isValid]) => {
  // Auto-Save on blur only when the document is valid
  if (!isValid) {
    return;
  }
  await createOrUpdateDocument();
});

function updatePendingDocument(update: Partial<DocumentDto>) {
  Object.assign(pendingDocument, update);
}

async function createOrUpdateDocument(params?: { goBack: boolean }) {
  documentService.createOrUpdate(pendingDocument);

  if (params?.goBack) {
    await router.push({
      name: "documentView",
      params: { documentId: pendingDocument.id },
    });
  }
}

function isDocumentExisting() {
  return documentService.getById(props.documentId) !== undefined;
}
</script>

<template>
  <div class="bg-surface-50 h-screen flex flex-col gap-4 p-4!">
    <BaseCard>
      <template #title>
        <div class="flex flex-row justify-between">
          <p>{{ t("document.edit") }}</p>

          <BaseButton
            data-testid="save-document"
            icon="mdi mdi-content-save-outline"
            :label="t('ui.save')"
            :disabled="!isValidDocument"
            @click="createOrUpdateDocument({ goBack: true })"
          />
        </div>
      </template>

      <div class="d-flex flex-column ga-4">
        <TextField
          :initialValue="pendingDocument.name ?? undefined"
          data-testid="document-name"
          :label="t('document.name')"
          @update="(value) => updatePendingDocument({ name: value })"
        />

        <VAutocomplete
          v-model="pendingDocumentEntityType"
          data-testid="document-entity-type"
          :items="entityTypeOptions"
          :label="t('document.entityType')"
          variant="outlined"
          density="compact"
          returnObject
          hideDetails
        />

        <TextField
          :initialValue="pendingDocument.iterator ?? undefined"
          data-testid="document-iterator"
          :label="t('document.iterator.label')"
          @update="(iterator) => updatePendingDocument({ iterator: iterator })"
        >
          <VTooltip
            activator="parent"
            openDelay="750"
            maxWidth="300"
            :text="t('document.iterator.tooltip')"
            location="bottom"
          ></VTooltip>
        </TextField>

        <TextEditor
          data-testid="document-description"
          :containerId="props.documentId"
          :label="t('document.description')"
          :previousContent="pendingDocument.description ?? undefined"
          @saveContent="
            (content) => updatePendingDocument({ description: content })
          "
        />

        <DocumentFileField
          data-testid="document-template-file"
          :entityId="pendingDocument.id"
          :fileId="pendingDocument.templateFileId ?? undefined"
          @update="
            (fileId) => updatePendingDocument({ templateFileId: fileId })
          "
        />
      </div>
    </BaseCard>

    <BaseCard v-if="isDocumentExisting()" noPadding>
      <DocumentParameterTable :entityId="pendingDocument.id" :editable="true" />
    </BaseCard>
  </div>
</template>
