import { LogLevel, PublicClientApplication } from "@azure/msal-browser";

import config from "@/config.ts";

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    // 'Application (client) ID' of app registration in Azure portal - this value is a GUID
    clientId: config.authClientId,

    // Must be registered as a SPA redirectURI on your app registration
    redirectUri: config.baseUrl,
    postLogoutRedirectUri: config.baseUrl,

    // Full directory URL, in the form of https://login.microsoftonline.com/<tenant-id>
    authority:
      "https://login.microsoftonline.com/d546a5cd-9f03-4040-b588-b8b4cde6448b",
  },
  cache: {
    cacheLocation: "localStorage",
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean,
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Error,
    },
  },
};

export const msalInstance =
  await PublicClientApplication.createPublicClientApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const baseLoginRequest = {
  // Request the default scope for our own application so that we are allowed to call its API
  scopes: [`${msalConfig.auth.clientId}/.default`],
};
