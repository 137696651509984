<script lang="ts" setup>
import { computed, ref, useTemplateRef, watch } from "vue";
import { useI18n } from "vue-i18n";

import OptionField from "@/app/process/components/field/OptionField.vue";
import ReadonlyField from "@/app/process/components/field/ReadonlyField.vue";
import {
  type FieldKeyDto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
}>();

const { n } = useI18n();

const editTextField = useTemplateRef("editTextField");

const emit = defineEmits<(e: "update", value: FieldValueDto) => void>();

function emitUpdate(value: number) {
  emit("update", {
    ...props.fieldValue,
    value: {
      valueNumber: value,
    },
  });
}

const isOptionField = computed(() => (props.fieldKey.options?.length ?? 0) > 0);

const valueNumber = ref(props.fieldValue?.value?.valueNumber);
const displayValue = computed(() =>
  valueNumber.value ? n(valueNumber.value) : "-",
);

const focused = ref(false);

function onDisplayTextFieldFocus() {
  focused.value = true;
}

function onEditTextFieldBlur() {
  focused.value = false;
  if (valueNumber.value) {
    emitUpdate(valueNumber.value);
  }
}

watch(
  () => editTextField.value,
  (input) => {
    if (!input) {
      return;
    }

    input.focus();
  },
);
</script>

<template>
  <ReadonlyField
    v-if="props.readonly"
    :label="props.label"
    :value="displayValue"
  />
  <OptionField
    v-else-if="isOptionField"
    :fieldKey="props.fieldKey"
    :fieldValue="props.fieldValue"
    :label="props.label"
    itemTitle="valueNumber"
    @update="
      (value) => emit('update', { ...props.fieldValue, optionId: value })
    "
  />
  <div v-else class="w-full">
    <VTextField
      v-if="!focused"
      v-model="displayValue"
      :label="props.fieldKey.name ?? ''"
      variant="outlined"
      density="compact"
      hideDetails="auto"
      data-testid="number-field-input"
      @focus="onDisplayTextFieldFocus"
    />
    <VTextField
      v-else
      ref="editTextField"
      v-model.number="valueNumber"
      :label="props.fieldKey.name ?? ''"
      variant="outlined"
      density="compact"
      type="number"
      hideDetails="auto"
      data-testid="number-field-input"
      @blur="onEditTextFieldBlur"
    />
  </div>
</template>
