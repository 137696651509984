<script lang="ts" setup>
import dayjs from "dayjs";
import { computed, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

import DocumentGenerationRunListActions from "@/app/document/components/generation/DocumentGenerationRunListActions.vue";
import DocumentGenerationRunParameterDialog from "@/app/document/components/generation/DocumentGenerationRunParameterDialog.vue";
import GenerateDocumentDialog from "@/app/document/components/generation/GenerateDocumentDialog.vue";
import { useDocumentGenerationRunService } from "@/app/document/services/DocumentGenerationRunService.ts";
import PersonField from "@/app/process/components/field/PersonField.vue";
import FilteredDataTable from "@/base/components/filterdatatable/FilteredDataTable.vue";
import { type CellContent } from "@/base/components/filterdatatable/TableTypes.ts";
import { type DocumentGenerationRunEto } from "@/base/graphql/generated/types.ts";

const { t } = useI18n();
const documentGenerationRunService = useDocumentGenerationRunService();

const props = defineProps<{
  entityId: string;
}>();

const i18n = {
  nameTitle: t("documentGenerationRun.name"),
  createdDateTitle: t("documentGenerationRun.createdDate"),
  authorTitle: t("documentGenerationRun.author"),
};

watchEffect(() => documentGenerationRunService.markRefetch(props.entityId));

const documentGenerationRuns = computed(() =>
  documentGenerationRunService.getAll(props.entityId),
);

const rowItems = computed(() => {
  return documentGenerationRuns.value.map((documentGenerationRun) => {
    const cells: Record<string, CellContent> = {
      ...createNameCell(documentGenerationRun),
      ...createCreatedCell(documentGenerationRun),
      ...createAuthorCell(documentGenerationRun),
      ...createActionsCell(documentGenerationRun),
    };

    return {
      key: documentGenerationRun.id,
      cells,
      tags: [],
    };
  });
});

function createNameCell(documentGenerationRun: DocumentGenerationRunEto) {
  return {
    [i18n.nameTitle]: {
      content: documentGenerationRun.name,
    },
  };
}

function createCreatedCell(documentGenerationRun: DocumentGenerationRunEto) {
  return {
    [i18n.createdDateTitle]: {
      content: documentGenerationRun.created
        ? dayjs(documentGenerationRun.created).format("YYYY-MM-DD, HH:mm")
        : "",
    },
  };
}

function createAuthorCell(documentGenerationRun: DocumentGenerationRunEto) {
  return {
    [i18n.authorTitle]: {
      component: PersonField,
      content: documentGenerationRun.authorId ?? "",
      props: {
        initialValue: documentGenerationRun.authorId,
        readonly: true,
      },
    },
  };
}

function createActionsCell(
  documentGenerationRun: DocumentGenerationRunEto,
): Record<string, CellContent> {
  return {
    [""]: {
      component: DocumentGenerationRunListActions,
      props: {
        documentGenerationRunId: documentGenerationRun.id,
        class: "justify-end",
      },
    },
  };
}

const mandatoryColumns = [i18n.nameTitle, ""];
const initialColumns = [
  i18n.nameTitle,
  i18n.createdDateTitle,
  i18n.authorTitle,
];
</script>

<template>
  <FilteredDataTable
    :searchByTextColumns="[i18n.nameTitle]"
    :contextKey="`documentGenerationRuns-${props.entityId}`"
    :rowItems="rowItems"
    :mandatoryColumns="mandatoryColumns"
    :initialColumns="initialColumns"
    :availableTags="[]"
  >
  </FilteredDataTable>
  <DocumentGenerationRunParameterDialog />
  <GenerateDocumentDialog :preselectedItem="entityId" :canChooseDoc="true" />
</template>
