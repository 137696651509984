<script setup lang="ts">
import { type TieredMenu, type TieredMenuPassThroughOptions } from "primevue";
// This is required for the import due to a PrimeVue Bug
/* eslint-disable canonical/prefer-inline-type-import */
import type { MenuItem } from "primevue/menuitem";
import { ref } from "vue";

import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";

withDefaults(
  defineProps<{
    popup?: boolean;
    model: BaseMenuItem[];
    pt?: TieredMenuPassThroughOptions;
  }>(),
  {
    popup: true,
    pt: undefined,
  },
);

const menuRef = ref<InstanceType<typeof TieredMenu> | null>(null);

defineExpose({
  toggle: (event: Event) => {
    menuRef.value?.toggle(event);
  },
});
</script>

<template>
  <PTieredMenu ref="menuRef" :popup :model="model as MenuItem[]" :pt />
</template>
