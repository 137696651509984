import { processOutputToDto } from "@/app/process/services/mapper/ProcessMapper.ts";
import {
  type ProcessOutputDto,
  type ProcessOutputEto,
} from "@/base/graphql/generated/types.ts";
import {
  DeleteProcessOutputDocument,
  ProcessOutputDocument,
} from "@/base/graphql/generated/types.ts";
import {
  defineEntityStore,
  EntityProvider,
} from "@/base/stores/entity/EntityStore.ts";
import { PendingPersistedLookupDual } from "@/base/utils/IdRelationLookups.ts";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual<ProcessOutputEto, ProcessOutputDto>(
    processOutputToDto,
  ),
  {
    queryName: "processOutput",
    mutation: ProcessOutputDocument,
    deleteMutation: DeleteProcessOutputDocument,
  },
  {
    indexFields: ["processActivityId", "activityOutputId"],
  },
);

export const useProcessOutputStore = defineEntityStore(
  "processOutput",
  entityProvider,
);
