import {
  GetPersonDocument,
  GetPersonListDocument,
  PersonDeleteDocument,
  PersonDocument,
  type PersonDto,
  type PersonEto,
} from "@/base/graphql/generated/types.ts";
import {
  defineEntityStore,
  EntityProvider,
} from "@/base/stores/entity/EntityStore.ts";
import { useFieldValueStore } from "@/base/stores/field/FieldValueStore.ts";
import { PendingPersistedLookupDual } from "@/base/utils/IdRelationLookups.ts";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual(
    (eto: PersonEto) => ({ id: eto.id }) as PersonDto,
  ),
  {
    queryName: "person",
    listQuery: GetPersonListDocument,
    singleQuery: GetPersonDocument,
    mutation: PersonDocument,
    deleteMutation: PersonDeleteDocument,
  },
  {
    onAfterRegister(etos) {
      useFieldValueStore().registerAllLoaded(
        etos.flatMap((person) => person.fields ?? []),
      );
    },
  },
);

export const usePersonStore = defineEntityStore("person", entityProvider);
