import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
  type RouterHistory,
} from "vue-router";

import ActivityListView from "@/app/activity/views/ActivityListView.vue";
import ActivitySingleView from "@/app/activity/views/ActivitySingleView.vue";
import FieldKeyOverviewView from "@/app/admin/views/FieldKeyOverviewView.vue";
import GrafanaPage from "@/app/dashboard/GrafanaPage.vue";
import DocumentListView from "@/app/document/views/DocumentListView.vue";
import DocumentSingleEditView from "@/app/document/views/DocumentSingleEditView.vue";
import DocumentView from "@/app/document/views/DocumentView.vue";
import GraphViewerView from "@/app/graphviewer/views/GraphViewerView.vue";
import MessageListView from "@/app/message/views/MessageListView.vue";
import MessageSingleEditView from "@/app/message/views/MessageSingleEditView.vue";
import MessageView from "@/app/message/views/MessageView.vue";
import PersonListView from "@/app/person/views/PersonListView.vue";
import PersonSingleEditView from "@/app/person/views/PersonSingleEditView.vue";
import PersonView from "@/app/person/views/PersonView.vue";
import ProcessActivityListView from "@/app/process/views/ProcessActivityListView.vue";
import ProcessActivitySingleView from "@/app/process/views/ProcessActivitySingleView.vue";
import ProcessListView from "@/app/process/views/ProcessListView.vue";
import ProcessSingleView from "@/app/process/views/ProcessSingleView.vue";
import ProcessTemplateListView from "@/app/process/views/ProcessTemplateListView.vue";
import { type TranslationPath } from "@/base/i18n/i18n.ts";
import ErrorView from "@/base/views/ErrorView.vue";

declare module "vue-router" {
  interface RouteMeta {
    /**
     * Whether to show the back button (if defaultBackTarget or internal history exists,
     * and linkTranslationKey is given for the target page),
     */
    showBackButton?: boolean;
    /** The `name` of a route to always go back to regardless of the internal history */
    hardBackTarget?: string;
    /** The `name` of the route to go back to if no history is available internally */
    defaultBackTarget?: string;
    /** Translation key for back button to this page */
    linkTranslationKey?: TranslationPath;
    /** Does the user have to be logged into a valid account */
    requiresAuth?: boolean;
  }
}

export const routes: RouteRecordRaw[] = [
  {
    name: "dashboard",
    path: "/",
    component: GrafanaPage,
    meta: {
      showBackButton: true,
      // no default back target - only show if there is a history
      linkTranslationKey: "linkTranslationKey.dashboard",
    },
  },
  {
    name: "activity",
    path: "/process/template/:activityId",
    component: ActivitySingleView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "customActivity",
    path: "/process/:processId/activity/custom/:activityId",
    component: ActivitySingleView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "processTemplate",
    path: "/process/template/list/:rootActivityId",
    component: ActivityListView,
    props: true,
    meta: {
      linkTranslationKey: "linkTranslationKey.processTemplateList",
      showBackButton: true,
      hardBackTarget: "processTemplateList",
    },
  },
  {
    name: "processTemplateList",
    path: "/process/template/list",
    component: ProcessTemplateListView,
    meta: {
      linkTranslationKey: "linkTranslationKey.processTemplateList",
      showBackButton: true,
      hardBackTarget: "processList",
    },
  },
  {
    name: "processList",
    path: "/process/list",
    component: ProcessListView,
    meta: {
      linkTranslationKey: "linkTranslationKey.processList",
      showBackButton: true,
      hardBackTarget: "dashboard",
    },
  },
  {
    name: "processActivityList",
    path: "/process/activity/list",
    component: ProcessActivityListView,
    meta: {
      linkTranslationKey: "linkTranslationKey.processActivityList",
      showBackButton: true,
    },
  },
  {
    name: "process",
    path: "/process/:processId",
    component: ProcessSingleView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "processActivity",
    path: "/process/:processId/activity/:processActivityId",
    component: ProcessActivitySingleView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "activityGraph",
    path: "/process/template/:rootActivityId/graph/",
    component: GraphViewerView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "processGraph",
    path: "/process/template/:rootActivityId/graph/:processId",
    component: GraphViewerView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "personList",
    path: "/person/list",
    component: PersonListView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "personEdit",
    path: "/person/:personId/edit",
    component: PersonSingleEditView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "personView",
    path: "/person/:personId",
    component: PersonView,
    props: true,
    meta: {
      showBackButton: true,
      hardBackTarget: "personList",
    },
  },
  {
    name: "documentList",
    path: "/document/list",
    component: DocumentListView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "documentEdit",
    path: "/document/:documentId/edit",
    component: DocumentSingleEditView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "documentView",
    path: "/document/:documentId",
    component: DocumentView,
    props: true,
    meta: {
      showBackButton: true,
      hardBackTarget: "documentList",
    },
  },
  {
    name: "messageList",
    path: "/message/list",
    component: MessageListView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "messageEdit",
    path: "/message/:messageId/edit",
    component: MessageSingleEditView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "messageView",
    path: "/message/:messageId",
    component: MessageView,
    props: true,
    meta: {
      showBackButton: true,
      hardBackTarget: "messageList",
    },
  },
  {
    name: "fieldKeyOverview",
    path: "/fieldKeyOverview",
    component: FieldKeyOverviewView,
    meta: {
      showBackButton: true,
      hardBackTarget: "dashboard",
    },
  },
  {
    name: "error",
    path: "/error",
    component: ErrorView,
  },
  //   Fallback route, must be the last route :)
  {
    path: "/:pathMatch(.*)*",
    redirect: (to) => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return {
        path: "/error",
        query: {
          error: 404,
          invalidPath: to.fullPath,
        },
      };
    },
  },
];

const router = (history?: RouterHistory) =>
  createRouter({
    history: history ?? createWebHistory(import.meta.env.BASE_URL),
    routes,
  });

export default router;
