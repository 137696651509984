<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { usePersonService } from "@/app/person/services/PersonService.ts";
import { usePersonServiceV2 } from "@/app/person/services/PersonServiceV2.ts";
import TagsEditCard from "@/app/process/components/field/TagsEditCard.vue";
import ValueField from "@/app/process/components/field/ValueField.vue";
import BaseButton from "@/base/components/button/BaseButton.vue";
import DeleteFieldDialog from "@/base/components/fields/DeleteFieldDialog.vue";
import FieldDialog from "@/base/components/fields/FieldDialog.vue";
import TopUsedFieldKeysSelector from "@/base/components/fields/TopUsedFieldKeysSelector.vue";
import TextField from "@/base/components/form/value/TextField.vue";
import { TitleMode, useTitle } from "@/base/composables/useTitle.ts";
import {
  EntityType,
  type FieldValueDto,
  type PersonDto,
} from "@/base/graphql/generated/types.ts";
import { useFieldService } from "@/base/services/FieldService.ts";

const props = defineProps<{
  personId: string;
}>();

const { t } = useI18n();

const personService = usePersonService();
const personServiceV2 = usePersonServiceV2();
const fieldService = useFieldService();

const fieldValueSelectedForDeletion = ref<FieldValueDto | undefined>();
const { selectedPerson } = storeToRefs(personServiceV2);

const tabTitle = computed(
  () => selectedPerson.value?.name ?? t("person.person", 2),
);
useTitle(tabTitle, TitleMode.EDIT);

const personFields = computed(() =>
  fieldService.getNonTagFieldValues(props.personId),
);

function openDeleteFieldDialog(fieldValue: FieldValueDto) {
  fieldValueSelectedForDeletion.value = fieldValue;
  fieldService.openDeleteFieldDialog();
}

async function updatePerson(update: Partial<PersonDto>) {
  if (!selectedPerson.value) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { references, fields, ...personWithoutFields } = selectedPerson.value;
  await personServiceV2.createOrUpdate({ ...personWithoutFields, ...update });
}
</script>

<template>
  <div v-if="selectedPerson">
    <VCardTitle>
      {{ t("person.edit") }}
    </VCardTitle>
    <VCard class="pa-4">
      <VForm>
        <div class="d-flex flex-column ga-4">
          <TagsEditCard
            :entityType="EntityType.Person"
            :entityId="personId"
            @delete="(id: string) => fieldService.deleteFieldWithInstances(id)"
          />
          <TextField
            :initialValue="selectedPerson.name ?? undefined"
            data-testid="person-name"
            :label="t('person.name')"
            @update="(value) => updatePerson({ name: value })"
          />
          <div class="d-flex flex-column ga-4">
            <ValueField
              v-for="fieldValue in personFields"
              :key="fieldValue.id"
              :fieldValueId="[fieldValue.id]"
              class="w-100"
              data-testid="entity-field"
              @delete="() => openDeleteFieldDialog(fieldValue)"
              @update="
                (update) => fieldService.createOrUpdateFieldValue(update)
              "
            />
          </div>
          <DeleteFieldDialog
            v-if="
              fieldService.isDeleteFieldDialogOpen &&
              fieldValueSelectedForDeletion
            "
            :fieldValueId="fieldValueSelectedForDeletion.id"
            :deleteMessage="
              t('person.deleteFieldText', {
                field:
                  fieldService.getFieldKey(
                    fieldValueSelectedForDeletion.fieldKeyId ?? '',
                  )?.name ?? '',
              })
            "
          />
          <div>
            <BaseButton
              outlined
              icon="mdi mdi-plus"
              data-testid="entity-add-field"
              :label="t('action.addSomething', { name: t('processes.field') })"
              @click="fieldService.openFieldDialog"
            />
            <FieldDialog
              v-if="fieldService.isFieldDialogOpen"
              :entityType="[EntityType.Person]"
              :entityId="personId"
            />
            <TopUsedFieldKeysSelector
              :entityId="personId"
              :fieldKeys="personService.getTopUsedFieldKeys"
            />
          </div>
        </div>
      </VForm>
    </VCard>
  </div>
</template>
