<script lang="ts" setup>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import TextDisplay from "@/base/components/form/TextDisplay.vue";
import { ActivityOutputType } from "@/base/graphql/generated/types.ts";
import { translateEnum } from "@/base/i18n/i18n.ts";

const props = defineProps<{
  outputId: string;
  readonly?: boolean;
}>();

const { t } = useI18n();

const processService = useProcessService();
const activityService = useActivityService();
const output = computed(() => {
  return activityService.getOutput(props.outputId);
});

function getActivityOutputTypes() {
  return Object.values(ActivityOutputType).map((rawType) => {
    return {
      name: translateEnum("processes.singleView.outputTypes", rawType),
      value: rawType,
    };
  });
}

function toActivityOutputType(type: string): ActivityOutputType {
  switch (type) {
    case ActivityOutputType.Boolean.toUpperCase():
      return ActivityOutputType.Boolean;
    case ActivityOutputType.Date.toUpperCase():
      return ActivityOutputType.Date;
    case ActivityOutputType.DateTime.toUpperCase():
      return ActivityOutputType.DateTime;
    case ActivityOutputType.File.toUpperCase():
      return ActivityOutputType.File;
    case ActivityOutputType.Document.toUpperCase():
      return ActivityOutputType.Document;
    case ActivityOutputType.Number.toUpperCase():
      return ActivityOutputType.Number;
    case ActivityOutputType.String.toUpperCase():
      return ActivityOutputType.String;
    case ActivityOutputType.Url.toUpperCase():
      return ActivityOutputType.Url;
    case ActivityOutputType.Person.toUpperCase():
      return ActivityOutputType.Person;
    case ActivityOutputType.People.toUpperCase():
      return ActivityOutputType.People;
    case ActivityOutputType.Json.toUpperCase():
      return ActivityOutputType.Json;
    default:
      throw new Error(`"${type}" is not a valid ActivityOutputType`);
  }
}

const value = ref<string>(output.value?.type ?? ActivityOutputType.String);

function createOrUpdateOutput() {
  activityService
    .createOrUpdateOutput(
      {
        id: props.outputId,
        type: toActivityOutputType(value.value),
      },
      output.value?.activityId ?? "undefined",
    )
    .then(() => {
      // if the output type of custom activity is changed, delete the existing output instance
      // from the cache. the backend deletes the output instance from the database.
      // this is necessary because custom activities have no versioning
      processService.deleteCustomOutputInstancesFromCache(props.outputId);
    })
    .catch((reason) => {
      console.error(reason);
    });
}
</script>

<template>
  <TextDisplay
    v-if="props.readonly"
    :label="t('processes.singleView.outputTypeLabel')"
    :value="
      output?.type
        ? translateEnum('processes.singleView.outputTypes', output?.type)
        : ''
    "
  />
  <VSelect
    v-else
    v-model="value"
    variant="outlined"
    density="compact"
    class="text-caeli6 w-100"
    :label="t('processes.singleView.outputTypeLabel')"
    :items="getActivityOutputTypes()"
    itemTitle="name"
    itemValue="value"
    hideDetails="auto"
    @blur="createOrUpdateOutput"
  >
    <template #selection="data">
      {{ translateEnum("processes.singleView.outputTypes", data.item.value) }}
    </template>
  </VSelect>
</template>
