import { defineStore } from "pinia";

import { useEntityEventStore } from "@/base/stores/entityevent/EntityEventStore";

export const useEntityEventService = defineStore("EntityEventService", () => {
  const entityEventStore = useEntityEventStore();

  function getAll(entityId: string) {
    return entityEventStore.getAll({ entityId });
  }

  function isLoading(entityId: string) {
    return entityEventStore.isLoading(undefined, { entityId });
  }

  function markRefetch(entityId: string) {
    entityEventStore.markRefetch(undefined, { entityId });
  }

  return {
    getAll,
    markRefetch,
    isLoading,
  };
});
