<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import { UI_DIALOG, useDialogService } from "@/base/services/DialogService.ts";

const router = useRouter();

const { t } = useI18n();

const activityService = useActivityService();
const dialogService = useDialogService();
const dialogOpen = computed(() =>
  dialogService.isDialogOpen(UI_DIALOG.CREATE_TEMPLATE),
);

function createTemplate() {
  activityService
    .createNewProcessTemplate(dialogService.dialogTemplateName)
    .then(
      (createdId) => {
        if (!createdId) {
          console.error("No ID returned from createNewProcessTemplate");
          return;
        }
        router
          .push({
            name: "processTemplate",
            params: { rootActivityId: createdId },
          })
          .catch((error) => console.error(error));
        dialogService.closeDialog(UI_DIALOG.CREATE_TEMPLATE);
      },
      (error) => console.error(error),
    );
}
</script>

<template>
  <VDialog v-model="dialogOpen" maxWidth="400">
    <VCard>
      <VCardTitle>{{ t("processes.createProcessTemplate") }}</VCardTitle>
      <VCardText>
        <VTextField
          v-model="dialogService.dialogTemplateName"
          density="compact"
          variant="outlined"
          :label="t('processes.nameLabel')"
          hideDetails="auto"
          data-testid="create-process-template-dialog-name"
        />
      </VCardText>
      <VCardActions class="d-flex flex-column">
        <VBtn
          variant="flat"
          color="caeli5"
          :disabled="
            !dialogService.dialogTemplateName ||
            !dialogService.dialogTemplateName.length
          "
          block
          data-testid="create-process-template-dialog-create-button"
          @click="createTemplate"
        >
          {{ t("action.create") }}
        </VBtn>
        <VBtn
          variant="flat"
          block
          @click="() => dialogService.closeDialog(UI_DIALOG.CREATE_TEMPLATE)"
        >
          {{ t("action.cancel") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
