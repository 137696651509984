import { type _GettersTree, defineStore, type StateTree } from "pinia";

import { type EntityStore } from "@/base/stores/entity/EntityStore.ts";
import { type RevisionEntry } from "@/base/utils/IdRelationLookups.ts";

export interface VersionStoreActions<E extends RevisionEntry> {
  getMid: (id: string) => string | undefined;
  isReleased: (id: string) => boolean;
  isArchived: (id: string) => boolean;
  getLastReleased: (mid: string) => E | undefined;
  getLastReleasedVersion: (id: string) => E | undefined;
  getRevisions: (mid: string) => E[];
  getMostRecent: (mid: string) => E | undefined;
}

export function defineVersionStore<
  E extends RevisionEntry,
  D extends { id: string },
>(storeId: string, entityStore: EntityStore<E, D>) {
  return defineStore<
    string,
    StateTree,
    _GettersTree<StateTree>,
    VersionStoreActions<E>
  >(storeId, {
    actions: {
      getRevisions(mid: string) {
        return entityStore
          .getFiltered("mid", mid)
          .sort((a, b) => b.revision - a.revision);
      },
      getMid(id: string) {
        return entityStore.getById(id)?.mid;
      },
      isReleased(id: string) {
        return entityStore.getById(id)?.released ?? false;
      },
      isArchived(id: string) {
        return entityStore.getById(id)?.archived ?? false;
      },
      getLastReleased(mid: string) {
        return this.getRevisions(mid).find((r) => r.released);
      },
      getLastReleasedVersion(id: string) {
        const mid = this.getMid(id);
        return mid ? this.getLastReleased(mid) : undefined;
      },
      getMostRecent(mid: string) {
        return this.getRevisions(mid)[0];
      },
    },
  });
}
