import { type DocumentNode } from "@apollo/client/core";

import { type Eto } from "@/base/graphql/generated/types.ts";
import { apolloClient } from "@/base/plugins/apollo/apollo.ts";

export interface QueryParams {
  queryName: string;
  listQuery?: DocumentNode;
  singleQuery?: DocumentNode;
  mutation?: DocumentNode;
  deleteMutation?: DocumentNode;
}

export class ApolloCrudRepo<E extends Eto, D extends { id: string }> {
  constructor(private readonly params: QueryParams) {
    this.params = params;
  }

  async findAll(queryVariables?: Record<string, unknown>): Promise<E[]> {
    if (!this.params.listQuery) {
      return Promise.reject(new Error("listQuery is not defined"));
    }
    return apolloClient
      .query<Record<string, E[]>>({
        query: this.params.listQuery,
        variables: { filter: queryVariables },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        return result.data[this.params.queryName];
      });
  }

  async findByIds(ids: string[]) {
    if (!this.params.singleQuery) {
      return Promise.reject(new Error("singleQuery is not defined"));
    }
    return apolloClient
      .query<Record<string, E[]>>({
        query: this.params.singleQuery,
        variables: { filter: { ids } },
        fetchPolicy: "no-cache",
      })
      .then((result) => result.data[this.params.queryName]);
  }

  async deleteById(id: string) {
    if (!this.params.deleteMutation) {
      return Promise.reject(new Error("deleteMutation is not defined"));
    }
    return apolloClient
      .mutate({
        mutation: this.params.deleteMutation,
        variables: { id },
      })
      .then(() => {
        return true;
      });
  }

  async createOrUpdate(dto: D, options = {}): Promise<E> {
    if (!this.params.mutation) {
      return Promise.reject(new Error("mutation is not defined"));
    }
    return apolloClient
      .mutate<Record<string, E>>({
        mutation: this.params.mutation,
        variables: { dto, ...options },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        if (!result.data) {
          return Promise.reject(new Error("No data returned on mutation"));
        }
        return result.data[this.params.queryName];
      });
  }
}
