/* istanbul ignore file */
/* eslint-disable */
import type { CancelablePromise } from "@/base/services/backend";
import { OpenAPI } from "../core/OpenAPI.ts";
import {
  request as __request,
  requestBlob as __requestBlob,
} from "../core/request.ts";
import { type FileEto } from "@/base/graphql/generated/types.ts";

export class FileControllerService {
  public static upload(id: string, file: File): CancelablePromise<FileEto> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("uuid", id);

    return __request(OpenAPI, {
      method: "POST",
      url: "/api/file",
      body: formData,
    });
  }

  public static download(id: string): CancelablePromise<ArrayBuffer> {
    return __request(OpenAPI, {
      method: "GET",
      url: `/api/file/${id}`,
      isArrayBuffer: true,
    });
  }

  public static downloadMultiple(ids: Array<string>): CancelablePromise<Blob> {
    return __requestBlob(OpenAPI, {
      method: "POST",
      url: `/api/files/download`,
      body: {
        fileRequests: ids.map((id) => ({ id })),
      },
    });
  }

  public static getFileMetadata(id: string): CancelablePromise<FileEto> {
    return __request(OpenAPI, {
      method: "GET",
      url: `/api/file/metadata/${id}`,
    });
  }

  public static remove(id: string): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: `/api/file/${id}`,
    });
  }
}
