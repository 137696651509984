<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import StartProcessDialogSpecificButton from "@/app/process/components/action/start/StartProcessDialogSpecificButton.vue";
import { useBlockedActionStore } from "@/app/process/stores/action/BlockedActionStore.ts";
import BaseButton from "@/base/components/button/BaseButton.vue";
import { useDialogService } from "@/base/services/DialogService.ts";

const props = defineProps<{
  rootActivityMid: string;
}>();

const { t } = useI18n();

const activityService = useActivityService();
const blockerStore = useBlockedActionStore();
const dialogService = useDialogService();

const current = computed(() =>
  activityService.getCurrentVersion(props.rootActivityMid),
);

const lastReleased = computed(() =>
  activityService.getLastReleased(props.rootActivityMid),
);

const allActivities = computed(() =>
  current.value
    ? [
        current.value,
        ...activityService.getDescendantActivities(
          current.value.id ?? "undefined",
        ),
      ]
    : [],
);

const reviseBlocked = computed(() =>
  blockerStore.isBlocked(lastReleased.value?.id ?? ""),
);
</script>

<template>
  <div class="flex flex-row items-center gap-2 py-1 h-6">
    <BaseButton
      v-if="current && lastReleased && current.id === lastReleased.id"
      v-tooltip.left="t('action.revise')"
      text
      icon="mdi mdi-pencil"
      :loading="reviseBlocked"
      @click.stop.prevent="
        () => activityService.reviseProcessTemplate(current?.id ?? 'undefined')
      "
    />

    <StartProcessDialogSpecificButton
      v-if="lastReleased"
      :rootActivityId="lastReleased.id"
    />

    <BaseButton
      v-tooltip.left="t('action.delete')"
      text
      severity="danger"
      icon="mdi mdi-delete"
      @click.stop.prevent="
        () => dialogService.openActivityDeleteDialog(allActivities)
      "
    />
  </div>
</template>
