import {
  DocumentGenerationRunDocument,
  type DocumentGenerationRunDto,
  type DocumentGenerationRunEto,
  GetDocumentGenerationRunDocument,
  GetDocumentGenerationRunListDocument,
} from "@/base/graphql/generated/types.ts";
import {
  defineEntityStore,
  EntityProvider,
} from "@/base/stores/entity/EntityStore.ts";
import { PendingPersistedLookupDual } from "@/base/utils/IdRelationLookups.ts";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual(
    (eto: DocumentGenerationRunEto) =>
      ({ id: eto.id }) as DocumentGenerationRunDto,
  ),
  {
    queryName: "documentGenerationRun",
    singleQuery: GetDocumentGenerationRunDocument,
    listQuery: GetDocumentGenerationRunListDocument,
    mutation: DocumentGenerationRunDocument,
  },
);

export const useDocumentGenerationRunStore = defineEntityStore(
  "documentGenerationRun",
  entityProvider,
);
