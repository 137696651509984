import { useActivityStore } from "@/app/activity/activity/ActivityEntityStore.ts";
import { processActivityToDto } from "@/app/process/services/mapper/ProcessMapper.ts";
import { useProcessOutputStore } from "@/app/process/stores/process/ProcessOutputEntityStore.ts";
import { useProcessTaskStore } from "@/app/process/stores/process/ProcessTaskEntityStore.ts";
import {
  DeleteProcessActivityDocument,
  GetProcessActivityDocument,
  GetProcessActivityListDocument,
  ProcessActivityDocument,
  type ProcessActivityDto,
  type ProcessActivityEto,
} from "@/base/graphql/generated/types.ts";
import {
  defineEntityStore,
  EntityProvider,
} from "@/base/stores/entity/EntityStore.ts";
import { useFieldValueStore } from "@/base/stores/field/FieldValueStore.ts";
import { PendingPersistedLookupDual } from "@/base/utils/IdRelationLookups.ts";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual<ProcessActivityEto, ProcessActivityDto>(
    processActivityToDto,
  ),
  {
    queryName: "processActivity",
    listQuery: GetProcessActivityListDocument,
    singleQuery: GetProcessActivityDocument,
    mutation: ProcessActivityDocument,
    deleteMutation: DeleteProcessActivityDocument,
  },
  {
    indexFields: ["processId"],
    onAfterRegister(etos) {
      useActivityStore().registerAllLoaded(
        etos.map((eto) => eto.activity) ?? [],
      );
      useProcessOutputStore().registerAllLoaded(
        etos.flatMap((eto) => eto.outputs ?? []),
      );
      useProcessTaskStore().registerAllLoaded(
        etos.flatMap((eto) => eto.tasks ?? []),
      );
      useFieldValueStore().registerAllLoaded(
        etos.flatMap((eto) => eto.fields ?? []),
      );
    },
    onBeforeUnregister(etos) {
      useProcessOutputStore().unregisterAllLoaded(
        etos.flatMap((eto) => eto.outputs ?? []).map((output) => output.id),
      );
      useProcessTaskStore().unregisterAllLoaded(
        etos.flatMap((eto) => eto.tasks ?? []).map((task) => task.id),
      );
    },
  },
);

export const useProcessActivityStore = defineEntityStore(
  "processActivity",
  entityProvider,
);
