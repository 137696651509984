<script lang="ts" setup>
import PromptDisplay from "@/base/components/notification/PromptDisplay.vue";
import { usePromptService } from "@/base/services/notification/PromptService.ts";

const promptService = usePromptService();
</script>

<template>
  <div
    class="d-flex flex-column flex-wrap ga-4 position-fixed t-24 r-8 z-10k"
    style="z-index: 10000"
  >
    <PromptDisplay
      v-for="prompt in promptService.prompts"
      :key="prompt.key"
      :prompt="prompt"
    />
  </div>
</template>
