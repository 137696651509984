import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import { i18n } from "@/base/i18n/i18n.ts";

const { t } = i18n.global;

export const menuItems: BaseMenuItem[] = [
  {
    label: `${t("linkTranslationKey.dashboard")}`,
    route: "dashboard",
    icon: "mdi mdi-view-dashboard",
  },
  {
    label: `${t("linkTranslationKey.personList")}`,
    route: "personList",
    icon: "mdi mdi-account-multiple",
  },
  {
    label: `${t("linkTranslationKey.mailList")}`,
    route: "messageList",
    icon: "mdi mdi-email",
  },
  {
    label: `${t("linkTranslationKey.documentList")}`,
    route: "documentList",
    icon: "mdi mdi-file-document-multiple",
  },
  {
    label: `${t("linkTranslationKey.activeProcesses")}`,
    route: "processList",
    icon: "mdi mdi-sitemap",
  },
  // Will be activated later on
  // {
  //   label: `${t("linkTranslationKey.processTemplates")}`,
  //   route: "processTemplateList",
  //   icon: "mdi mdi-file-cog",
  // },
  {
    label: `${t("linkTranslationKey.taskList")}`,
    route: "processActivityList",
    icon: "mdi mdi-checkbox-marked",
  },
];
