<script lang="ts" setup>
import { v4 as uuidv4 } from "uuid";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import ActivityOutputEditCard from "@/app/process/components/output/ActivityOutputEditCard.vue";
import TextField from "@/base/components/form/value/TextField.vue";
import { UI_DIALOG, useDialogService } from "@/base/services/DialogService.ts";

const { t } = useI18n();

const activityService = useActivityService();
const dialogService = useDialogService();
const dialogOpen = computed(() =>
  dialogService.isDialogOpen(UI_DIALOG.EDIT_ROOT_TEMPLATE),
);

const rootActivityId = computed(() => dialogService.dialogActivityList[0].id);

const startParameters = computed(() =>
  activityService.getOutputs(rootActivityId.value),
);
</script>

<template>
  <VDialog v-model="dialogOpen" maxWidth="800">
    <VCard>
      <VCardTitle>{{ t("processes.processTemplate") }}</VCardTitle>
      <VCardText class="d-flex flex-column ga-4">
        <TextField
          density="compact"
          variant="outlined"
          :label="t('processes.nameLabel')"
          hideDetails="auto"
          @update="
            (value) =>
              activityService.createOrUpdateActivity({
                id: rootActivityId,
                name: value,
              })
          "
        ></TextField>
        <div>
          <div
            class="text-caption bg-white px-1 text-center"
            style="
              position: relative;
              margin-bottom: -0.65rem;
              margin-left: 0.75rem;
              max-width: 7rem;
              z-index: 10;
              color: #aabbcc !important;
            "
          >
            {{ t("processes.startParameter") }}
          </div>
          <VCard variant="flat" class="border">
            <div class="pt-4 px-4 d-flex flex-column ga-2">
              <ActivityOutputEditCard
                v-for="output in startParameters"
                :key="output.id"
                :activityId="rootActivityId"
                :outputId="output.id"
                compact
              />
              <VBtn
                variant="text"
                class="text-caeli5"
                @click="
                  activityService.createOrUpdateOutput(
                    { id: uuidv4() },
                    rootActivityId,
                  )
                "
                >{{
                  t("action.addSomething", {
                    name: t("processes.startParameter"),
                  })
                }}
              </VBtn>
            </div>
          </VCard>
        </div>
      </VCardText>
      <VCardActions class="d-flex flex-column">
        <VBtn
          variant="flat"
          block
          @click="() => dialogService.closeDialog(UI_DIALOG.EDIT_ROOT_TEMPLATE)"
        >
          {{ t("action.close") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
