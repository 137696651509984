<script lang="ts" setup>
import { v4 as uuidv4 } from "uuid";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import EntityHistoryTooltip from "@/app/process/components/entity/EntityHistoryTooltip.vue";
import ProcessOutputField from "@/app/process/components/output/ProcessOutputField.vue";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import TextDisplay from "@/base/components/form/TextDisplay.vue";
import {
  ProcessActivityState,
  ProcessOutputState,
} from "@/base/graphql/generated/types.ts";
import { isValueProvided } from "@/base/services/utils.ts";

const { t } = useI18n();

const props = defineProps<{
  processActivityId: string;
  activityOutputId: string;
  customCaption?: string;
}>();

const activityService = useActivityService();
const processService = useProcessService();

const output = computed(() => {
  return processService.getProcessOutput(
    props.processActivityId,
    props.activityOutputId,
  );
});

const isOutputImportant = computed(() => {
  return output.value?.important ?? activityOutput.value?.important ?? false;
});

const isOutputSkipped = computed(
  () => output.value?.state === ProcessOutputState.Skipped,
);

const isProcessActivitySkipped = computed(
  () =>
    processService.getProcessActivity(props.processActivityId)?.status
      ?.state === ProcessActivityState.Skipped,
);

const skipOutputActionTooltipText = computed(() => {
  if (isProcessActivitySkipped.value) {
    return t("processes.skippedActivity");
  } else if (isOutputSkipped.value) {
    return t("processes.unsetSkipped");
  } else {
    return t("processes.setSkipped");
  }
});

function toggleImportant() {
  if (!output.value) {
    return;
  }
  processService
    .createOrUpdateOutput({
      id: output.value?.id,
      important: !isOutputImportant.value,
      processActivityId: props.processActivityId,
      activityOutputId: props.activityOutputId,
    })
    .catch((reason) => {
      console.error(reason);
    });
}

const togglingSkippedState = ref(false);

function toggleSkippedState() {
  togglingSkippedState.value = true;
  processService
    .createOrUpdateOutput({
      id: output.value?.id ?? uuidv4(),
      processActivityId: props.processActivityId,
      activityOutputId: props.activityOutputId,
      skipped: !isOutputSkipped.value,
    })
    .catch((reason) => console.error(reason))
    .finally(() => (togglingSkippedState.value = false));
}

const activityOutput = computed(() => {
  return activityService.getOutput(props.activityOutputId);
});
</script>

<template>
  <VCard v-if="activityOutput" variant="flat">
    <div class="mt-n1 pl-4 text-caption text-blue-grey-lighten-3">
      {{ props.customCaption ?? t("processes.output") }}
    </div>
    <div class="d-flex ga-4 align-start px-3">
      <VCheckboxBtn
        :modelValue="isValueProvided(activityOutput, output)"
        color="caeli6"
        density="compact"
        :disabled="true"
      />
      <div class="d-flex flex-column w-100">
        <div class="headline">
          <span
            class="text-caeli6 text-wrap text-body-2"
            style="padding-top: 0.3rem"
            >{{ activityOutput.name ?? "" }}
          </span>
          <div class="tool-tips">
            <EntityHistoryTooltip :entityId="output?.id" />
            <VTooltip :text="skipOutputActionTooltipText">
              <template #activator="{ props: activator }">
                <span v-bind="activator">
                  <VBtn
                    variant="plain"
                    size="medium"
                    icon="mdi-cancel"
                    :color="isOutputSkipped ? 'caeli5' : undefined"
                    :disabled="isProcessActivitySkipped"
                    :loading="togglingSkippedState"
                    @click="toggleSkippedState"
                  />
                </span>
              </template>
            </VTooltip>
            <VTooltip location="start" :text="t('processes.outputFavorite')">
              <template #activator="{ props: activator }">
                <VIcon
                  v-bind="activator"
                  :icon="isOutputImportant ? 'mdi-star' : 'mdi-star-outline'"
                  class="text-caeli5"
                  @click="toggleImportant"
                />
              </template>
            </VTooltip>
          </div>
        </div>
        <TextDisplay
          v-if="activityOutput.description"
          :value="activityOutput.description"
          markdown
        />
        <div
          class="d-flex justify-start align-center text-caeli6 w-100 text-body-2 mt-2"
        >
          <ProcessOutputField
            :processActivityId="props.processActivityId"
            :activityOutputId="props.activityOutputId"
          />
        </div>
      </div>
    </div>
  </VCard>
</template>

<style scoped>
.headline {
  display: grid;
  grid-template-columns: 1fr auto;
}

.tool-tips {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
</style>
