<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import UpgradeChangesSubItem from "@/app/process/components/action/upgrade/UpgradeChangesSubItem.vue";
import UpgradeProcessValueMigration from "@/app/process/components/action/upgrade/UpgradeProcessValueMigration.vue";
import {
  type ActivityChanges,
  type ChangeLookup,
  findFieldChanges,
  getSubTypeChanges,
} from "@/app/process/components/action/upgrade/UpgradeUtils.ts";
import {
  activityInputToDto,
  activityOutputToDto,
  activityTaskToDto,
  activityToDto,
} from "@/app/process/services/mapper/ActivityMapper.ts";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import TextDisplay from "@/base/components/form/TextDisplay.vue";
import { type ActivityOutputDto } from "@/base/graphql/generated/types.ts";
import { translateEnum } from "@/base/i18n/i18n.ts";
import { UI_DIALOG, useDialogService } from "@/base/services/DialogService.ts";
import { isValueProvided } from "@/base/services/utils.ts";
import { mapBy, union } from "@/base/utils/array.ts";

const props = defineProps<{
  processId: string;
}>();

const activityService = useActivityService();
const processService = useProcessService();
const dialogService = useDialogService();

const { t } = useI18n();

const isLoading = ref<boolean>(false);
const isUpgrading = ref<boolean>(false);

const updateDialogOpen = computed(() =>
  dialogService.isDialogOpen(UI_DIALOG.UPGRADE_PROCESS),
);

watch(updateDialogOpen, async (isDialogOpen, oldIsDialogOpen) => {
  if (!oldIsDialogOpen && isDialogOpen) {
    isLoading.value = true;
    await activityService.refetchRootActivities();
    isLoading.value = false;
  }
});

const process = computed(() => {
  return processService.getProcess(props.processId);
});

const referencedRootActivity = computed(() =>
  activityService.getActivity(process.value?.startActivityId ?? "undefined"),
);
const referencedGraph = computed(() => {
  return (
    (referencedRootActivity.value &&
      activityService.getGraph(referencedRootActivity.value.id)) ??
    []
  );
});
const referencedActivityIds = computed(() =>
  referencedGraph.value.map((activity) => activity.id),
);
const lastReleasedRoot = computed(() =>
  activityService.getLastReleasedVersion(
    referencedRootActivity.value?.id ?? "undefined",
  ),
);
const lastReleasedGraph = computed(
  () =>
    (lastReleasedRoot.value &&
      activityService.getGraph(lastReleasedRoot.value.id)) ??
    [],
);
const lastReleasedActivityIds = computed(() =>
  lastReleasedGraph.value.map((activity) => activity.id),
);
const activityVersionsByMid = computed(() =>
  mapBy(
    union(referencedGraph.value, lastReleasedGraph.value),
    (activity) => activityService.getMid(activity.id) ?? "undefined",
  ),
);
const addedActivities = computed(() =>
  [...activityVersionsByMid.value.values()].flatMap((versions) =>
    versions.length === 1 &&
    lastReleasedActivityIds.value.includes(versions[0].id)
      ? versions[0]
      : [],
  ),
);
const removedActivities = computed(() =>
  [...activityVersionsByMid.value.values()].flatMap((versions) =>
    versions.length === 1 &&
    referencedActivityIds.value.includes(versions[0].id)
      ? versions[0]
      : [],
  ),
);
const changes = computed((): ActivityChanges[] =>
  [...activityVersionsByMid.value.entries()].flatMap((versionsByMid) => {
    if (versionsByMid[1].length !== 2) {
      return [];
    }
    const [oldActivity, newActivity] = versionsByMid[1];

    const activityChanges = findFieldChanges(
      activityToDto(oldActivity),
      activityToDto(newActivity),
      ["tasks", "outputs", "inputs", "fields"],
    );
    const taskChanges = getSubTypeChanges(
      activityService
        .getTasks(oldActivity.id)
        .map((task) => activityTaskToDto(task)),
      activityService
        .getTasks(newActivity.id)
        .map((task) => activityTaskToDto(task)),
      activityService.getTaskMid,
    );
    const outputChanges = getSubTypeChanges(
      activityService
        .getOutputs(oldActivity.id)
        .map((output) => activityOutputToDto(output)),
      activityService
        .getOutputs(newActivity.id)
        .map((output) => activityOutputToDto(output)),
      activityService.getOutputMid,
    );
    const inputChanges = getSubTypeChanges(
      activityService
        .getInputs(oldActivity.id)
        .map((input) => activityInputToDto(input)),
      activityService
        .getInputs(newActivity.id)
        .map((input) => activityInputToDto(input)),
      activityService.getInputMid,
    );
    if (
      !activityChanges.changedKeys.length &&
      !taskChanges.length &&
      !outputChanges.length &&
      !inputChanges.length
    ) {
      return [];
    }
    return {
      mid: versionsByMid[0],
      previous: oldActivity,
      current: newActivity,
      activityChanges,
      taskChanges,
      outputChanges,
      inputChanges,
    };
  }),
);
const migrationRequiredOnProcessOutputIds = computed(() =>
  changes.value.flatMap((change) =>
    change.outputChanges.flatMap((outputChange) => {
      if (!outputChange.changedKeys.includes("type")) {
        return [];
      }
      const processActivityId = processService.getProcessActivityByTemplate(
        props.processId,
        change.previous.id,
      )?.id;
      if (!processActivityId) {
        return [];
      }
      const previousActivityOutput = outputChange.previous;
      if (!previousActivityOutput) {
        return [];
      }
      const processOutput = processService.getProcessOutput(
        processActivityId,
        previousActivityOutput.id,
      );
      return processOutput &&
        isValueProvided(previousActivityOutput, processOutput)
        ? processOutput.id
        : [];
    }),
  ),
);

const migratedOutputIds = computed(() =>
  [
    ...processService
      .getAllMigrationStates(
        props.processId,
        lastReleasedRoot.value?.id ?? "undefined",
      )
      .entries(),
  ]
    .filter(([, state]) => state)
    .map(([outputId]) => outputId),
);

async function updateProcess() {
  isUpgrading.value = true;
  const lastReleasedRootId = lastReleasedRoot.value?.id ?? "undefined";
  const migratedOutputs = processService.getAllMigrationFields(
    props.processId,
    lastReleasedRootId,
  );
  await processService.upgradeProcess(props.processId, lastReleasedRootId, [
    ...migratedOutputs.values(),
  ]);
  dialogService.closeDialog(UI_DIALOG.UPGRADE_PROCESS);
  isUpgrading.value = false;
}

function isMigrationRequired(
  outputChange: ChangeLookup<ActivityOutputDto>,
  activityId: string,
) {
  return (
    outputChange.changedKeys.includes("type") &&
    migrationRequiredOnProcessOutputIds.value.includes(
      processService.getProcessOutput(
        processService.getProcessActivityByTemplate(props.processId, activityId)
          ?.id ?? "undefined",
        outputChange.previous?.id ?? "undefined",
      )?.id ?? "undefined",
    )
  );
}
</script>

<template>
  <VDialog v-model="updateDialogOpen" maxWidth="1000">
    <VCard class="pa-3 text-caeli2">
      <VCardTitle>
        {{ t("processes.upgraded", { name: referencedRootActivity?.name }) }}
      </VCardTitle>

      <VCardText v-if="isLoading" class="text-center">
        <VProgressCircular indeterminate color="caeli5" />
      </VCardText>
      <VCardText
        v-else
        class="overflow-y-auto d-flex flex-column text-body-2 ga-3"
      >
        <div v-if="removedActivities.length" class="text-red-darken-4">
          <div>
            {{
              t(
                "action.thingsWereRemoved",
                {
                  count: removedActivities.length,
                  name: t("processes.activity", removedActivities.length),
                },
                changes.length,
              )
            }}
            <ul class="pl-4 text-caption">
              <li
                v-for="removedActivity in removedActivities"
                :key="removedActivity.id"
              >
                {{
                  t("common.categoryName", {
                    category: t("processes.activity"),
                    name: removedActivity.name,
                  })
                }}
              </li>
            </ul>
          </div>
        </div>
        <div v-if="addedActivities.length" class="text-green-darken-2">
          {{
            t(
              "action.thingsWereAdded",
              {
                count: addedActivities.length,
                name: t("processes.activity", addedActivities.length),
              },
              changes.length,
            )
          }}
          <div>
            <ul class="pl-4 text-caption">
              <li
                v-for="addedActivity in addedActivities"
                :key="addedActivity.id"
              >
                {{
                  t("common.categoryName", {
                    category: t("processes.activity"),
                    name: addedActivity.name,
                  })
                }}
              </li>
            </ul>
          </div>
        </div>
        <div
          v-if="changes.length"
          class="text-teal-darken-4 d-flex flex-column text-subtitle-2 ga-3"
        >
          {{
            t(
              "action.thingsWereChanged",
              {
                count: changes.length,
                name: t("processes.activity", changes.length),
              },
              changes.length,
            )
          }}
          <VCard
            v-for="change in changes"
            :key="change.mid"
            class="border-card rounded-lg"
          >
            <div class="py-1 px-3">
              <TextDisplay
                :value="
                  change.activityChanges.changedKeys.includes('name')
                    ? t('action.somethingWasRenamed', {
                        old: t('common.categoryName', {
                          category: t('processes.activity'),
                          name: `**${change.previous.name}**`,
                        }),
                        new: t('common.quoted', {
                          text: `**${change.current.name}**`,
                        }),
                      })
                    : t('common.categoryName', {
                        category: t('processes.activity'),
                        name: `**${change.previous.name}**`,
                      })
                "
                :markdown="true"
              />
            </div>
            <div v-if="change.outputChanges.length" class="text-caption">
              <div
                v-for="outputChange in change.outputChanges"
                :key="
                  outputChange.previous?.id ??
                  outputChange.current?.id ??
                  'undefined'
                "
                class="border-t"
              >
                <div class="py-2 px-6">
                  <UpgradeChangesSubItem
                    :change="outputChange"
                    :entityNameProvider="(output) => output.name ?? ''"
                    :categoryLabel="t('processes.output')"
                    :excludeKeys="['name', 'type', 'sortOrder']"
                  />
                  <TextDisplay
                    v-if="outputChange.changedKeys.includes('type')"
                    :value="
                      t('processes.outputTypeChanged', {
                        oldType: outputChange.previous?.type
                          ? `**${translateEnum(
                              'processes.singleView.outputTypes',
                              outputChange.previous.type,
                            )}**`
                          : '',
                        newType: outputChange.current?.type
                          ? `**${translateEnum(
                              'processes.singleView.outputTypes',
                              outputChange.current.type,
                            )}**`
                          : '',
                      })
                    "
                    :markdown="true"
                  />
                  <UpgradeProcessValueMigration
                    v-if="isMigrationRequired(outputChange, change.previous.id)"
                    :processId="props.processId"
                    :targetStartActivityId="lastReleasedRoot?.id ?? 'undefined'"
                    :processActivityId="
                      processService.getProcessActivityByTemplate(
                        processId,
                        change.previous.id,
                      )?.id ?? 'undefined'
                    "
                    :previousOutputId="outputChange.previous?.id ?? ''"
                    :previousType="outputChange.previous?.type ?? undefined"
                    :currentType="outputChange.current?.type ?? undefined"
                  />
                </div>
              </div>
            </div>
            <div v-if="change.taskChanges.length" class="text-caption">
              <div
                v-for="taskChange in change.taskChanges"
                :key="
                  taskChange.previous?.id ??
                  taskChange.current?.id ??
                  'undefined'
                "
                class="border-t"
              >
                <div class="py-2 px-6">
                  <UpgradeChangesSubItem
                    :change="taskChange"
                    :entityNameProvider="(task) => task.title ?? ''"
                    :categoryLabel="t('processes.task')"
                    :excludeKeys="['title', 'sortOrder']"
                  />
                </div>
              </div>
            </div>
            <div v-if="change.inputChanges.length" class="text-caption">
              <div
                v-for="inputChange in change.inputChanges"
                :key="
                  inputChange.previous?.id ??
                  inputChange.current?.id ??
                  'undefined'
                "
                class="border-t"
              >
                <div class="py-2 px-6">
                  <UpgradeChangesSubItem
                    :change="inputChange"
                    :entityNameProvider="
                      (input) =>
                        activityService.getOutput(input.outputId)?.name ?? ''
                    "
                    :categoryLabel="t('processes.input')"
                  />
                </div>
              </div>
            </div>
          </VCard>
        </div>
        <p
          v-if="
            !addedActivities.length &&
            !removedActivities.length &&
            !changes.length
          "
          class="text-blue-grey-lighten-2 text-center text-subtitle-1"
        >
          {{ t("common.noneOf", { name: t("common.change", 2) }) }}
        </p>
      </VCardText>

      <div class="d-flex flex-column align-center pa-2">
        <VBtn
          color="caeli5"
          variant="flat"
          :text="t('action.apply')"
          :block="true"
          :loading="isUpgrading"
          :disabled="
            migratedOutputIds.length !==
              migrationRequiredOnProcessOutputIds.length ||
            isLoading ||
            isUpgrading
          "
          data-testid="apply-upgrade-button"
          @click="updateProcess"
        />
        <VBtn
          variant="flat"
          :text="t('action.cancel')"
          :block="true"
          :disabled="isUpgrading"
          @click="dialogService.closeDialog(UI_DIALOG.UPGRADE_PROCESS)"
        />
      </div>
    </VCard>
  </VDialog>
</template>
