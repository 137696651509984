<script setup lang="ts">
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useMessageServiceV2 } from "@/app/message/services/MessageServiceV2.ts";
import PersonReadonlyField from "@/app/process/components/field/PersonReadonlyField.vue";
import ReadonlyField from "@/app/process/components/field/ReadonlyField.vue";
import TagsDisplay from "@/app/process/components/field/TagsDisplay.vue";
import FloatingActionButton from "@/base/components/button/FloatingActionButton.vue";
import AppDialog from "@/base/components/dialog/AppDialog.vue";
import EntityFieldsCard from "@/base/components/fields/EntityFieldsCard.vue";
import TextDisplay from "@/base/components/form/TextDisplay.vue";
import { useTitle } from "@/base/composables/useTitle.ts";
import { EntityType } from "@/base/graphql/generated/types.ts";

const router = useRouter();

const props = defineProps<{
  messageId: string;
}>();

const { t } = useI18n();
const messageService = useMessageServiceV2();

const { selectedMessage: message } = storeToRefs(messageService);

useTitle(t("message.message", 1));

const isDeleteMessageDialogOpen = ref<boolean>(false);
</script>

<template>
  <AppDialog
    v-model="isDeleteMessageDialogOpen"
    :title="t('message.deleteMessage')"
    :confirmMessage="t('action.delete')"
    :cancelMessage="t('action.cancel')"
    destructive
    @confirm="
      () => {
        if (message?.id) {
          messageService.deleteMessage(message.id);
        }
      }
    "
    @cancel="isDeleteMessageDialogOpen = false"
  >
    <p>
      {{
        t("message.deleteMessageText", {
          name: message?.title,
        })
      }}
    </p>
  </AppDialog>

  <div v-if="message">
    <div class="d-flex align-center justify-space-between">
      <VCardTitle>{{ t("message.message") }}</VCardTitle>
      <TagsDisplay class="mr-4" :entityId="message.id" justify="end" />
    </div>
    <VCard class="pa-4 d-flex flex-column ga-4">
      <ReadonlyField
        :label="t('message.title')"
        :value="message?.title ?? ''"
        data-testid="message-view-title"
      />

      <TextDisplay
        :label="t('message.body')"
        :value="message?.body ?? ''"
        data-testid="message-view-body"
        markdown
      />

      <EntityFieldsCard
        :entityType="[EntityType.Message]"
        :entityId="props.messageId"
        readonly
      />
      <PersonReadonlyField
        v-if="message.authorId"
        :personId="message.authorId"
        label="Autor"
      />
    </VCard>
    <FloatingActionButton
      icon="mdi-pencil"
      first
      primary
      data-testid="message-edit"
      @click="
        () => {
          router.push({ name: 'messageEdit', params: { messageId } });
        }
      "
    />
    <FloatingActionButton
      icon="mdi-trash-can-outline"
      data-testid="message-delete"
      color="error"
      @click="isDeleteMessageDialogOpen = true"
    />
  </div>
</template>
