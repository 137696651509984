<script lang="ts" setup>
import { v4 as uuidv4 } from "uuid";
import { type Component, computed } from "vue";
import { useI18n } from "vue-i18n";

import EntityHistoryTooltip from "@/app/process/components/entity/EntityHistoryTooltip.vue";
import AddressField from "@/app/process/components/field/AddressField.vue";
import BooleanField from "@/app/process/components/field/BooleanField.vue";
import DateField from "@/app/process/components/field/DateField.vue";
import FileField from "@/app/process/components/field/FileField.vue";
import NumberField from "@/app/process/components/field/NumberField.vue";
import ObjectField from "@/app/process/components/field/ObjectField.vue";
import PersonField from "@/app/process/components/field/PersonField.vue";
import StringField from "@/app/process/components/field/StringField.vue";
import UrlField from "@/app/process/components/field/UrlField.vue";
import {
  FieldType,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";
import { useFieldService } from "@/base/services/FieldService.ts";

const props = withDefaults(
  defineProps<{
    fieldValueId: string[];
    targetEntityId?: string;
    readonly?: boolean;
    showLabel?: boolean;
    wrap?: boolean;
  }>(),
  {
    targetEntityId: undefined,
    showLabel: true,
    wrap: false,
  },
);

const { t } = useI18n();
const emit = defineEmits<{
  (e: "delete"): void;
  (e: "update", value: FieldValueDto): void;
}>();

const fieldService = useFieldService();

const fieldValues = computed(() =>
  props.fieldValueId
    .map(fieldService.getFieldValue)
    .filter((value) => value !== undefined),
);
const isInherited = computed(() => {
  if (!props.targetEntityId || fieldValues.value.length === 0) {
    return false;
  }
  return fieldValues.value.every(
    (fieldValue) => fieldValue?.entityId !== props.targetEntityId,
  );
});
const fieldKey = computed(() => {
  if (fieldValues.value.length === 0 || !fieldValues.value[0]) {
    return undefined;
  }
  return fieldService.getFieldKey(fieldValues.value[0].fieldKeyId);
});

function emitUpdate(value: FieldValueDto) {
  // That's the case when eg. a field from an ancestor is overridden in the current entity.
  if (props.targetEntityId && value.entityId !== props.targetEntityId) {
    value.id = uuidv4();
    value.entityId = props.targetEntityId;
  }
  emit("update", value);
}

const componentByType = new Map<FieldType, Component>([
  [FieldType.Date, DateField],
  [FieldType.Person, PersonField],
  [FieldType.Address, AddressField],
  [FieldType.Json, ObjectField],
  [FieldType.String, StringField],
  [FieldType.Url, UrlField],
  [FieldType.Boolean, BooleanField],
  [FieldType.Number, NumberField],
  [FieldType.File, FileField],
]);

function emitDelete() {
  emit("delete");
}
</script>

<template>
  <div
    v-if="fieldValues.length > 0 && fieldKey"
    data-testid="entity-field"
    class="text-caeli6 w-full flex flex-row gap-2"
    :class="{ 'flex-wrap': props.wrap }"
  >
    <div
      v-for="(fieldValue, index) in fieldValues"
      :key="fieldValue.id"
      class="flex flex-row gap-4 field-value"
      :class="{ 'w-100': !props.readonly }"
    >
      <Component
        :is="componentByType.get(fieldKey.type)"
        v-if="componentByType.has(fieldKey.type)"
        :fieldKey="fieldKey"
        :fieldValue="fieldValue"
        :label="props.showLabel ? fieldKey.name : undefined"
        :readonly="props.readonly"
        @update="emitUpdate"
      />
      <span
        v-if="fieldValues.length > 1 && fieldValues.length !== index + 1"
        class="mr-1"
        >{{ "," }}</span
      >
      <VTooltip
        v-if="fieldKey.description"
        activator="parent"
        openDelay="750"
        maxWidth="300"
        :text="fieldKey.description"
        location="bottom"
      />
      <div class="tooltips">
        <EntityHistoryTooltip
          v-if="!props.readonly"
          :entityId="fieldValue.id"
        ></EntityHistoryTooltip>
      </div>
    </div>
    <VBtn
      v-if="!props.readonly && !isInherited"
      variant="plain"
      size="small"
      color="red-lighten-1"
      icon="mdi-delete-outline"
      data-testid="delete-field-button"
      @click="emitDelete"
    />
    <VTooltip
      v-else-if="!props.readonly && isInherited"
      :text="t('processes.fieldCannotDeleteInherited')"
      top
    >
      <template #activator="{ props: activator }">
        <span v-bind="activator">
          <VBtn
            variant="plain"
            disabled
            size="small"
            color="grey"
            icon="mdi-delete-off-outline"
            data-testid="delete-field-button"
        /></span>
      </template>
    </VTooltip>
  </div>
</template>

<style scoped>
.field-value {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.tooltips {
  margin-bottom: auto;
  margin-top: 0.5rem;
}
</style>
