<script lang="ts" setup>
import dayjs from "dayjs";
import { computed } from "vue";

import COptionField from "@/app/process/components/field/COptionField.vue";
import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseCard from "@/base/components/card/BaseCard.vue";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import {
  type FieldKeyEto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  fieldKey: FieldKeyEto;
  fieldValue: FieldValueDto;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
}>();

const emit = defineEmits<{
  (e: "update", value: FieldValueDto): void;
  (e: "editing", value: boolean): void;
  (e: "delete"): void;
}>();

const dateFormatted = computed(() => {
  return formatDate(props.fieldValue?.value?.valueDate ?? undefined);
});

function formatDate(dateString?: string | null) {
  if (!dateString) {
    return "-";
  }
  return dayjs(dateString).format("YYYY-MM-DD");
}

// We need to wrap the options in a computed property to show the formatted date
const availableOptions = computed(() => {
  return props.fieldKey?.options?.map((option) => {
    return {
      id: option.id,
      title: formatDate(option.value?.valueDate),
    };
  });
});

const isOptionField = computed(() => (props.fieldKey.options?.length ?? 0) > 0);
</script>

<template>
  <PInplace
    :pt="{
      root: { class: 'h-full' },
      display: { class: 'w-full h-full' },
    }"
    @open="emit('editing', true)"
    @close="emit('editing', false)"
  >
    <template #display>
      <div class="flex flex-row items-start gap-2">
        <span
          class="mdi text-[2.3rem] opacity-90 mr-2 mt-2"
          :class="props.fieldKey.prefix ?? 'mdi-help'"
        ></span>

        <div class="flex flex-col">
          <p
            class="block mb-1 text-sm font-extrabold text-ellipsis overflow-hidden"
          >
            {{ props.label ?? "-" }}
          </p>

          <p
            class="block text-base text-ellipsis overflow-hidden"
            data-testid="field-value"
          >
            {{ dateFormatted }}
          </p>
        </div>
      </div>
    </template>

    <template #content="{ closeCallback }">
      <BaseCard :pt="{ root: { class: 'h-full' } }">
        <template #title>
          <div class="flex flex-row justify-between">
            <p
              class="mt-2 text-sm font-bold block text-nowrap text-ellipsis overflow-hidden whitespace-nowrap"
            >
              {{ props.label ?? "-" }}
            </p>
            <div class="flex flex-row">
              <BaseButton
                v-if="!props.readonly"
                icon="mdi mdi-delete-outline"
                data-testid="delete-field-button"
                text
                severity="danger"
                @click="emit('delete')"
              />

              <BaseButton
                icon="mdi mdi-close"
                data-testid="close-field-card-button"
                text
                severity="secondary"
                @click="closeCallback"
              />
            </div>
          </div>
        </template>

        <COptionField
          v-if="isOptionField"
          :fieldKey="props.fieldKey"
          :fieldValue="props.fieldValue"
          :label="props.label"
          :options="availableOptions"
          itemTitle="valueString"
          @update="
            (value) => emit('update', { ...props.fieldValue, optionId: value })
          "
        />

        <BaseTextField
          v-else
          v-focustrap
          data-testid="date-field-input"
          type="date"
          :initialValue="dateFormatted"
          :label="fieldKey?.name ?? label ?? '-'"
          @update="
            (value) => {
              emit('update', {
                ...props.fieldValue,
                value: {
                  valueDate: value ? formatDate(value) : null,
                },
              });
              closeCallback();
            }
          "
        />
      </BaseCard>
    </template>
  </PInplace>
</template>
