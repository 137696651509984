import {
  DeleteFieldValueDocument,
  FieldValueDocument,
  type FieldValueDto,
  type FieldValueEto,
  GetFieldValueDocument,
  GetFieldValuesDocument,
} from "@/base/graphql/generated/types.ts";
import {
  defineEntityStore,
  EntityProvider,
} from "@/base/stores/entity/EntityStore.ts";
import { PendingPersistedLookupDual } from "@/base/utils/IdRelationLookups.ts";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual(
    (eto: FieldValueEto) => ({ id: eto.id }) as FieldValueDto,
  ),
  {
    queryName: "fieldValue",
    listQuery: GetFieldValuesDocument,
    singleQuery: GetFieldValueDocument,
    mutation: FieldValueDocument,
    deleteMutation: DeleteFieldValueDocument,
  },
  {
    indexFields: ["fieldKeyId", "entityId"],
  },
);

export const useFieldValueStore = defineEntityStore(
  "fieldValue",
  entityProvider,
);
