import { type _GettersTree, defineStore, type Store } from "pinia";

import { type Eto } from "@/base/graphql/generated/types.ts";
import { ListDebouncer, withoutTypename } from "@/base/services/utils.ts";
import {
  ApolloCrudRepo,
  type QueryParams,
} from "@/base/stores/ApolloCrudRepo.ts";
import { type PendingPersistedLookupDual } from "@/base/utils/IdRelationLookups.ts";

export interface EntityStoreState<E extends Eto, D extends { id: string }> {
  cache: PendingPersistedLookupDual<E, D>;
  repo: ApolloCrudRepo<E, D>;
  queryParams: QueryParams;
  onAfterRegister?: (etos: E[]) => void;
  onBeforeUnregister?: (etos: E[]) => void;
  indexedFields: (keyof E)[];
  indices: Map<keyof E, Map<string, Map<string, E>>>;
}

export type EntityStoreGetters<
  E extends Eto,
  D extends { id: string },
> = _GettersTree<EntityStoreState<E, D>>;

export interface EntityStoreActions<E extends Eto, D extends { id: string }> {
  createOrUpdate(dto: D, options?): Promise<E>;

  deleteById(id: string): Promise<boolean>;

  getAll(queryVariables?: Record<string, unknown>): E[];

  getById(id: string): E | undefined;

  getFiltered(key: keyof E, value: string): E[];

  isLoading(id?: string, queryVariables?: Record<string, unknown>): boolean;

  registerAllLoaded(etos: E[], cacheKey?: string): void;

  unregisterAllLoaded(ids: string[]): void;

  loadAll(): void;

  loadById(id: string): void;

  addToIndices(etos: E[]): void;

  removeFromIndices(eto: E): void;

  markRefetch(id?: string, queryVariables?: Record<string, unknown>): void;

  getCacheKey(queryVariables?: Record<string, unknown>): string;
}

export type EntityStore<E extends Eto, D extends { id: string }> = Store<
  string,
  EntityStoreState<E, D>,
  EntityStoreGetters<E, D>,
  EntityStoreActions<E, D>
>;

export interface EntityProviderHooks<E extends Eto> {
  onAfterRegister?: (etos: E[]) => void;
  onBeforeUnregister?: (etos: E[]) => void;
  indexFields?: (keyof E)[];
}

export class EntityProvider<E extends Eto, D extends { id: string }> {
  cache: PendingPersistedLookupDual<E, D>;
  repo: ApolloCrudRepo<E, D>;
  queryParams: QueryParams;
  onAfterRegister?: (etos: E[]) => void;
  onBeforeUnregister?: (etos: E[]) => void;

  isLoadingAll = new Map<string, boolean>();
  isLoadingIds = new Map<string, boolean>();

  indexedFields: (keyof E)[];
  indices: Map<keyof E, Map<string, Map<string, E>>>;

  listDebouncer = new ListDebouncer<string>();

  constructor(
    cache: PendingPersistedLookupDual<E, D>,
    queryParams: QueryParams,
    hooks?: EntityProviderHooks<E>,
  ) {
    this.cache = cache;
    this.queryParams = queryParams;
    this.repo = new ApolloCrudRepo<E, D>(queryParams);
    this.onAfterRegister = hooks?.onAfterRegister;
    this.onBeforeUnregister = hooks?.onBeforeUnregister;
    this.indexedFields = hooks?.indexFields ?? [];
    this.indices = this.initializedIndex();
  }

  private initializedIndex() {
    const indices = new Map();
    this.indexedFields.forEach((field) => {
      indices.set(field, new Map());
    });
    return indices;
  }

  getAll(queryVariables?: Record<string, unknown>) {
    const cacheKey = this.getCacheKey(queryVariables);
    if (
      this.queryParams.listQuery &&
      this.isLoadingAll.get(cacheKey) === undefined
    ) {
      this.loadAll(queryVariables);
    }
    return this.cache.getAllPersisted(cacheKey);
  }

  getById(id: string) {
    if (
      this.queryParams.singleQuery &&
      this.isLoadingIds.get(id) === undefined
    ) {
      this.loadById(id);
    }
    return this.cache.getPersistedOptional(id);
  }

  getFiltered(key: keyof E, value: string) {
    const index = this.indices.get(key);
    if (!index) {
      console.warn(`${String(key)} is not indexed.`);
      return this.getAll().filter((eto) => String(eto[key]) === value);
    }
    return Array.from(index?.get(value)?.values() ?? new Set<E>());
  }

  loadAll(queryVariables?: Record<string, unknown>) {
    const cacheKey = this.getCacheKey(queryVariables);
    this.isLoadingAll.set(cacheKey, true);
    this.repo
      .findAll(queryVariables)
      .then((results) => {
        this.isLoadingAll.set(cacheKey, false);
        this.registerAllLoaded(results, cacheKey);
      })
      .catch((reason) => {
        console.error(reason);
        this.isLoadingAll.set(cacheKey, false);
      });
  }

  loadById(id: string) {
    this.isLoadingIds.set(id, true);
    this.listDebouncer.add(id);
    void this.listDebouncer.run((ids) => {
      this.repo
        .findByIds(ids)
        .then((result) => {
          result.forEach((eto) => this.isLoadingIds.set(eto.id, false));
          this.registerAllLoaded(result);
        })
        .catch((reason) => {
          console.error(reason);
          ids.forEach((item) => this.isLoadingIds.set(item, false));
        });
    });
  }

  async createOrUpdate(dto: D, options?): Promise<E> {
    return this.repo.createOrUpdate(dto, options).then((result: E) => {
      this.registerAllLoaded([result]);
      return result;
    });
  }

  async deleteById(id: string) {
    this.unregisterAllLoaded([id]);
    return this.repo.deleteById(id).catch((reason) => {
      console.error(reason);
      return Promise.reject(new Error(reason));
    });
  }

  registerAllLoaded(etos: E[], cacheKey?: string) {
    const items = etos.map(withoutTypename);
    this.cache.setPersisted(items, cacheKey);
    const individualEntries: Iterable<[string, boolean]> = items.map((eto) => [
      eto.id,
      false,
    ]);
    this.isLoadingIds = new Map([
      ...this.isLoadingIds.entries(),
      ...individualEntries,
    ]);
    this.addToIndices(items);
    if (this.onAfterRegister) {
      this.onAfterRegister(items);
    }
  }

  unregisterAllLoaded(ids: string[]) {
    ids.forEach((id) => {
      const eto = this.cache.getPersistedOptional(id);
      if (eto) {
        if (this.onBeforeUnregister) {
          this.onBeforeUnregister([eto]);
        }
        this.removeFromIndices(eto);
      }
      this.cache.remove(id);
      this.isLoadingIds.set(id, false);
    });
  }

  isLoading(id?: string, queryVariables?: Record<string, unknown>) {
    if (id) {
      return this.isLoadingIds.get(id) === true;
    }

    return this.isLoadingAll.get(this.getCacheKey(queryVariables)) === true;
  }

  markRefetch(id?: string, queryVariables?: Record<string, unknown>) {
    if (id) {
      this.isLoadingIds.delete(id);
    } else if (queryVariables) {
      this.isLoadingAll.delete(this.getCacheKey(queryVariables));
    } else {
      this.isLoadingAll.clear();
      this.isLoadingIds.clear();
    }
  }

  addToIndices(etos: E[]) {
    const updatedIndices = new Map(this.indices);
    this.indexedFields.forEach((field) => {
      const updatedFieldMap = new Map(this.indices.get(field));
      etos.forEach((eto) => {
        const stringValue = String(eto[field]);
        let instancesById = updatedFieldMap.get(stringValue);
        if (!instancesById) {
          instancesById = new Map();
          updatedFieldMap.set(stringValue, instancesById);
        }
        instancesById.set(eto.id, eto);
      });
      updatedIndices.set(field, updatedFieldMap);
    });
    this.indices = updatedIndices;
  }

  removeFromIndices(eto: E) {
    [...this.indices.keys()].forEach((field) => {
      const stringValue = String(eto[field]);
      const index = this.indices.get(field);
      const instancesById = index?.get(stringValue);
      instancesById?.delete(eto.id);
    });
  }

  getCacheKey(queryVariables?: Record<string, unknown>) {
    return JSON.stringify(queryVariables);
  }
}

export function defineEntityStore<E extends Eto, D extends { id: string }>(
  storeId: string,
  entityProvider: EntityProvider<E, D>,
) {
  return defineStore<
    string,
    EntityStoreState<E, D>,
    EntityStoreGetters<E, D>,
    EntityStoreActions<E, D>
  >(storeId, {
    state: () => ({ ...entityProvider }),
    getters: {},
    actions: {
      isLoading(
        id?: string,
        queryVariables?: Record<string, unknown>,
      ): boolean {
        return entityProvider.isLoading.bind(this)(id, queryVariables);
      },
      deleteById(id: string): Promise<boolean> {
        return entityProvider.deleteById.bind(this)(id);
      },
      createOrUpdate(dto: D, options?): Promise<E> {
        return entityProvider.createOrUpdate.bind(this)(dto, options);
      },
      getAll(queryVariables?: Record<string, unknown>): E[] {
        return entityProvider.getAll.bind(this)(queryVariables);
      },
      getById(id: string): E | undefined {
        return entityProvider.getById.bind(this)(id);
      },
      getFiltered(key: keyof E, value: string): E[] {
        return entityProvider.getFiltered.bind(this)(key, value);
      },
      registerAllLoaded(etos: E[], cacheKey?: string): void {
        entityProvider.registerAllLoaded.bind(this)(etos, cacheKey);
      },
      unregisterAllLoaded(ids: string[]): void {
        entityProvider.unregisterAllLoaded.bind(this)(ids);
      },
      loadAll(queryVariables?: Record<string, unknown>): void {
        entityProvider.loadAll.bind(this)(queryVariables);
      },
      loadById(id: string) {
        entityProvider.loadById.bind(this)(id);
      },
      addToIndices(etos: E[]) {
        entityProvider.addToIndices.bind(this)(etos);
      },
      removeFromIndices(eto: E) {
        entityProvider.removeFromIndices.bind(this)(eto);
      },
      markRefetch(id?: string, queryVariables?: Record<string, unknown>) {
        entityProvider.markRefetch.bind(this)(id, queryVariables);
      },
      getCacheKey(queryVariables?: Record<string, unknown>) {
        return entityProvider.getCacheKey.bind(this)(queryVariables);
      },
    },
  });
}
