import { processTaskToDto } from "@/app/process/services/mapper/ProcessMapper.ts";
import {
  type ProcessTaskDto,
  type ProcessTaskEto,
} from "@/base/graphql/generated/types.ts";
import {
  DeleteProcessTaskDocument,
  ProcessTaskDocument,
} from "@/base/graphql/generated/types.ts";
import {
  defineEntityStore,
  EntityProvider,
} from "@/base/stores/entity/EntityStore.ts";
import { PendingPersistedLookupDual } from "@/base/utils/IdRelationLookups.ts";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual<ProcessTaskEto, ProcessTaskDto>(
    processTaskToDto,
  ),
  {
    queryName: "processTask",
    mutation: ProcessTaskDocument,
    deleteMutation: DeleteProcessTaskDocument,
  },
  {
    indexFields: ["processActivityId", "activityTaskId"],
  },
);

export const useProcessTaskStore = defineEntityStore(
  "processTask",
  entityProvider,
);
