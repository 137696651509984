<script setup lang="ts">
import { loadDevMessages } from "@apollo/client/dev";
import { BrowserAuthError, BrowserAuthErrorCodes } from "@azure/msal-browser";
import { usePrimeVue } from "primevue";
import { onErrorCaptured, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { RouterView, useRouter } from "vue-router";

import DialogScope from "@/base/components/dialog/DialogScope.vue";
import EnvironmentIndicator from "@/base/components/header/EnvironmentIndicator.vue";
import PromptList from "@/base/components/notification/PromptList.vue";
import BasePage from "@/base/components/page/BasePage.vue";
import { ApiError } from "@/base/services/backend/core/ApiError";

import ErrorDialog from "./base/components/notification/ErrorDialog.vue";

const { t } = useI18n();
const router = useRouter();
const primevue = usePrimeVue();
const error = ref<Error>();

onErrorCaptured((capturedError, _instance, info) => {
  console.error("Error occurred from", info, "\n", capturedError);

  //if error is an ApiError, go to ErrorPage
  if (capturedError instanceof ApiError) {
    const href = router.resolve({
      path: "/error",
      query: { error: capturedError.status, message: capturedError.message },
    });
    void router.push(href);
  } else if (
    capturedError instanceof BrowserAuthError &&
    capturedError.errorCode === BrowserAuthErrorCodes.interactionInProgress
  ) {
    // TODO: For now we ignore this error in the dialog but we should reimplement the login flow
    router.go(0);
    return;
  } else {
    //else show error dialog
    error.value = capturedError;
  }
});

const { locale } = useI18n();
if (!import.meta.env.PROD) {
  loadDevMessages();
}

watch(
  () => primevue.config.locale,
  () => {
    if (!primevue.config.locale) {
      return;
    }
    primevue.config.locale.addRule = t("table.filter.addRule");
    primevue.config.locale.removeRule = t("table.filter.removeRule");
    primevue.config.locale.clear = t("table.filter.clear");
    primevue.config.locale.apply = t("table.filter.apply");
    primevue.config.locale.contains = t("table.filter.contains");
    primevue.config.locale.notContains = t("table.filter.notContains");
    primevue.config.locale.startsWith = t("table.filter.startsWith");
    primevue.config.locale.endsWith = t("table.filter.endsWith");
    primevue.config.locale.matchAll = t("table.filter.matchAll");
    primevue.config.locale.matchAny = t("table.filter.matchAny");
    primevue.config.locale.equals = t("table.filter.equals");
    primevue.config.locale.notEquals = t("table.filter.notEquals");
    primevue.config.locale.emptyMessage = t("table.emptySelection");
  },
  { immediate: true },
);
</script>

<template>
  <VApp :lang="locale">
    <!--  PrimeVue Dialog Handlers  -->
    <PDynamicDialog />
    <PConfirmDialog
      :style="{ width: '50vw' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    />
    <!-------------------------------->

    <EnvironmentIndicator />

    <DialogScope>
      <BasePage>
        <RouterView />
      </BasePage>
    </DialogScope>
    <PromptList />
    <ErrorDialog v-if="error" :error="error" @dismiss="error = undefined" />
  </VApp>
</template>

<style>
.w-form {
  max-width: 1200px;
}

.border-card {
  border: 1px solid rgb(var(--v-theme-caeli8));
  border-bottom: none;
}

.pointer {
  cursor: pointer;
}

.ml-n20 {
  margin-left: -5rem;
}

.r-8 {
  right: 2rem;
}

.t-24 {
  top: 6rem;
}

.z-1 {
  z-index: 1;
}

.z-10k {
  z-index: 10000;
}

#app .v-text-field.bg-white input {
  background-color: white;
}

#app .tight .v-input {
  --v-input-control-height: 36px;
}

#app .tight .v-field__input {
  padding-top: 6px;
  padding-bottom: 6px;
}

.field-border {
  border: 1px solid #bcd;
}

.field-border:hover {
  border: 1px solid #789;
}
</style>
