import {
  GetMessageDocument,
  GetMessageListDocument,
  MessageDeleteDocument,
  MessageDocument,
  type MessageDto,
  type MessageEto,
} from "@/base/graphql/generated/types.ts";
import {
  defineEntityStore,
  EntityProvider,
} from "@/base/stores/entity/EntityStore.ts";
import { useFieldValueStore } from "@/base/stores/field/FieldValueStore.ts";
import { PendingPersistedLookupDual } from "@/base/utils/IdRelationLookups.ts";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual((eto: MessageEto) => {
    return {
      id: eto.id,
      title: eto.title,
      fields: eto.fields,
      body: eto.body,
    } as MessageDto;
  }),
  {
    queryName: "message",
    listQuery: GetMessageListDocument,
    singleQuery: GetMessageDocument,
    mutation: MessageDocument,
    deleteMutation: MessageDeleteDocument,
  },
  {
    onAfterRegister(etos) {
      useFieldValueStore().registerAllLoaded(
        etos.flatMap((message) => message.fields ?? []),
      );
    },
  },
);

export const useMessageStore = defineEntityStore("message", entityProvider);
