<script setup lang="ts">
import { useStorage } from "@vueuse/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import DocumentGenerationRunTable from "@/app/document/components/generation/DocumentGenerationRunTable.vue";
import DeleteProcessTemplateDialog from "@/app/process/components/action/DeleteProcessTemplateDialog.vue";
import OutputTable from "@/app/process/components/output/OutputTable.vue";
import ProcessActivityTable from "@/app/process/components/tables/ProcessActivityTable.vue";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import EntityFieldsCard from "@/base/components/fields/EntityFieldsCard.vue";
import {
  type ActivityOutputDto,
  EntityType,
  type ProcessDto,
  type ProcessOutputEto,
} from "@/base/graphql/generated/types.ts";
import { isDefined } from "@/base/services/utils.ts";
import { deduplicate } from "@/base/utils/array.ts";
import { StorageKeys } from "@/config.ts";

export interface ProcessSingleViewContentProps {
  process: ProcessDto;
}

const props = defineProps<ProcessSingleViewContentProps>();

const { t } = useI18n();
const processService = useProcessService();
const activityService = useActivityService();

const activeTab = useStorage<string>(
  StorageKeys.process.activeTab.key,
  "features",
  StorageKeys.process.activeTab.storage,
);

const activityOutputs = computed((): ActivityOutputDto[] =>
  processService
    .getProcessActivities(props.process.id)
    .flatMap((processActivity) =>
      activityService.getOutputs(processActivity.activity.id),
    ),
);

const processOutputs = computed((): ProcessOutputEto[] =>
  processService
    .getProcessActivities(props.process.id)
    .flatMap((processActivity) =>
      processService.getOutputs(processActivity.id),
    ),
);

const importantActivityOutputsFromProcess = computed(() => {
  return processOutputs.value
    .filter((output) => output.important)
    .map((output) => output.activityOutputId)
    .map((id) => activityOutputs.value.find((output) => output.id === id))
    .filter(isDefined);
});

const nonImportantProcessActivityOutputIds = computed(() => {
  return processOutputs.value
    .filter((output) => !output.important)
    .map((output) => output.activityOutputId);
});

const importantActivityOutputs = computed(() => {
  return deduplicate([
    ...activityOutputs.value.filter(
      (output) =>
        output.important &&
        !nonImportantProcessActivityOutputIds.value.includes(output.id),
    ),
    ...importantActivityOutputsFromProcess.value,
  ]);
});
</script>

<template>
  <PTabs v-model:value="activeTab" lazy>
    <PTabList>
      <PTab value="features">{{ t("processes.features") }}</PTab>
      <PTab value="activities">{{ t("processes.activity", 2) }}</PTab>
      <PTab value="outputs">{{ t("processes.output", 2) }}</PTab>
      <PTab value="customActivities" data-testid="custom-activities-tab"
        >{{ t("processes.customActivity", 2) }}
      </PTab>
      <PTab value="documents">{{ t("processes.documents") }}</PTab>
    </PTabList>

    <PTabPanels :pt="{ root: { class: 'p-0! mt-4' } }">
      <PTabPanel class="flex flex-col gap-8" value="features">
        <EntityFieldsCard
          cards
          grouped
          :entityId="process.id"
          :ancestorIds="
            process.startActivityId ? [process.startActivityId] : []
          "
          :entityType="[EntityType.Process, EntityType.Activity]"
        />

        <div>
          <p class="text-xl mb-4">
            <i class="mdi mdi-star" />
            {{ t("processes.outputFavorite", 2) }}
          </p>

          <OutputTable
            :contextKey="`importantoutputs-${process.id}`"
            :outputs="importantActivityOutputs"
            :processId="process.id"
            :processOutputs="processOutputs"
            showType
          />
        </div>
      </PTabPanel>

      <PTabPanel value="activities">
        <ProcessActivityTable
          showDetails
          contextKey="activities"
          :processId="process.id"
        />
      </PTabPanel>

      <PTabPanel value="outputs">
        <OutputTable
          :contextKey="`outputs-${process.id}`"
          :outputs="activityOutputs"
          :processId="process.id"
          :processOutputs="processOutputs"
          showType
        />
      </PTabPanel>

      <PTabPanel value="customActivities">
        <DeleteProcessTemplateDialog />
        <ProcessActivityTable
          showCustomActivities
          showDetails
          contextKey="customActivities"
          :processId="process.id"
        />
      </PTabPanel>

      <PTabPanel value="documents">
        <DocumentGenerationRunTable
          :entityId="process.id"
        ></DocumentGenerationRunTable>
      </PTabPanel>
    </PTabPanels>
  </PTabs>
</template>
