import {
  type DocumentDto,
  type DocumentEto,
} from "@/base/graphql/generated/types.ts";
import {
  DocumentDeleteDocument,
  DocumentDocument,
  GetDocumentDocument,
  GetDocumentListDocument,
} from "@/base/graphql/generated/types.ts";
import {
  defineEntityStore,
  EntityProvider,
} from "@/base/stores/entity/EntityStore.ts";
import { PendingPersistedLookupDual } from "@/base/utils/IdRelationLookups.ts";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual(
    (eto: DocumentEto) => ({ id: eto.id }) as DocumentDto,
  ),
  {
    queryName: "document",
    listQuery: GetDocumentListDocument,
    singleQuery: GetDocumentDocument,
    mutation: DocumentDocument,
    deleteMutation: DocumentDeleteDocument,
  },
);

export const useDocumentStore = defineEntityStore("document", entityProvider);
