<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import BaseButton from "@/base/components/button/BaseButton.vue";

const props = defineProps<{ rootActivityMid: string }>();

const { t } = useI18n();

const activityService = useActivityService();

const current = computed(() =>
  activityService.getCurrentVersion(props.rootActivityMid),
);

const lastReleased = computed(() =>
  activityService.getLastReleased(props.rootActivityMid),
);
</script>

<template>
  <RouterLink
    class="flex flex-row pointer ga-6 align-center no-underline"
    :class="{ 'font-italic': !lastReleased }"
    :to="{
      name: 'processTemplate',
      params: { rootActivityId: lastReleased?.id ?? current?.id },
    }"
  >
    <p>
      {{ current?.name ?? "" }}
    </p>

    <BaseButton
      v-if="lastReleased?.id !== current?.id"
      outlined
      class="text-caption font-italic h-6"
      icon="mdi mdi-pencil"
      severity="info"
      data-testid="edit-draft-button"
      :label="t('processes.draft')"
      :to="{
        name: 'processTemplate',
        params: { rootActivityId: current?.id },
      }"
    />
  </RouterLink>
</template>
