import { defineStore, storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useMessageStoreV2 } from "@/app/message/stores/MessageStoreV2.ts";
import { createFieldCells } from "@/base/components/filterdatatable/FilteredDataTableUtil.ts";
import { type DataTableColumn } from "@/base/components/filterdatatable/table/CFilteredDataTableUtils.ts";
import {
  type CellContent,
  type RowItem,
} from "@/base/components/filterdatatable/TableTypes.ts";
import {
  EntityType,
  FieldType,
  type MessageDto,
  type MessageEto,
} from "@/base/graphql/generated/types.ts";
import { useFieldService } from "@/base/services/FieldService.ts";
import {
  Action,
  usePromptService,
} from "@/base/services/notification/PromptService.ts";

export const useMessageServiceV2 = defineStore("MessageServiceV2", () => {
  const messageStore = useMessageStoreV2();
  const promptService = usePromptService();
  const fieldService = useFieldService();
  const router = useRouter();
  const { t } = useI18n();
  const {
    isLoading,
    entities: messages,
    selectedEntity: selectedMessage,
  } = storeToRefs(messageStore);

  const translations = {
    message: {
      title: t("message.title"),
      creationDate: t("message.creationDate"),
    },
  };

  const mandatoryColumns: DataTableColumn[] = [
    {
      name: translations.message.creationDate,
      key: "entity_event_timestamp",
      type: FieldType.Date,
    },
    {
      name: translations.message.title,
      key: "title",
      type: FieldType.String,
    },
  ];

  const availableTags = computed(() =>
    fieldService.getTagFieldKeys(EntityType.Message),
  );

  const listRowItems = computed(() => messages.value.map(createRowItem));

  const displayColumns = computed<DataTableColumn[]>(() => {
    return [...mandatoryColumns].concat(
      fieldService
        .getNonTagFieldKeys(EntityType.Message)
        .sort((a, b) => a.name.localeCompare(b.name))
        .reverse()
        .map((fieldKey) => {
          return {
            name: fieldKey.name,
            key: fieldKey.key,
            type: fieldKey.type,
          };
        }),
    );
  });

  async function createOrUpdate(dto: MessageDto) {
    await messageStore.createOrUpdateMutation.mutateAsync(dto).then(
      async () => {
        await router.push({
          name: "messageEdit",
          params: { messageId: dto.id },
        });

        promptService.success(dto.id, Action.SAVE, dto.title ?? "");
      },
      (reason) => promptService.failure(dto.id, Action.SAVE, reason),
    );
  }

  async function deleteMessage(id: string) {
    await messageStore.deleteMutation.mutateAsync(id).then(
      async () => {
        await router.push({
          name: "messageList",
        });
        promptService.success(id, Action.DELETE);
      },
      (reason) => promptService.failure(id, Action.DELETE, reason),
    );
  }

  function createRowItem(message: MessageEto): RowItem {
    const cells = {
      ...createTitleCell(message.title),
      ...createCreationDateCell(message.created),
      ...createFieldCells(message.id),
    };

    const tags = fieldService
      .getTagFieldValues(message.id)
      .flatMap(
        (fieldValue) =>
          fieldService.getFieldKey(fieldValue.fieldKeyId)?.name ?? [],
      );

    return {
      key: message.id,
      cells,
      tags,
      to: {
        name: "messageView",
        params: { messageId: message.id },
      },
    };
  }

  function createTitleCell(title: string): Record<string, CellContent> {
    return {
      ["title"]: {
        content: title,
        props: { class: "pointer" },
      },
    };
  }

  function createCreationDateCell(
    created: string,
  ): Record<string, CellContent> {
    return {
      ["entity_event_timestamp"]: {
        content: created,
        type: FieldType.Date,
        props: { class: "pointer" },
      },
    };
  }

  return {
    isLoading,
    mandatoryColumns,
    availableTags,
    displayColumns,
    listRowItems,
    selectedMessage,
    createOrUpdate,
    deleteMessage,
    getById: (id: string) => messageStore.getById(id),
  };
});
