<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import ExtendActivityButton from "@/app/process/components/action/ExtendActivityButton.vue";
import { useDialogService } from "@/base/services/DialogService.ts";

const props = defineProps<{
  activityId: string;
  isRoot?: boolean;
}>();

const { t } = useI18n();

const activityService = useActivityService();
const dialogService = useDialogService();

const activity = computed(() => activityService.getActivity(props.activityId));
const outputs = computed(() => activityService.getOutputs(props.activityId));
const isActivityReleased = computed(() =>
  activityService.isReleased(props.activityId),
);
</script>

<template>
  <div v-if="!isActivityReleased" class="d-flex align-center ga-2 py-1">
    <VTooltip location="start" :text="t('processes.listView.addActivity')">
      <template #activator="{ props: activator }">
        <ExtendActivityButton
          v-if="outputs.length > 0"
          v-bind="activator"
          :activityId="props.activityId"
        />
      </template>
    </VTooltip>
    <VTooltip location="start" :text="t('action.delete')">
      <template #activator="{ props: activator }">
        <VBtn
          v-bind="activator"
          :title="t('action.delete')"
          variant="plain"
          color="error"
          size="tiny"
          icon="mdi-delete"
          @click.stop.prevent="
            () =>
              activity
                ? dialogService.openActivityDeleteDialog([activity])
                : undefined
          "
        />
      </template>
    </VTooltip>
  </div>
</template>
