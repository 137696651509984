<script setup lang="ts">
import { provide, reactive } from "vue";

import AppDialog from "./AppDialog.vue";
import { type Dialog } from "./Dialog.ts";
import { DialogProvideKey, type DialogProvider } from "./DialogProvider.ts";

interface DialogEntry extends Dialog {
  id: number;
  callback: (response: boolean) => void;
}

const dialogs = reactive<DialogEntry[]>([]);

let nextDialogIndex = 0;

const provided: DialogProvider = {
  confirm(dialog) {
    return new Promise((resolve) => {
      const dialogEntry: DialogEntry = {
        ...dialog,
        id: ++nextDialogIndex,
        callback(result) {
          dialogs.splice(dialogs.indexOf(dialogEntry), 1);
          resolve(result);
        },
      };
      dialogs.push(dialogEntry);
    });
  },
};

provide(DialogProvideKey, provided);

defineExpose({ provided });
</script>

<template>
  <slot />
  <AppDialog
    v-for="dialog of dialogs"
    :key="dialog.id"
    :title="dialog.title"
    :destructive="dialog.destructive"
    :cancelMessage="dialog.cancelMessage"
    :confirmMessage="dialog.confirmMessage"
    @cancel="dialog.callback(false)"
    @confirm="dialog.callback(true)"
  >
    {{ dialog.message }}
  </AppDialog>
</template>
