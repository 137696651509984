import { type ApolloError } from "@apollo/client/core";
import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import { useDocumentGenerationRunService } from "@/app/document/services/DocumentGenerationRunService.ts";
import { type TreeNode } from "@/app/document/services/TreeNodeTypes.ts";
import { useDocumentParameterStore } from "@/app/document/stores/DocumentParameterStore.ts";
import { useDocumentStore } from "@/app/document/stores/DocumentStore.ts";
import { useMessageService } from "@/app/message/services/MessageService.ts";
import { usePersonService } from "@/app/person/services/PersonService.ts";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import {
  type DocumentDto,
  type DocumentEto,
  type DocumentGenerationOptionsDto,
  type DocumentGenerationResult,
  type DocumentGenerationRunEto,
  type DocumentParameterDto,
  EntityType,
  type MessageEto,
  type PersonEto,
  ValidationLevel,
  type ValidationResult,
} from "@/base/graphql/generated/types.ts";
import { translateEnum } from "@/base/i18n/i18n.ts";
import { useDialogService } from "@/base/services/DialogService.ts";
import {
  Action,
  usePromptService,
  ValidationCode,
} from "@/base/services/notification/PromptService.ts";

export enum GenerationStatusEnum {
  SUCCESS = "success",
  WARN = "warn",
  ERROR = "error",
}

type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export const useDocumentService = defineStore("DocumentService", () => {
  const documentStore = useDocumentStore();
  const documentParameterStore = useDocumentParameterStore();
  const documentGenerationRunService = useDocumentGenerationRunService();
  const activityService = useActivityService();
  const personService = usePersonService();
  const messageService = useMessageService();
  const promptService = usePromptService();
  const dialogService = useDialogService();
  const router = useRouter();
  const { t } = useI18n();

  const processService = useProcessService();

  const unknownErrorMessage = t("common.unknownErrorMessage");

  const translations = {
    name: t("document.name"),
    description: t("document.description"),
    entityType: t("document.entityType"),
    generate: t("document.generate"),
  };

  function deleteAndGoToList(id: string) {
    documentStore.deleteById(id).then(
      () => {
        promptService.success(id, Action.DELETE);
        void router.push({
          name: "documentList",
        });
      },
      (reason) => promptService.failure(id, Action.DELETE, reason),
    );
  }

  function deleteParameter(id: string | undefined) {
    if (!id) {
      promptService.failure(
        "document-not-found",
        Action.DELETE,
        "No document id",
      );
      return;
    }
    documentParameterStore.deleteById(id).then(
      () => {
        promptService.success(id, Action.DELETE);
      },
      (reason) => promptService.failure(id, Action.DELETE, reason),
    );
  }

  function getEntityItemsForDropdown(entityType: EntityType) {
    let items: { id: string; name: string }[];

    switch (entityType) {
      case EntityType.Activity:
        items = activityService
          .getAllActivitiesWithoutArchived()
          .map((item) => ({
            id: item.id,
            name: item.name,
          }));
        break;
      case EntityType.Person:
        items = personService.getAll().map((item: PersonEto) => ({
          id: item.id,
          name: item.name ?? "",
        }));
        break;
      case EntityType.Message:
        items = messageService.getAll().map((item: MessageEto) => ({
          id: item.id,
          name: item.title,
        }));
        break;
      case EntityType.Process:
      default:
        items = processService.getProcesses();
        break;
    }

    return items.map((item) => ({
      text: item.name, // Use the name for display
      value: item.id, // Use the id as the value
    }));
  }

  const mapDocumentEtoToTreeNode = (document: DocumentEto): TreeNode => {
    const treeNode: TreeNode = {
      key: document.id,
      entityType: document.entityType,
      data: {
        name: document.name,
        entityType: translateEnum("entityTypes", document.entityType),
      },
      children: [],
    };
    if (document.parentId) {
      treeNode.parentId = document.parentId;
    }
    return treeNode;
  };

  const getTreeNodes = (documents?: DocumentEto[]): TreeNode[] => {
    const allDocuments = documents ?? documentStore.getAll();

    const rootNodes = allDocuments
      .filter((rootNode) => !rootNode.parentId)
      .map(mapDocumentEtoToTreeNode);
    const childNodes = allDocuments
      .filter((rootNode) => rootNode.parentId)
      .map(mapDocumentEtoToTreeNode);

    const findNode = (nodes: TreeNode[], id?: string) =>
      nodes.find((node) => node.key === id);

    childNodes.forEach((childNode) => {
      findNode(rootNodes, childNode.parentId)?.children?.push(childNode) ??
        findNode(childNodes, childNode.parentId)?.children?.push(childNode) ??
        rootNodes.push(childNode);
    });

    return rootNodes;
  };

  const entityTypeLabel = computed(() => {
    if (!dialogService.dialogEntityType) {
      return "";
    }
    switch (dialogService.dialogEntityType) {
      case EntityType.Activity:
        return t("entityTypes.ACTIVITY");
      case EntityType.Person:
        return t("entityTypes.PERSON");
      case EntityType.Message:
        return t("entityTypes.MESSAGE");
      case EntityType.Process:
      default:
        return t("entityTypes.PROCESS");
    }
  });

  function markParameterRefetch(documentId: string) {
    documentParameterStore.markRefetch(undefined, {
      documentIds: [documentId],
    });
  }

  const displayColumns = computed((): string[] => {
    return [translations.name, translations.entityType];
  });

  function createOrUpdate(documentDto: DocumentDto) {
    documentStore.createOrUpdate(documentDto).then(
      () =>
        promptService.success(
          documentDto.id,
          Action.SAVE,
          documentDto.name ?? documentDto.id,
        ),
      (reason) => promptService.failure(documentDto.id, Action.SAVE, reason),
    );
    return documentDto.id;
  }

  function createOrUpdateParameter(
    documentParameterDto: AtLeast<DocumentParameterDto, "id">,
  ) {
    documentParameterStore
      .createOrUpdate(documentParameterDto)
      .then((value) => {
        promptService.success(
          documentParameterDto.id,
          Action.SAVE,
          documentParameterDto.name ?? documentParameterDto.id,
        );
        markParameterRefetch(value.documentId);
      })
      .catch((reason) =>
        promptService.failure(documentParameterDto.id, Action.SAVE, reason),
      );
    return documentParameterDto.id;
  }

  function getParametersForDocument(documentId: string) {
    return documentParameterStore.getAll({
      documentIds: [documentId],
    });
  }

  async function generateDocument(
    entityId?: string,
    documentGenerationOptions: DocumentGenerationOptionsDto = {},
  ): Promise<DocumentGenerationRunEto> {
    if (!dialogService.dialogEntityId) {
      return Promise.reject(new Error("No document selected"));
    }

    const document = documentStore.getById(dialogService.dialogEntityId);

    if (!document) {
      return Promise.reject(new Error("Document not found"));
    }

    return documentGenerationRunService
      .createOrUpdate({
        id: uuidv4(),
        documentId: document.id,
        entityId,
        documentGenerationOptions,
        templateFileId: document.templateFileId,
      })
      .then((value) => value)
      .catch((error) => {
        promptService.failure(document.id, Action.GENERATE, error.message);
        return Promise.reject(new Error(error));
      });
  }

  const documentVariantCreationResult = ref<{ document?: DocumentEto }>({});
  const generateDocumentVariant = async (
    documentId: string,
    variantName?: string,
  ): Promise<DocumentEto> => {
    const document = documentId && documentStore.getById(documentId);

    if (!document) {
      return Promise.reject(new Error("Document not found"));
    }
    const documentCopy: DocumentDto = {
      id: uuidv4(),
      name: variantName,
      parentId: document.id,
      entityType: document.entityType,
      templateFileId: document.templateFileId,
      iterator: document.iterator,
    };

    try {
      const documentCreated = await documentStore.createOrUpdate(documentCopy, {
        optionsDto: {
          createFileCopyOfParentDocumentAndAttach: true,
        },
      });
      promptService.success(
        document.id,
        Action.GENERATE_VARIANT,
        documentCreated.name ?? documentCreated.id,
      );
      documentVariantCreationResult.value = { document: documentCreated };
      return documentCreated;
    } catch (error) {
      let errorMessage: string;
      if ((error as ApolloError).graphQLErrors) {
        errorMessage = (error as ApolloError).graphQLErrors?.[0]?.message;
      }
      errorMessage ??= unknownErrorMessage;
      promptService.failure(document.id, Action.GENERATE_VARIANT, errorMessage);
      return Promise.reject(new Error(errorMessage));
    }
  };

  const validationLabelMapping = {
    [ValidationCode.NOT_FOUND]: t("document.validation.placeholderNotFound"),
    [ValidationCode.INVALID_FORMAT]: t("document.validation.invalidFormat"),
  };

  function formatValidationMessages(validation: ValidationResult[]): string {
    return validation
      .map(
        (val) =>
          `${validationLabelMapping[val.code]} ${val.params?.join(", ")}`,
      )
      .join("\n");
  }

  function isValidationResult(
    value: ValidationResult | null | undefined,
  ): value is ValidationResult {
    return value !== null && value !== undefined;
  }

  function determineStatus(
    validationResults: ValidationResult[],
  ): GenerationStatusEnum {
    if (validationResults.some((val) => val.level === ValidationLevel.Error)) {
      return GenerationStatusEnum.ERROR;
    }
    if (validationResults.some((val) => val.level === ValidationLevel.Warn)) {
      return GenerationStatusEnum.WARN;
    }
    return GenerationStatusEnum.SUCCESS;
  }

  const documentGenerationRun = ref<DocumentGenerationRunEto | null>(null);
  const isGenerating = ref(false);
  const generateStatus = ref<GenerationStatusEnum | null>(null);
  const validationMessages = ref("");

  function reduceValidationMessages(value: DocumentGenerationResult[]) {
    const findFunction = (validationEntry, acc) =>
      acc.some(
        (r) =>
          r.code === validationEntry.code &&
          r.params.join(",") === validationEntry.params.join(","),
      );
    return value.reduce((acc, v) => {
      v.validation?.forEach((validationEntry) => {
        const found = findFunction(validationEntry, acc);
        if (!found && validationEntry) {
          acc.push(validationEntry);
        }
      });
      return acc;
    }, [] as ValidationResult[]);
  }

  async function startGeneration(
    entityId?: string,
    documentGenerationOptions: DocumentGenerationOptionsDto = {},
  ) {
    isGenerating.value = true;
    generateStatus.value = null;
    validationMessages.value = "";

    try {
      documentGenerationRun.value = await generateDocument(
        entityId,
        documentGenerationOptions,
      );

      const validationResults = reduceValidationMessages(
        documentGenerationRun.value.results ?? [],
      ).filter(isValidationResult);
      validationMessages.value = formatValidationMessages(validationResults);

      generateStatus.value = determineStatus(validationResults);
    } catch (error) {
      generateStatus.value = GenerationStatusEnum.ERROR;
      if (error instanceof Error) {
        validationMessages.value = error.message;
      } else {
        validationMessages.value = unknownErrorMessage;
      }

      console.error("Error generating document:", error);
    } finally {
      isGenerating.value = false;
    }
  }

  const startVariantGeneration = async (
    documentId: string,
    variantName: string,
  ) => {
    if (!variantName || !documentId) {
      return;
    }

    isGenerating.value = true;
    generateStatus.value = null;
    validationMessages.value = "";

    try {
      await generateDocumentVariant(documentId, variantName);
    } catch (error) {
      generateStatus.value = GenerationStatusEnum.ERROR;
      if (error instanceof Error) {
        validationMessages.value = error.message;
      } else {
        validationMessages.value = unknownErrorMessage;
      }

      console.error("Error generating document:", error);
    } finally {
      isGenerating.value = false;
    }
  };

  async function downloadGeneratedDocument() {
    if (!documentGenerationRun.value) {
      return;
    }
    await documentGenerationRunService.downloadDocuments(
      documentGenerationRun.value.id,
    );
  }

  const entityItems = computed(() => {
    if (dialogService.dialogEntityType) {
      return getEntityItemsForDropdown(dialogService.dialogEntityType);
    }
    return [];
  });

  function resetGeneration() {
    documentGenerationRun.value = null;
    isGenerating.value = false;
    generateStatus.value = null;
    validationMessages.value = "";
  }

  const resetVariantGeneration = () => {
    isGenerating.value = false;
    generateStatus.value = null;
    validationMessages.value = "";
  };

  return {
    documentGenerationRun,
    documentVariantCreationResult,
    isGenerating,
    generateStatus,
    validationMessages,
    entityItems,
    entityTypeLabel,
    resetGeneration,
    resetVariantGeneration,
    startGeneration,
    startVariantGeneration,
    downloadGeneratedDocument,
    isLoading: (id?: string) => documentStore.isLoading(id),
    getById: (id: string) => documentStore.getById(id),
    getAll: () => documentStore.getAll(),
    getParametersForDocument,
    createOrUpdate,
    createOrUpdateParameter,
    deleteAndGoToList,
    deleteParameter,
    getTreeNodes,
    displayColumns,
    markRefetch: (id: string) => documentStore.markRefetch(id),
  };
});
