<script lang="ts" setup>
import { keepPreviousData, useQuery } from "@tanstack/vue-query";
import { computed, shallowRef, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

import ProcessActivityActions from "@/app/process/components/action/activity/ProcessActivityActions.vue";
import DataTableStatusColumn from "@/app/process/components/output/DataTableStatusColumn.vue";
import { createFieldCellsByValues } from "@/base/components/filterdatatable/FilteredDataTableUtil.ts";
import CFilteredDataTable from "@/base/components/filterdatatable/table/CFilteredDataTable.vue";
import { type DataTableColumn } from "@/base/components/filterdatatable/table/CFilteredDataTableUtils.ts";
import {
  type CellContent,
  type RowItem,
} from "@/base/components/filterdatatable/TableTypes.ts";
import {
  useApplyPaginationInfo,
  useGetListFilter,
} from "@/base/components/filterdatatable/useDataTableFilter.ts";
import {
  EntityType,
  FieldType,
  GetProcessActivityListDocument,
  type GetProcessActivityListQuery,
  GetProcessGranularDocument,
  type GetProcessGranularQuery,
  type ProcessActivityEto,
  ProcessActivityState,
} from "@/base/graphql/generated/types.ts";
import { translateEnum } from "@/base/i18n/i18n.ts";
import { rawRequest } from "@/base/plugins/apollo/graphQlClient.ts";
import { useFieldService } from "@/base/services/FieldService.ts";
import { deduplicate } from "@/base/utils/array.ts";

const props = withDefaults(
  defineProps<{
    contextKey?: string;
    processId?: string;
    showProcess?: boolean;
    showCustomActivities?: boolean;
    showDetails?: boolean;
  }>(),
  {
    contextKey: undefined,
    processId: undefined,
    showCustomActivities: undefined,
  },
);

const { t } = useI18n();

const fieldService = useFieldService();

const i18n = {
  activityTitle: t("processes.activity"),
  processTitle: t("processes.process"),
  progressTitle: t("processes.progress"),
  templateTitle: t("processes.processTemplate"),
  startActivity: t("processes.startActivity"),
};

const cachedLabels: Record<string, string> = {
  [ProcessActivityState.NotReady]: t("processes.activityState.NOT_READY"),
  [ProcessActivityState.Ready]: t("processes.activityState.READY"),
  [ProcessActivityState.InProgress]: t("processes.activityState.IN_PROGRESS"),
  [ProcessActivityState.PartiallyReady]: t(
    "processes.activityState.PARTIALLY_READY",
  ),
  [ProcessActivityState.Done]: t("processes.activityState.DONE"),
};

const nameColumn: DataTableColumn = {
  name: i18n.activityTitle,
  type: FieldType.String,
  key: "activity_name",
};

const progressColumn: DataTableColumn = {
  name: i18n.progressTitle,
  type: FieldType.String,
  key: "state",
  options: Object.values(ProcessActivityState).map((key, index) => ({
    id: index.toString(),
    value: translateEnum("processes.activityState", key),
  })),
};

const buttonColumn: DataTableColumn = {
  name: "",
  type: FieldType.String,
  key: "",
  isFilterable: false,
  isSortable: false,
};

const processColumn: DataTableColumn = {
  name: i18n.processTitle,
  type: FieldType.String,
  key: "process_name",
};

const displayColumns = computed<DataTableColumn[]>(() => {
  const fieldColumns: DataTableColumn[] = deduplicate([
    ...fieldService.getNonTagFieldKeys(EntityType.Activity),
    ...fieldService.getNonTagFieldKeys(EntityType.Process),
  ]).map((fieldKey) => ({
    name: fieldKey.name,
    type: fieldKey.type,
    key: fieldKey.key,
  }));

  return [
    nameColumn,
    ...(props.showProcess ? [processColumn] : []),
    ...fieldColumns,
    progressColumn,
    ...(props.showCustomActivities ? [buttonColumn] : []),
  ];
});

const mandatoryColumns = computed<DataTableColumn[]>(() => {
  return [
    nameColumn,
    ...(props.showProcess ? [processColumn] : []),
    progressColumn,
    ...(props.showCustomActivities ? [buttonColumn] : []),
  ];
});

const availableTags = computed(() => {
  return fieldService.getTagFieldKeys(EntityType.Activity);
});

const rowItems = shallowRef<RowItem[]>([]);

const { listFilter } = useGetListFilter(
  props.contextKey ?? "processActivityList",
);
const { applyPaginationInfo } = useApplyPaginationInfo(
  props.contextKey ?? "processActivityList",
);

const processActivitiesQuery = useQuery({
  queryKey: [
    "processActivitiesList",
    () => props.processId,
    () => props.showCustomActivities,
    () => listFilter,
  ],
  queryFn: () => fetchAll(),
  placeholderData: keepPreviousData,
});

const processNamesQuery = useQuery({
  queryKey: ["processNames", () => processActivitiesQuery.data.value],
  queryFn: () => fetchProcessDetails(processActivitiesQuery.data.value),
  enabled: computed(() => (processActivitiesQuery.data.value?.length ?? 0) > 0),
});

const loading = computed<boolean>(
  () =>
    processActivitiesQuery.isLoading.value ||
    processActivitiesQuery.isRefetching.value ||
    processNamesQuery.isLoading.value ||
    processNamesQuery.isRefetching.value,
);

async function fetchProcessDetails(items?: ProcessActivityEto[]) {
  if (!items) {
    return [];
  }

  const result = await rawRequest<GetProcessGranularQuery>(
    GetProcessGranularDocument,
    {
      filter: {
        ids: items.map((processActivity) => processActivity.processId),
      },
      includeName: true,
    },
  );

  return result.data.process;
}

async function fetchAll(): Promise<ProcessActivityEto[]> {
  const result = await rawRequest<GetProcessActivityListQuery>(
    GetProcessActivityListDocument,
    {
      filter: {
        processIds: props.processId ? [props.processId] : undefined,
        custom: props.showCustomActivities ?? undefined,
        listFilter: {
          ...listFilter.value,
          pagination: {
            pageNumber: listFilter.value.pagination?.pageNumber ?? 0,
            pageSize: listFilter.value.pagination?.pageSize ?? 25,
          },
        },
      },
    },
  );

  applyPaginationInfo(result.extensions?.pagination);

  const fieldValues = result.data.processActivity.flatMap(
    (processActivity) => processActivity.fields ?? [],
  );
  fieldValues.forEach((fieldValue) =>
    fieldService.getFieldKey(fieldValue?.fieldKeyId),
  );
  fieldService.registerLoadedFieldValues(fieldValues);

  return (result.data.processActivity ?? []) as ProcessActivityEto[];
}

const processActivities = computed(
  () => processActivitiesQuery.data.value ?? [],
);

watchEffect(() => {
  rowItems.value = createRowItems(processActivities.value);
});

function createRowItems(items: ProcessActivityEto[]): RowItem[] {
  return items.map((processActivity) => {
    const cells: Record<string, CellContent> = {
      ...createActivityCell(processActivity),
      ...(props.showProcess ? createProcessCell(processActivity) : {}),
      ...createTemplateCell(processActivity),
      ...createFieldCellsByValues(processActivity.fields),
      ...createProgressCell(processActivity),
      ...(props.showCustomActivities ? createActionsCell(processActivity) : {}),
    };

    const tags = fieldService
      .getTagFieldValues(processActivity.activity.id)
      .flatMap(
        (fieldValue) =>
          fieldService.getFieldKey(fieldValue.fieldKeyId)?.name ?? [],
      );

    return {
      key: processActivity.id,
      cells,
      tags,
      to: {
        name: "processActivity",
        params: {
          processId: processActivity.processId,
          processActivityId: processActivity.id,
        },
      },
    };
  });
}

function createActivityCell(processActivity: ProcessActivityEto) {
  const activity = processActivity.activity;
  const isRoot = isRootActivity(processActivity);
  const skippedClasses =
    processActivity.status.state === ProcessActivityState.Skipped
      ? "text-gray-300"
      : "pointer";

  return {
    ["activity_name"]: {
      content: isRoot
        ? `${i18n.startActivity}: ${activity?.name ?? ""}`
        : (activity?.name ?? ""),
      props: {
        class: isRoot ? "pointer text-caeli5" : `pointer ${skippedClasses}`,
      },
    },
  };
}

function createProcessCell(processActivity: ProcessActivityEto) {
  const process = processNamesQuery.data.value?.find(
    (item) => item.id === processActivity.processId,
  );

  return {
    ["process_name"]: {
      content: process?.name ?? "-",
      props: {
        class: "pointer text-blue-grey-lighten-3",
      },
      to: {
        name: "process",
        params: { processId: processActivity.processId },
      },
    },
  };
}

function createTemplateCell(processActivity: ProcessActivityEto) {
  return {
    [i18n.templateTitle]: {
      content: processActivity.activity?.name ?? "",
    },
  };
}

function createProgressCell(processActivity: ProcessActivityEto) {
  const processActivityState = processActivity.status.state;
  return {
    ["state"]: {
      component: props.showDetails ? DataTableStatusColumn : undefined,
      props: props.showDetails
        ? {
            state: processActivityState.toString(),
            outputPresentCount: processActivity.status.outputPresentCount,
            outputAllCount: processActivity.status.outputAllCount,
            enumTranslationPath: "processes.activityState",
            tooltipsTranslationPath: "processes.activityStateTooltips",
            cachedLabels,
          }
        : undefined,
      mappedContent:
        cachedLabels[processActivityState] ??
        translateEnum("processes.activityState", processActivityState),
      content: Object.values(ProcessActivityState)
        .indexOf(processActivityState)
        .toString(),
    },
  };
}

function createActionsCell(processActivity: ProcessActivityEto) {
  return {
    [""]: {
      component: ProcessActivityActions,
      props: {
        processActivity,
        class: "justify-end",
      },
    },
  };
}

function isRootActivity(processActivity: ProcessActivityEto): boolean {
  return (
    !processActivity.activity.custom &&
    processActivity.status.inputAllCount === 0 &&
    processActivity.status.inputSkippedCount === 0
  );
}
</script>

<template>
  <CFilteredDataTable
    stripedRows
    :contextKey="contextKey"
    :rowItems="rowItems"
    :availableTags="availableTags"
    :exposedColumns="displayColumns"
    :mandatoryColumns="mandatoryColumns"
    :isLoading="loading"
  />
</template>
