<script setup lang="ts">
import { useSortable } from "@vueuse/integrations/useSortable";
import { v4 as uuidv4 } from "uuid";
import { computed, nextTick, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import TagsDisplay from "@/app/process/components/field/TagsDisplay.vue";
import TagsEditCard from "@/app/process/components/field/TagsEditCard.vue";
import ActivityInputsCard from "@/app/process/components/input/ActivityInputsCard.vue";
import ActivityOutputEditCard from "@/app/process/components/output/ActivityOutputEditCard.vue";
import ActivityTaskCard from "@/app/process/components/task/ActivityTaskCard.vue";
import FloatingActionButton from "@/base/components/button/FloatingActionButton.vue";
import DeleteDialog from "@/base/components/dialog/DeleteDialog.vue";
import EntityFieldsCard from "@/base/components/fields/EntityFieldsCard.vue";
import TextDisplay from "@/base/components/form/TextDisplay.vue";
import TextEditor from "@/base/components/form/TextEditor.vue";
import TextField from "@/base/components/form/value/TextField.vue";
import {
  type ActivityOutputEto,
  ActivityOutputType,
  type ActivityTaskEto,
  EntityType,
} from "@/base/graphql/generated/types.ts";
import { useFieldService } from "@/base/services/FieldService.ts";
import {
  Action,
  usePromptService,
} from "@/base/services/notification/PromptService.ts";
import { compareBySortOrder } from "@/base/services/utils.ts";

const props = defineProps<{
  activityId: string;
  processId?: string;
}>();

const activityService = useActivityService();
const fieldService = useFieldService();
const promptService = usePromptService();

const router = useRouter();
const { t } = useI18n();
const sortableListEl = ref(null);

const activity = computed(() => {
  return activityService.getActivity(props.activityId);
});
const current = computed(() =>
  activityService.getCurrentVersion(
    activityService.getMid(props.activityId) ?? "undefined",
  ),
);
const released = computed(() => {
  return activityService.isReleased(props.activityId);
});
const custom = computed(() => {
  return activityService.isCustom(props.activityId);
});
const inputs = computed(() => {
  return activityService.getInputs(props.activityId);
});
const outputs = computed(() => {
  return activityService
    .getOutputs(props.activityId)
    .filter((output) => !output.archived);
});
const tasks = computed(() => {
  return activityService
    .getTasks(props.activityId)
    .filter((task) => !task.archived);
});
const sortedTasksAndOutputs = computed({
  get: () => [...tasks.value, ...outputs.value].sort(compareBySortOrder),
  set: async (newValue) => await updateSortOrder(newValue),
});

async function updateSortOrder(list: (ActivityOutputEto | ActivityTaskEto)[]) {
  for (const taskOrOutput of list) {
    const index = list.indexOf(taskOrOutput);
    if ("type" in taskOrOutput) {
      await activityService
        .createOrUpdateOutput(
          {
            id: taskOrOutput.id,
            sortOrder: index * 10,
          },
          props.activityId,
          true,
        )
        .catch((error) => console.error(error));
    } else {
      await activityService
        .createOrUpdateTask(
          {
            id: taskOrOutput.id,
            sortOrder: index * 10,
          },
          props.activityId,
          true,
        )
        .catch((error) => console.error(error));
    }
    await nextTick();
  }
}

useSortable(sortableListEl, sortedTasksAndOutputs, {
  handle: ".drag-handle-icon",
  animation: 150,
  ghostClass: "drag-ghost",
  swapThreshold: 2,
});

const isRootActivity = computed(() => {
  return !inputs.value.length && !activity.value?.custom;
});

const title = computed(() => {
  if (activity.value?.custom) {
    return t("processes.singleView.customActivityTitle", {
      name: activity.value?.name,
    });
  }
  if (isRootActivity.value) {
    return t("processes.singleView.processTemplateTitle", {
      name: activity.value?.name,
    });
  }
  return t("processes.singleView.activityTitle", {
    name: activity.value?.name,
  });
});

const deleteDialog = ref<boolean>(false);

function deleteActivity() {
  deleteDialog.value = false;
  router.go(-1);
  activityService.deleteActivity(props.activityId).then(
    () => promptService.success(props.activityId, Action.DELETE),
    () =>
      promptService.failure(
        props.activityId,
        Action.DELETE,
        t("processes.templateProbablyInUse"),
      ),
  );
}

const entityTypes = computed(() => {
  return isRootActivity.value
    ? [EntityType.Process, EntityType.Activity]
    : [EntityType.Activity];
});
</script>

<template>
  <VCard
    v-if="activity !== undefined"
    class="bg-surface-50! h-screen overflow-y-auto text-caeli6 activity"
    variant="flat"
    :style="{
      borderLeft: released
        ? '0.25rem solid rgb(var(--v-theme-caeli5))'
        : undefined,
    }"
  >
    <VTooltip v-if="!released" location="start" :text="t('action.delete')">
      <template #activator="{ props: activator }">
        <FloatingActionButton
          v-bind="activator"
          icon="mdi-delete-outline"
          color="error"
          primary
          @click="() => (deleteDialog = true)"
        />
      </template>
    </VTooltip>
    <DeleteDialog
      v-model="deleteDialog"
      :target="
        t('processes.activity') +
        (activity.name ? ' ' + t('quoted', { expression: activity.name }) : '')
      "
      @confirm="deleteActivity"
      @cancel="() => (deleteDialog = false)"
    />
    <div class="d-flex flex-wrap py-3 ga-4 align-center ma-auto w-form">
      <p class="text-h6">
        {{ title }}
      </p>
      <VChip
        v-if="activityService.isReleased(props.activityId)"
        variant="flat"
        density="compact"
        color="caeli5"
      >
        {{ t("processes.released") }}
      </VChip>
      <VBtn
        v-if="
          activityService.isReleased(props.activityId) && !current?.released
        "
        prependIcon="mdi-pencil"
        variant="outlined"
        density="compact"
        color="caeli6"
        @click="
          () =>
            router.push({
              name: 'activity',
              params: { activityId: current?.id },
            })
        "
        >{{ t("processes.draft") }}
      </VBtn>
    </div>
    <div class="ma-auto w-form mt-3">
      <VForm class="d-flex flex-column ga-4">
        <TextField
          v-if="!released"
          :label="t('processes.nameLabel')"
          :initialValue="activity.name"
          required
          density="compact"
          variant="outlined"
          hideDetails="auto"
          data-testid="activity-single-view-name"
          @update="
            (value: string) =>
              activityService.createOrUpdateActivity({
                id: props.activityId,
                name: value,
              })
          "
        />

        <TagsDisplay v-if="released" :entityId="props.activityId" />

        <TextEditor
          v-if="!released"
          :containerId="props.activityId"
          :previousContent="activity.description ?? ''"
          :label="t('processes.singleView.descriptionLabel')"
          data-testid="activity-single-view-description"
          @saveContent="
            (content: string) =>
              activityService.createOrUpdateActivity({
                id: props.activityId,
                description: content,
              })
          "
        />
        <TextDisplay
          v-else-if="activity.description"
          :label="t('processes.singleView.descriptionLabel')"
          :value="activity.description ?? ''"
          markdown
        />
        <TextField
          v-if="!released"
          density="compact"
          :initialValue="activity.goal ?? undefined"
          :label="t('processes.singleView.goalLabel')"
          variant="outlined"
          hideDetails="auto"
          data-testid="activity-single-view-goal"
          @update="
            (goal: string) =>
              activityService.createOrUpdateActivity({
                id: props.activityId,
                goal,
              })
          "
        />

        <TextDisplay
          v-else-if="activity.goal"
          :value="activity.goal ?? ''"
          :label="t('processes.singleView.goalLabel')"
        />

        <TagsEditCard
          v-if="!released"
          :entityType="EntityType.Activity"
          :entityId="props.activityId"
          @delete="(id: string) => fieldService.deleteFieldWithInstances(id)"
        />

        <EntityFieldsCard
          :entityType="entityTypes"
          :entityId="props.activityId"
          :readonly="released"
        />
      </VForm>

      <ActivityInputsCard
        v-if="!isRootActivity && !custom"
        :activityId="props.activityId"
        :readonly="released"
      />
      <div class="mt-3 px-1 pb-3">
        <p class="text-h6 py-2">
          <template v-if="isRootActivity">
            {{ t("processes.startParameter", 2) }}
          </template>
          <template v-else>
            {{ t("processes.task", 2) + " & " + t("processes.output", 2) }}
          </template>
        </p>
        <div ref="sortableListEl" class="d-flex flex-column flex-wrap">
          <div
            v-for="taskOrOutput in sortedTasksAndOutputs.slice()"
            :key="taskOrOutput.id"
          >
            <ActivityOutputEditCard
              v-if="'type' in taskOrOutput"
              class="mb-2"
              :outputId="taskOrOutput.id"
              :activityId="props.activityId"
              :readonly="released"
              :customCaption="
                isRootActivity ? t('processes.startParameter') : undefined
              "
            />
            <ActivityTaskCard
              v-else
              :id="taskOrOutput.id"
              :activityId="props.activityId"
              class="task-card mb-2 mt-6"
              :readonly="released"
              draggable
            />
          </div>
        </div>
        <div class="d-flex ga-4 mt-1">
          <VBtn
            v-if="!released && !isRootActivity"
            variant="outlined"
            color="caeli5"
            prependIcon="mdi-plus"
            data-testid="activity-create-task-button"
            @click="
              activityService.createOrUpdateTask(
                {
                  id: uuidv4(),
                  description: '',
                },
                props.activityId,
              )
            "
            >{{ t("processes.singleView.createTaskButtonLabel") }}
          </VBtn>
          <VBtn
            v-if="!released"
            variant="outlined"
            color="caeli5"
            prependIcon="mdi-plus"
            data-testid="activity-create-output-button"
            @click="
              activityService.createOrUpdateOutput(
                {
                  id: uuidv4(),
                  type: ActivityOutputType.String,
                  description: '',
                },
                props.activityId,
              )
            "
            >{{
              isRootActivity
                ? t("processes.singleView.createStartparameterButtonLabel")
                : t("processes.singleView.createOutputButtonLabel")
            }}
          </VBtn>
        </div>
      </div>
    </div>
  </VCard>
</template>

<style scoped>
.drag-ghost {
  border-radius: 4px;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 0.4rem;
  background-color: rgba(var(--v-theme-caeli5), 0.5);
}
</style>

<style>
#app .activity .v-text-field input {
  font-size: 0.85em;
}

div .task-card:first-child {
  margin-top: 0 !important;
}
</style>
