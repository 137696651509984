<script setup lang="ts">
/* eslint-disable */
// @ts-nocheck

import { ref } from "vue";
import FieldKeyOverviewTab from "@/app/admin/components/FieldKeyOverviewTab.vue";
import FieldKeyCreationTab from "@/app/admin/components/FieldKeyCreationTab.vue";
import { useRouter } from "vue-router";

const router = useRouter();
const activeTab = ref<"overview" | "create">("overview");

function refresh() {
  activeTab.value = "overview";
  router.go(0);
}
</script>

<template>
  <VCard
    class="activity bg-grey-lighten-5 pt-2 h-screen overflow-y-auto text-caeli6"
  >
    <VTabs fixedTabs v-model="activeTab">
      <VTab value="overview">Overview</VTab>
      <VTab value="create">Create</VTab>
    </VTabs>

    <VCardText>
      <VTabsWindow v-model="activeTab">
        <VTabsWindowItem value="overview">
          <FieldKeyOverviewTab />
        </VTabsWindowItem>

        <VTabsWindowItem value="create">
          <FieldKeyCreationTab @onSaveSuccess="refresh" />
        </VTabsWindowItem>
      </VTabsWindow>
    </VCardText>
  </VCard>
</template>
