<script setup lang="ts">
import { computed } from "vue";

import { type EditableDataTableColumn } from "@/base/components/filterdatatable/table/CFilteredDataTableUtils.ts";
import { type RowItem } from "@/base/components/filterdatatable/TableTypes";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import { FieldType } from "@/base/graphql/generated/types";

export interface ColumnEditorUpdateEvent {
  id: string;
  [key: string]: string;
}

const props = defineProps<{
  data: RowItem;
  column: EditableDataTableColumn<unknown>;
}>();

const emits = defineEmits<{
  update: [value: ColumnEditorUpdateEvent];
}>();

const columnCell = computed(
  () => props.data.cells?.[props.column.key ?? props.column.name],
);

const columnContent = computed(() => {
  if (Array.isArray(columnCell.value?.content)) {
    console.warn("Arrays are not yet supported in editable datatable columns");
    return undefined;
  }
  return columnCell.value?.content;
});

function update(value: string) {
  emits("update", {
    id: props.data.key,
    [props.column.editKey]: value,
    [props.column.key ?? props.column.name]: value,
  });
}
</script>

<template>
  <div v-if="columnCell && !columnCell.hasMultipleValues">
    <BaseTextField
      v-if="props.column.type === FieldType.String"
      :initialValue="columnContent"
      :autofocus="props.column.autofocus"
      @update="update"
    />
  </div>
</template>
