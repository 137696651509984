<script lang="ts" setup>
import { sanitizeUrl } from "@braintree/sanitize-url";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import OptionField from "@/app/process/components/field/OptionField.vue";
import ReadonlyField from "@/app/process/components/field/ReadonlyField.vue";
import {
  type FieldKeyDto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";
import { extractDomain } from "@/base/utils/string.ts";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
}>();

const { t } = useI18n();

const emit = defineEmits<(e: "update", value: FieldValueDto) => void>();

const sanitizedUrl = computed(() => {
  const valueString = props.fieldValue?.value?.valueString;

  if (!valueString) {
    return "#";
  }
  return sanitizeUrl(
    valueString?.startsWith("http") ? valueString : `https://${valueString}`,
  );
});

const isOptionField = computed(() => (props.fieldKey.options?.length ?? 0) > 0);

function emitOptionUpdate(value: string) {
  emit("update", { ...props.fieldValue, optionId: value });
}

function emitUpdate(value: string) {
  emit("update", {
    ...props.fieldValue,
    value: {
      valueString: value,
    },
  });
}
</script>

<template>
  <ReadonlyField v-if="props.readonly" :label="props.label">
    <div class="d-flex w-100">
      <div class="d-flex align-center ga-1">
        <a
          :href="sanitizedUrl"
          class="toastui-editor-contents url"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{
            extractDomain(
              props.fieldValue?.value?.valueString ?? "",
              t("processes.singleView.outputTypes.URL"),
            )
          }}
        </a>
        <VIcon icon="mdi-open-in-new" size="tiny" />
      </div>
    </div>
  </ReadonlyField>
  <OptionField
    v-else-if="isOptionField"
    :fieldKey="props.fieldKey"
    :fieldValue="props.fieldValue"
    :label="props.label"
    itemTitle="valueString"
    @update="emitOptionUpdate"
  />
  <VTextField
    v-else
    :modelValue="props.fieldValue?.value?.valueString"
    :label="props.fieldKey.name ?? ''"
    variant="outlined"
    density="compact"
    hideDetails="auto"
    @update:model-value="emitUpdate"
  ></VTextField>
</template>

<style scoped>
.url {
  color: rgb(var(--v-theme-caeli-link));
  text-wrap: nowrap;
}
</style>
