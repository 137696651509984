import { DataTableContextKeys } from "@/base/components/filterdatatable/FilteredDataTableUtil.ts";
import {
  GetPersonDocument,
  GetPersonWithReferencesDocument,
  PersonDeleteDocument,
  PersonDocument,
  type PersonDto,
  type PersonEto,
} from "@/base/graphql/generated/types.ts";
import { defineEntityStoreV2 } from "@/base/stores/entity/EntityStoreV2.ts";

export const usePersonStoreV2 = defineEntityStoreV2<PersonEto, PersonDto>({
  key: "PersonStoreV2",
  singleViewRouteParam: "personId",
  contextKey: DataTableContextKeys.personList,
  listQuery: GetPersonDocument,
  singleQuery: GetPersonWithReferencesDocument,
  createOrUpdateMutation: PersonDocument,
  deleteMutation: PersonDeleteDocument,
});
