<script lang="ts" setup>
import { computed, reactive } from "vue";
import { useI18n } from "vue-i18n";

import { countries } from "@/app/process/components/field/countries.ts";
import OptionField from "@/app/process/components/field/OptionField.vue";
import ReadonlyField from "@/app/process/components/field/ReadonlyField.vue";
import { useValidators } from "@/base/components/form/validators.ts";
import {
  type FieldKeyDto,
  type FieldValueAddressV1,
  type FieldValueDto,
  type FieldValueEto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
}>();

const { t } = useI18n();

const emit = defineEmits<(e: "update", value: FieldValueDto) => void>();

const { zipCode } = useValidators();

const address: FieldValueAddressV1 = reactive(
  props.fieldValue?.value?.valueJson
    ? JSON.parse(props.fieldValue?.value?.valueJson)
    : {
        _type: "FieldValueAddressV1",
        country: undefined,
        zipCode: undefined,
        city: undefined,
        street: undefined,
      },
);

const addressReadOnly = computed(() => {
  return props.fieldValue ? parseAddressValue(props.fieldValue) : "";
});

// Favorites are on top of the list
const favoriteCountries = ["DE", "AT", "CH", "FR"];

const sortedCountries = countries
  .filter((country) => favoriteCountries.includes(country.value))
  .sort(
    (a, b) =>
      favoriteCountries.indexOf(a.value) - favoriteCountries.indexOf(b.value),
  )
  .concat(
    countries
      .filter((country) => !favoriteCountries.includes(country.value))
      .sort((a, b) => a.title.localeCompare(b.title)),
  );

function parseAddressValue(value: FieldValueDto | FieldValueEto) {
  const parsedAddress = JSON.parse(
    value.value?.valueJson ?? "{}",
  ) as FieldValueAddressV1;
  const addressParts: string[] = [];

  function parsePartOfAddress(part: keyof FieldValueAddressV1) {
    if (parsedAddress[part]) {
      addressParts.push(parsedAddress[part].toString());
    }
  }

  parsePartOfAddress("country");
  parsePartOfAddress("zipCode");
  parsePartOfAddress("province");
  parsePartOfAddress("city");
  parsePartOfAddress("street");

  return addressParts.join(" - ");
}

// We need to wrap the options in a computed property to show the parsed address instead of JSON
const availableOptions = computed(() => {
  return props.fieldKey?.options?.map((option) => {
    return {
      id: option.id,
      title: parseAddressValue(option),
    };
  });
});

const isOptionField = computed(() => (props.fieldKey.options?.length ?? 0) > 0);

function emitOptionUpdate(value: string) {
  emit("update", { ...props.fieldValue, optionId: value });
}

function emitUpdate() {
  const addressJson = JSON.stringify(address);
  emit("update", {
    ...props.fieldValue,
    value: {
      ...props.fieldValue.value,
      valueJson: addressJson,
    },
  });
}
</script>

<template>
  <ReadonlyField
    v-if="props.readonly"
    :label="props.label"
    :value="addressReadOnly"
  />
  <OptionField
    v-else-if="isOptionField"
    :fieldKey="props.fieldKey"
    :fieldValue="props.fieldValue"
    :options="availableOptions"
    :label="props.label"
    @update="emitOptionUpdate"
  />
  <VCard v-else class="pa-3 field-border pt-0 w-100" variant="flat">
    <div class="d-flex justify-space-between align-center pb-3">
      <p class="text-subtitle-2">{{ props.fieldKey.name ?? undefined }}</p>
    </div>
    <div class="d-flex flex-column ga-3">
      <VAutocomplete
        v-model="address.country"
        variant="outlined"
        density="compact"
        :label="t('address.country')"
        :items="sortedCountries"
        autoSelectFirst
        hideDetails="auto"
        data-testid="address-field-country-multiselect"
        @blur="emitUpdate"
      >
        <template #item="{ props: itemProps, item, index }">
          <VListItem
            v-bind="itemProps"
            :title="item.title"
            :class="{ 'border-b': index === favoriteCountries.length - 1 }"
          />
        </template>
      </VAutocomplete>
      <div class="d-flex ga-3">
        <VTextField
          v-model="address.zipCode"
          type="number"
          class="w-33"
          variant="outlined"
          :rules="[zipCode]"
          density="compact"
          :label="t('address.zipCode')"
          hideDetails="auto"
          data-testid="address-field-zip-input"
          @blur="emitUpdate"
        />
        <VTextField
          v-model="address.city"
          type="text"
          class="w-66"
          variant="outlined"
          density="compact"
          :label="t('address.city')"
          hideDetails="auto"
          data-testid="address-field-city-input"
          @blur="emitUpdate"
        />
      </div>
      <VTextField
        v-model="address.street"
        type="text"
        variant="outlined"
        density="compact"
        :label="t('address.street')"
        hideDetails="auto"
        data-testid="address-field-street-input"
        @blur="emitUpdate"
      />
    </div>
  </VCard>
</template>
