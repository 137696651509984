<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import AppDialog from "@/base/components/dialog/AppDialog.vue";
import { type ActivityDto } from "@/base/graphql/generated/types.ts";
import { UI_DIALOG, useDialogService } from "@/base/services/DialogService.ts";
import {
  Action,
  usePromptService,
} from "@/base/services/notification/PromptService.ts";

const { t } = useI18n();

const activityService = useActivityService();
const processService = useProcessService();
const dialogService = useDialogService();
const promptService = usePromptService();

const emits = defineEmits<(event: "beforeDelete", ids: string[]) => void>();

const dialogOpen = computed(() =>
  dialogService.isDialogOpen(UI_DIALOG.DELETE_ACTIVITY),
);

function deleteActivity() {
  const ids = dialogService.dialogActivityList.map((it) => it.id);
  emits("beforeDelete", ids);

  deleteNextActivity(dialogService.dialogActivityList);
  dialogService.closeDialog(UI_DIALOG.DELETE_ACTIVITY);
}

function deleteNextActivity(remainingActivities: ActivityDto[]) {
  if (remainingActivities.length === 0) {
    return;
  }
  const activityToDelete = remainingActivities[0];

  // if the activity to be deleted is a custom activity we also need to delete the processActivity of it
  if (activityToDelete.custom && dialogService.dialogEntityId) {
    const processActivityToDelete = processService.getProcessActivityByTemplate(
      dialogService.dialogEntityId,
      activityToDelete.id,
    );
    if (processActivityToDelete) {
      processService
        .deleteProcessActivity(processActivityToDelete.id)
        .catch((reason) =>
          promptService.failure(
            processActivityToDelete.id,
            Action.DELETE,
            reason,
          ),
        );
    }
  }
  activityService
    .deleteActivity(remainingActivities[0].id)
    .then(() => {
      deleteNextActivity(remainingActivities.slice(1));
    })
    .catch((reason) =>
      promptService.failure(remainingActivities[0].id, Action.DELETE, reason),
    );
}
</script>

<template>
  <AppDialog
    v-model="dialogOpen"
    :title="
      dialogService.dialogActivityList.length === 1
        ? t('processes.listView.deleteActivity')
        : t('processes.listView.deleteProcessTemplate')
    "
    :confirmMessage="t('action.delete')"
    :cancelMessage="t('action.cancel')"
    destructive
    @confirm="deleteActivity"
    @cancel="() => dialogService.closeDialog(UI_DIALOG.DELETE_ACTIVITY)"
  >
    <p>
      {{
        dialogService.dialogActivityList.length === 1
          ? t("processes.listView.deleteActivityText", {
              name: dialogService.dialogActivityList[0].name,
            })
          : t(
              "processes.listView.deleteProcessTemplateText",
              {
                name: dialogService.dialogActivityList[0].name,
                subActivityCount: dialogService.dialogActivityList.length - 1,
              },
              dialogService.dialogActivityList.length - 1,
            )
      }}
    </p>
  </AppDialog>
</template>
