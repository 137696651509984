import { computed, type ComputedRef, onBeforeUnmount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { type RouteLocation, useRoute, useRouter } from "vue-router";

const stringOrNull = (possibleString: unknown): string | null => {
  if (typeof possibleString === "string") {
    return possibleString;
  } else {
    return null;
  }
};

export interface PreviousRoute {
  label: string;
  go: () => void;
}

export const usePreviousRoute = (): ComputedRef<PreviousRoute | null> => {
  const router = useRouter();
  const currentRoute = useRoute();
  const { t } = useI18n();

  const previousRoutePath = ref(
    stringOrNull(router.options.history.state.back),
  );
  const unsubscribePreviousRoute = router.afterEach(() => {
    previousRoutePath.value = stringOrNull(router.options.history.state.back);
  });
  onBeforeUnmount(unsubscribePreviousRoute);

  const previousRoute = computed<RouteLocation | null>(() => {
    if (!previousRoutePath.value) {
      return null;
    }
    const route = router.resolve({ path: previousRoutePath.value });
    return route ?? null;
  });

  const previousRouteLink = computed<PreviousRoute | null>(() => {
    if (!previousRoute.value) {
      return null;
    }
    const translationKey = previousRoute.value.meta.linkTranslationKey;
    return {
      // eslint-disable-next-line no-restricted-syntax, @intlify/vue-i18n/no-dynamic-keys -- this is safe because translationKey is of type TranslationPath
      label: translationKey ? t(translationKey) : t("ui.back"),
      go: () => router.back(),
    };
  });

  function resolveBackTarget(target: string) {
    const route = router.resolve({ name: target });
    const translationKey = route.meta.linkTranslationKey;

    return {
      // eslint-disable-next-line no-restricted-syntax, @intlify/vue-i18n/no-dynamic-keys -- this is safe because translationKey is of type TranslationPath
      label: translationKey ? t(translationKey) : t("ui.back"),
      go: () => void router.replace(route.href),
    };
  }

  const hardBackLink = computed<PreviousRoute | null>(() => {
    const hardBackTarget = currentRoute.meta.hardBackTarget;
    return hardBackTarget ? resolveBackTarget(hardBackTarget) : null;
  });

  const defaultBackLink = computed<PreviousRoute | null>(() => {
    const defaultBackTarget = currentRoute.meta.defaultBackTarget;
    return defaultBackTarget ? resolveBackTarget(defaultBackTarget) : null;
  });

  return computed<PreviousRoute | null>(() => {
    if (!currentRoute.meta.showBackButton) {
      return null;
    }
    return (
      hardBackLink.value ?? previousRouteLink.value ?? defaultBackLink.value
    );
  });
};
