<script setup lang="ts">
/* eslint-disable */
// @ts-nocheck

import { computed } from "vue";

import { useFieldService } from "@/base/services/FieldService.ts";
import { EntityType, FieldType, type FieldValueEto } from "@/base/graphql/generated/types.ts";

const fieldService = useFieldService();
const entityTypes = Object.values(EntityType);
const isLoading = computed(
  () => fieldService.isLoadingFieldKey() || fieldService.isLoadingFieldValue()
);
const fieldKeysPerType = computed(() => {
  return entityTypes.map((entityType) => {
    const fieldKeys = fieldService.getNonTagFieldKeys(entityType);
    return {
      name: entityType.toString(),
      values: fieldKeys
    };
  });
});

function sortedFieldKeysPerType(fieldKeysPerTypeValue: values) {
  return fieldKeysPerTypeValue.sort((a, b) => a.name.localeCompare(b.name));
}

function mapOptions(valueType: FieldType, options: FieldValueEto[]) {
  let name: string;

  switch (valueType) {
    case FieldType.Boolean:
      name = "valueBoolean";
      break;
    case FieldType.Date:
      name = "valueDate";
      break;
    case FieldType.Number:
      name = "valueNumber";
      break;
    case FieldType.Url:
      name = "valueUrl";
      break;
    case FieldType.Person:
    case FieldType.File:
      name = "valueEntityId";
      break;
    case FieldType.Address:
    case FieldType.Json:
    case FieldType.BankAccount:
      name = "valueJson";
      break;
    default:
      name = "valueString";
  }

  return options
    .map((option: FieldValueEto) => {
      return {
        id: option.id,
        name: option.value[name as keyof FieldValueEto]
      };
    })
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
}
</script>

<template>
  <VCard
    class="activity bg-grey-lighten-5 text-caeli6"
    variant="flat"
    :loading="isLoading"
  >
    <template #loader="{ isActive }">
      <VProgressLinear
        :active="isActive"
        color="caeli5"
        height="4"
        :indeterminate="true"
      />
    </template>

    <div v-for="entry in fieldKeysPerType" :key="entry.name">
      <VExpansionPanels>
        <VExpansionPanel
          :disabled="entry.values.length === 0"
          class="mb-4"
          elevation="1"
        >
          <template #title>
            <div class="d-flex flex-row ga-2 align-center">
              <h4>EntityType.{{ entry.name }}</h4>
              <p class="text-subtitle-2">(Keys: {{ entry.values.length }})</p>
            </div>
          </template>

          <template #text>
            <VCard
              v-if="entry.values.length > 0"
              elevation="1"
              class="pa-4 mt-4"
            >
              <VTable density="compact">
                <thead>
                <tr>
                  <th scope="col" class="font-weight-bold" style="width: 14%">
                    Name
                  </th>

                  <th scope="col" class="font-weight-bold" style="width: 9%">
                    Technical Name
                  </th>

                  <th scope="col" class="font-weight-bold" style="width: 4%">
                    Prefix
                  </th>

                  <th scope="col" class="font-weight-bold" style="width: 4%">
                    Description
                  </th>

                  <th scope="col" class="font-weight-bold" style="width: 20%">
                    UUIDv4
                  </th>

                  <th scope="col" class="font-weight-bold" style="width: 15%">
                    Type
                  </th>

                  <th scope="col" class="font-weight-bold" style="width: 35%">
                    Options
                  </th>
                </tr>
                </thead>

                <tbody>
                <tr
                  v-for="fieldKey in sortedFieldKeysPerType(entry.values)"
                  :key="fieldKey.id"
                >
                  <td class="pt-6" :style="'vertical-align: top'">
                      <span v-if="fieldKey.unique">
                        <VIcon icon="mdi-star" class="mr-1" />
                        <VTooltip
                          activator="parent"
                          text="Unique"
                          location="top"
                        />
                      </span>
                    <span>{{ fieldKey.name }}</span>
                  </td>

                  <td class="pt-6" :style="'vertical-align: top'">
                    {{ fieldKey.key }}
                  </td>

                  <td class="pt-6" :style="'vertical-align: top'">
                    <VIcon
                      v-if="(fieldKey.prefix ?? '').includes('mdi-')"
                      :icon="fieldKey.prefix"
                      class="mr-1"
                      size="18px"
                    />
                    <span v-else> {{ fieldKey.prefix }} </span>
                  </td>

                  <td class="pt-6" :style="'vertical-align: top'">
                    {{ fieldKey.description }}
                  </td>

                  <td class="pt-6" :style="'vertical-align: top'">
                    {{ fieldKey.id }}
                  </td>

                  <td class="pt-6" :style="'vertical-align: top'">
                    {{ fieldKey.type }}
                  </td>

                  <td>
                    <VExpansionPanels
                      :disabled="fieldKey.options.length === 0"
                    >
                      <VExpansionPanel class="py-2 ml-n12" elevation="0">
                        <template #title>
                          <span>{{ fieldKey.options.length }} Options</span>
                        </template>

                        <template #text>
                          <VList>
                            <div
                              v-for="option in mapOptions(
                                  fieldKey.type,
                                  fieldKey.options,
                                )"
                              :key="option.id"
                            >
                              <VListItem class="ml-n4">
                                  <span class="font-weight-bold">{{
                                      option.name
                                    }}</span>
                                <p>{{ option.id }}</p>
                              </VListItem>
                            </div>
                          </VList>
                        </template>
                      </VExpansionPanel>
                    </VExpansionPanels>
                  </td>
                </tr>
                </tbody>
              </VTable>
            </VCard>
          </template>
        </VExpansionPanel>
      </VExpansionPanels>
    </div>
  </VCard>
</template>
