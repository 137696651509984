<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import ObjectField from "@/app/process/components/field/ObjectField.vue";
import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseCard from "@/base/components/card/BaseCard.vue";
import {
  type FieldKeyEto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  fieldKey: FieldKeyEto;
  fieldValue: FieldValueDto;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
  isInherited?: boolean;
}>();

const emit = defineEmits<{
  (e: "update", value: FieldValueDto): void;
  (e: "editing", value: boolean): void;
  (e: "delete"): void;
}>();

const { t } = useI18n();
</script>

<template>
  <PInplace
    :pt="{
      root: { class: 'h-full' },
      display: { class: 'w-full h-full' },
    }"
    @open="emit('editing', true)"
    @close="emit('editing', false)"
  >
    <template #display>
      <div class="flex flex-row items-start gap-2">
        <span
          class="mdi text-[2.3rem] opacity-90 mr-2 mt-2"
          :class="props.fieldKey.prefix ?? 'mdi-help'"
        ></span>

        <div class="flex flex-col">
          <p
            class="block mb-1 text-sm font-extrabold text-ellipsis overflow-hidden"
          >
            {{ props.label ?? "-" }}
          </p>

          <p
            class="block text-base text-ellipsis overflow-hidden"
            data-testid="field-value"
          >
            {{ props.fieldValue?.value?.valueJson ?? "-" }}
          </p>
        </div>
      </div>
    </template>

    <template #content="{ closeCallback }">
      <BaseCard :pt="{ root: { class: 'h-full' } }">
        <template #title>
          <div class="flex flex-row justify-between">
            <p
              class="mt-2 text-sm font-bold block text-nowrap text-ellipsis overflow-hidden whitespace-nowrap"
            >
              {{ props.label ?? "-" }}
            </p>
            <div class="flex flex-row">
              <BaseButton
                v-tooltip.bottom="{
                  value:
                    !props.readonly && isInherited
                      ? t('processes.fieldCannotDeleteInherited')
                      : '',
                }"
                icon="mdi mdi-delete-outline"
                data-testid="delete-field-button"
                text
                severity="danger"
                :disabled="props.readonly || isInherited"
                @click="emit('delete')"
              />

              <BaseButton
                icon="mdi mdi-close"
                data-testid="close-field-card-button"
                text
                severity="secondary"
                @click="closeCallback"
              />
            </div>
          </div>
        </template>

        <ObjectField
          data-testid="json-field-input"
          :initialValue="props.fieldValue?.value?.valueJson ?? undefined"
          :label="fieldKey?.name ?? label ?? '-'"
          @update="
            (value) => {
              emit('update', {
                ...props.fieldValue,
                value: { valueJson: value },
              });
              closeCallback();
            }
          "
        />
      </BaseCard>
    </template>
  </PInplace>
</template>
