import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { usePersonStore } from "@/app/person/stores/PersonStore.ts";
import {
  EntityType,
  type FieldKeyEto,
  useGetFieldKeysTopUsedQuery,
} from "@/base/graphql/generated/types.ts";
import { useFieldService } from "@/base/services/FieldService.ts";

export const usePersonService = defineStore("PersonService", () => {
  const personStore = usePersonStore();
  const fieldService = useFieldService();

  const { onResult: onResultTopUsed, loading: loadingTopUsed } =
    useGetFieldKeysTopUsedQuery({
      limit: 3,
      entityType: EntityType.Person,
    });
  onResultTopUsed((result) => {
    if (loadingTopUsed.value) {
      return;
    }
    topUsedFieldKeys.value = result.data.fieldKeysTopUsed;
    fieldService.registerLoadedFieldKeys(result.data.fieldKeysTopUsed);
  });
  const topUsedFieldKeys = ref<FieldKeyEto[]>();

  // todo refactor this to use a UiService where each dialog has its own enum
  const isDeleteDialogOpen = ref(false);

  function openDeleteDialog() {
    isDeleteDialogOpen.value = true;
  }

  function closeDeleteDialog() {
    isDeleteDialogOpen.value = false;
  }

  return {
    isLoading: (id?: string) => personStore.isLoading(id),
    getById: (id: string) => personStore.getById(id),
    getAll: () => personStore.getAll(),
    openDeleteDialog,
    closeDeleteDialog,
    isDeleteDialogOpen,
    getTopUsedFieldKeys: computed(() => topUsedFieldKeys.value),
  };
});
