<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import ProcessActivityTable from "@/app/process/components/tables/ProcessActivityTable.vue";
import { DataTableContextKeys } from "@/base/components/filterdatatable/FilteredDataTableUtil.ts";
import { useTitle } from "@/base/composables/useTitle.ts";

const { t } = useI18n();

useTitle(t("processes.activity", 2));
</script>

<template>
  <ProcessActivityTable
    class="p-4!"
    showProcess
    showDetails
    :contextKey="DataTableContextKeys.processActivityList"
  />
</template>
