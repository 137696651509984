import { defineStore, storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { usePersonStoreV2 } from "@/app/person/stores/PersonStoreV2.ts";
import { createFieldCells } from "@/base/components/filterdatatable/FilteredDataTableUtil.ts";
import { type DataTableColumn } from "@/base/components/filterdatatable/table/CFilteredDataTableUtils.ts";
import {
  type CellContent,
  type RowItem,
} from "@/base/components/filterdatatable/TableTypes.ts";
import {
  EntityType,
  FieldType,
  type PersonDto,
  type PersonEto,
} from "@/base/graphql/generated/types.ts";
import { useFieldService } from "@/base/services/FieldService.ts";
import {
  Action,
  usePromptService,
} from "@/base/services/notification/PromptService.ts";

export const usePersonServiceV2 = defineStore("PersonServiceV2", () => {
  const { t } = useI18n();
  const router = useRouter();
  const personStore = usePersonStoreV2();
  const fieldService = useFieldService();
  const promptService = usePromptService();
  const {
    isLoading,
    entities: persons,
    selectedEntity: selectedPerson,
  } = storeToRefs(personStore);

  const translations = {
    person: {
      name: t("person.name"),
      reference: t("person.reference"),
      referenceName: t("person.referenceName"),
      referenceType: t("person.referenceType"),
    },
  };

  const mandatoryColumns: DataTableColumn[] = [
    {
      name: translations.person.name,
      key: translations.person.name,
      type: FieldType.String,
    },
  ];

  const availableTags = computed(() =>
    fieldService.getTagFieldKeys(EntityType.Person),
  );

  const listRowItems = computed(() => persons.value.map(createRowItem));

  const displayColumns = computed<DataTableColumn[]>(() => {
    return [...mandatoryColumns].concat(
      fieldService
        .getNonTagFieldKeys(EntityType.Person)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((fieldKey) => {
          return {
            name: fieldKey.name,
            key: fieldKey.key,
            type: fieldKey.type,
          };
        }),
    );
  });

  const personReferenceListRowItems = computed(() => {
    return (selectedPerson.value?.references ?? []).map(
      (entityRelationship) => {
        return {
          cells: {
            [translations.person.reference]: {
              content: entityRelationship.fieldName,
              props: { class: "pointer" },
            },
            [translations.person.referenceName]: {
              content: entityRelationship.name ?? undefined,
              props: { class: "pointer" },
              events: {
                click: () =>
                  router.push({
                    name: "personView",
                    params: { personId: entityRelationship.entityId },
                  }),
              },
            },
            [translations.person.referenceType]: {
              content: entityRelationship.entityType,
              props: { class: "pointer" },
            },
          },
          tags: [],
          key: entityRelationship.id,
        };
      },
    );
  });

  async function createOrUpdate(dto: PersonDto) {
    await personStore.createOrUpdateMutation.mutateAsync(dto).then(
      async () => {
        await router.push({
          name: "personEdit",
          params: { personId: dto.id },
        });

        promptService.success(dto.id, Action.SAVE, dto.name ?? "");
      },
      (reason) => promptService.failure(dto.id, Action.SAVE, reason),
    );
  }

  async function deletePerson(id: string) {
    await personStore.deleteMutation.mutateAsync(id).then(
      async () => {
        await router.push({ name: "personList" });
        promptService.success(id, Action.DELETE);
      },
      (reason) => promptService.failure(id, Action.DELETE, reason),
    );
  }

  function createRowItem(person: PersonEto): RowItem {
    const cells = {
      ...createPersonNameCell(person),
      ...createFieldCells(person.id),
    };

    const tags = fieldService
      .getTagFieldValues(person.id)
      .flatMap(
        (fieldValue) =>
          fieldService.getFieldKey(fieldValue.fieldKeyId)?.name ?? [],
      );

    return {
      key: person.id,
      cells,
      tags,
      to: {
        name: "personView",
        params: { personId: person.id },
      },
    };
  }

  function createPersonNameCell(
    person: PersonEto,
  ): Record<string, CellContent> {
    return {
      [translations.person.name]: {
        content: person.name?.toString(),
        props: { class: "pointer" },
        events: {
          click: async () =>
            await router.push({
              name: "personView",
              params: { personId: person.id },
            }),
        },
      },
    };
  }

  return {
    isLoading,
    mandatoryColumns,
    availableTags,
    displayColumns,
    listRowItems,
    personReferenceListRowItems,
    selectedPerson,
    createOrUpdate,
    deletePerson,
    getById: (id: string) => personStore.getById(id),
  };
});
