// Utility functions for arrays of items with IDs.
// Use these in container components that allow insert/update/delete actions on such arrays.

export function deduplicate<T>(array?: T[]): T[] {
  return Array.from(new Set(array));
}

export function union<T>(...arrays: T[][]): T[] {
  return deduplicate(([] as T[]).concat(...arrays));
}

export function mapBy<T>(
  array: T[],
  keyFunction: (element: T) => string,
): Map<string, T[]> {
  const result = new Map<string, T[]>();
  array.forEach((element) => {
    const key = keyFunction(element);
    const entries = result.get(key) ?? [];
    entries.push(element);
    result.set(key, entries);
  });
  return result;
}
