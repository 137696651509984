<script lang="ts" setup>
import { type AutoCompleteCompleteEvent } from "primevue";
import { computed, nextTick, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";

import { countries } from "@/app/process/components/field/countries.ts";
import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseCard from "@/base/components/card/BaseCard.vue";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import {
  type FieldKeyDto,
  type FieldValueAddressV1,
  type FieldValueDto,
  type FieldValueEto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
}>();

const { t } = useI18n();

const emit = defineEmits<{
  (e: "update", value: FieldValueDto): void;
  (e: "editing", value: boolean): void;
  (e: "delete"): void;
}>();

const address: FieldValueAddressV1 = reactive(
  props.fieldValue?.value?.valueJson
    ? JSON.parse(props.fieldValue?.value?.valueJson)
    : {
        _type: "FieldValueAddressV1",
        country: undefined,
        zipCode: undefined,
        city: undefined,
        street: undefined,
      },
);

const addressReadOnly = computed(() => {
  return props.fieldValue.value?.valueJson
    ? parseAddressValue(props.fieldValue)
    : "";
});

// Favorites are on top of the list
const favoriteCountries = ["DE", "AT", "CH", "FR"];

const sortedCountries = computed(() => {
  return countries
    .filter((country) => favoriteCountries.includes(country.value))
    .sort(
      (a, b) =>
        favoriteCountries.indexOf(a.value) - favoriteCountries.indexOf(b.value),
    )
    .concat(
      countries
        .filter((country) => !favoriteCountries.includes(country.value))
        .sort((a, b) => a.title.localeCompare(b.title)),
    );
});
const filteredSortedCountries = ref(sortedCountries.value);
const selectedCountry = computed(() => {
  return sortedCountries.value.find((c) => c.value === address.country);
});

function parseAddressValue(value: FieldValueDto | FieldValueEto) {
  const parsedAddress = JSON.parse(
    value.value?.valueJson,
  ) as FieldValueAddressV1;
  const addressParts: string[] = [];

  function parsePartOfAddress(part: keyof FieldValueAddressV1) {
    if (parsedAddress[part]) {
      addressParts.push(parsedAddress[part].toString());
    }
  }

  parsePartOfAddress("country");
  parsePartOfAddress("zipCode");
  parsePartOfAddress("province");
  parsePartOfAddress("city");
  parsePartOfAddress("street");

  return addressParts.join(" - ");
}

function search(event: AutoCompleteCompleteEvent) {
  const query = event.query;
  filteredSortedCountries.value =
    query.trim().length > 0
      ? sortedCountries.value.filter((option) =>
          option.title?.toLowerCase().includes(query.toLowerCase()),
        )
      : sortedCountries.value;
}

async function emitUpdate(closeCallback?: () => void) {
  const addressJson = JSON.stringify(address);

  emit("update", {
    ...props.fieldValue,
    value: {
      ...props.fieldValue.value,
      valueJson: addressJson,
    },
  });

  // This is a workaround to close the inplace component after the selection.
  // For now required until PrimeVue fixes it.
  await nextTick();
  setTimeout(() => closeCallback?.(), 300);
}
</script>

<template>
  <PInplace
    :pt="{
      root: { class: 'h-full' },
      display: { class: 'w-full h-full ' },
    }"
    @open="emit('editing', true)"
    @close="emit('editing', false)"
  >
    <template #display>
      <div class="flex flex-row items-start gap-2">
        <span
          class="mdi text-[2.3rem] opacity-90 mr-2 mt-2"
          :class="props.fieldKey.prefix ?? 'mdi-help'"
        ></span>

        <div class="flex flex-col">
          <p
            class="text-sm mb-1 block font-extrabold text-ellipsis overflow-hidden text-nowrap"
          >
            {{ props.label ?? "-" }}
          </p>

          <p
            class="block text-base text-ellipsis overflow-hidden"
            data-testid="field-value"
          >
            {{ addressReadOnly }}
          </p>
        </div>
      </div>
    </template>

    <template #content="{ closeCallback }">
      <BaseCard :pt="{ root: { class: 'h-full' } }">
        <template #title>
          <div class="flex flex-row justify-between">
            <p
              class="mt-2 text-sm font-bold block text-nowrap text-ellipsis overflow-hidden whitespace-nowrap"
            >
              {{ props.label ?? "-" }}
            </p>
            <div class="flex flex-row">
              <BaseButton
                v-if="!props.readonly"
                icon="mdi mdi-delete-outline"
                data-testid="delete-field-button"
                text
                severity="danger"
                @click="emit('delete')"
              />

              <BaseButton
                icon="mdi mdi-close"
                data-testid="close-field-card-button"
                text
                severity="secondary"
                @click="closeCallback"
              />
            </div>
          </div>
        </template>

        <div class="flex flex-col gap-4" @keydown.enter="closeCallback">
          <PAutoComplete
            dropdown
            optionLabel="title"
            size="small"
            data-testid="address-field-country-multiselect"
            :modelValue="selectedCountry"
            :suggestions="filteredSortedCountries"
            :virtualScrollerOptions="{
              itemSize: 40,
            }"
            @complete="search"
            @itemSelect="
              (event) => {
                address.country = event.value.value;
                emitUpdate();
              }
            "
          />

          <div class="flex flex-row gap-2">
            <BaseTextField
              class="w-3/5"
              data-testid="address-field-zip-input"
              type="number"
              :initialValue="address.zipCode?.toString()"
              :label="t('address.zipCode')"
              @update="
                (value) => {
                  address.zipCode = value;
                  emitUpdate();
                }
              "
            />

            <BaseTextField
              data-testid="address-field-city-input"
              :initialValue="address.city ?? undefined"
              :label="t('address.city')"
              @update="
                (value) => {
                  address.city = value;
                  emitUpdate();
                }
              "
            />
          </div>

          <BaseTextField
            data-testid="address-field-street-input"
            :initialValue="address.street ?? undefined"
            :label="t('address.street')"
            @update="
              (value) => {
                address.street = value;
                emitUpdate();
              }
            "
          />
        </div>
      </BaseCard>
    </template>
  </PInplace>
</template>
