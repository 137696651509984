import { useActivityInputStore } from "@/app/activity/activity/ActivityInputEntityStore.ts";
import { useActivityOutputStore } from "@/app/activity/activity/ActivityOutputEntityStore.ts";
import { useActivityTaskStore } from "@/app/activity/activity/ActivityTaskEntityStore.ts";
import {
  ActivityDocument,
  type ActivityDto,
  type ActivityEto,
  DeleteActivityDocument,
  GetActivitiesDocument,
  GetActivityDocument,
} from "@/base/graphql/generated/types.ts";
import {
  defineEntityStore,
  EntityProvider,
} from "@/base/stores/entity/EntityStore.ts";
import { useFieldValueStore } from "@/base/stores/field/FieldValueStore.ts";
import { PendingPersistedLookupDual } from "@/base/utils/IdRelationLookups.ts";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual(
    (eto: ActivityEto) => ({ id: eto.id }) as ActivityDto,
  ),
  {
    queryName: "activity",
    listQuery: GetActivitiesDocument,
    singleQuery: GetActivityDocument,
    mutation: ActivityDocument,
    deleteMutation: DeleteActivityDocument,
  },
  {
    indexFields: ["mid"],
    onAfterRegister: (etos) => {
      useActivityInputStore().registerAllLoaded(
        etos.flatMap((eto) => eto.inputs ?? []),
      );
      useActivityOutputStore().registerAllLoaded(
        etos.flatMap((eto) => eto.outputs ?? []),
      );
      useActivityTaskStore().registerAllLoaded(
        etos.flatMap((eto) => eto.tasks ?? []),
      );
      useFieldValueStore().registerAllLoaded(
        etos.flatMap((eto) => eto.fields ?? []),
      );
    },
  },
);

export const useActivityStore = defineEntityStore("activity", entityProvider);
