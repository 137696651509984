<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import ReadonlyField from "@/app/process/components/field/ReadonlyField.vue";
import LoadingIndicator from "@/base/components/loading/LoadingIndicator.vue";
import { type FileEto } from "@/base/graphql/generated/types.ts";
import { useFileService } from "@/base/services/file/FileService.ts";

const props = defineProps<{
  entityId: string;
  fileId: string | undefined;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
}>();

const { t } = useI18n();

const emit = defineEmits<(e: "update", value: string) => void>();

const fileStore = useFileService();

const pendingFile = ref<FileEto | undefined>();
const file = computed<File | undefined>(() => {
  if (pendingFile.value?.name) {
    // We need to create a dummy file in place to show the new file name in the file-input
    return new File([], pendingFile.value.name);
  }
  return fileStore.getById(props.entityId ?? "");
});

onMounted(async () => {
  await fileStore.fetch(props.entityId, props.fileId);
});

function emitUpdate(value: string) {
  emit("update", value);
}

async function downloadFile() {
  if (!props.fileId) {
    return;
  }
  await fileStore.download(props.fileId);
}

async function uploadFile(newFile: File | File[]) {
  if (newFile instanceof Array) {
    newFile = newFile[0];
  }

  const fileEto = await fileStore.upload(newFile);

  if (props.fileId) {
    fileStore.removeById(props.fileId);
  }

  if (!fileEto) {
    return;
  }

  pendingFile.value = fileEto;
  emitUpdate(fileEto.id);
}
</script>

<template>
  <ReadonlyField v-if="props.readonly" :label="props.label">
    <div class="d-flex w-100 toastui-editor-contents">
      <a @click="downloadFile">
        <LoadingIndicator v-if="!file" />
        <span v-else class="file-link">{{ file.name }}</span>
      </a>
    </div>
  </ReadonlyField>

  <VFileInput
    v-else
    class="w-100"
    :modelValue="file ? [file] : []"
    :loading="fileStore.isLoading"
    accept="*"
    :label="t('processes.singleView.outputTypes.FILE')"
    variant="outlined"
    density="compact"
    :centerAffix="true"
    :clearable="props.clearable"
    :hideDetails="true"
    prependIcon=""
    prependInnerIcon="mdi-paperclip"
    @update:modelValue="(files) => uploadFile(files)"
  />
</template>

<style scoped>
.file-link {
  color: rgb(var(--v-theme-caeli-link));
  text-decoration: underline;
  cursor: pointer;
}

.file-link:hover {
  color: darkblue;
}

.file-link:active {
  color: red;
}
</style>
