<script setup lang="ts">
import { v4 as uuidv4 } from "uuid";
import { computed, type ComputedRef } from "vue";
import { useI18n } from "vue-i18n";

import AppDialog from "@/base/components/dialog/AppDialog.vue";
import FilteredDataTable from "@/base/components/filterdatatable/FilteredDataTable.vue";
import { type RowItem } from "@/base/components/filterdatatable/TableTypes.ts";
import {
  type EntityType,
  type FieldKeyDto,
  type FieldValueEto,
} from "@/base/graphql/generated/types.ts";
import { useFieldService } from "@/base/services/FieldService.ts";
import { uniqueById } from "@/base/services/utils.ts";

const props = defineProps<{
  entityId: string;
  entityType: EntityType[];
}>();

const { t } = useI18n();

const fieldService = useFieldService();

const isFieldDialogOpen = computed(() => fieldService.isFieldDialogOpen);

const columnNames = {
  referenceName: t("fields.referenceName"),
  referenceType: t("fields.referenceType"),
  referenceUnique: t("fields.referenceUnique"),
};

const columnValueTranslation = {
  booleanYes: t("boolean.yes"),
  booleanNo: "-",
};

function addFieldValue(fieldKey: FieldKeyDto) {
  const eto: FieldValueEto = {
    id: uuidv4(),
    entityId: props.entityId,
    fieldKeyId: fieldKey.id,
  };
  fieldService.createOrUpdateFieldValue(eto);
  fieldService.closeFieldDialog();
}

const rowItems: ComputedRef<RowItem[]> = computed(() => {
  const keysForAllEntityTypes = props.entityType
    .flatMap((type) => fieldService.getNonTagFieldKeys(type))
    .filter(uniqueById);

  function isUniqueKeyAlreadyUsed(fieldKey: FieldKeyDto) {
    return fieldService.isUniqueKeyAlreadySet(fieldKey, props.entityId);
  }

  return keysForAllEntityTypes.map(
    (field) =>
      ({
        cells: {
          [columnNames.referenceName]: {
            content: field.name,
          },
          [columnNames.referenceUnique]: {
            content: field.unique
              ? columnValueTranslation.booleanYes
              : columnValueTranslation.booleanNo,
          },
          [columnNames.referenceType]: {
            content: field.type,
          },
          id: {
            content: field.id,
          },
        },
        style: {
          cursor: "pointer",
          pointerEvents: isUniqueKeyAlreadyUsed(field) ? "none" : "inherit",
          opacity: isUniqueKeyAlreadyUsed(field) ? "0.5" : "1",
        },
        tags: [],
        key: field.id,
        events: {
          click: () => {
            addFieldValue(field);
          },
        },
      }) as RowItem,
  );
});

const displayColumns = [
  columnNames.referenceName,
  columnNames.referenceUnique,
  columnNames.referenceType,
];
</script>

<template>
  <AppDialog
    v-model="isFieldDialogOpen"
    noConfirm
    data-testid="field-dialog"
    @cancel="fieldService.closeFieldDialog"
  >
    <VCardTitle>
      {{ t("action.addSomething", { name: t("processes.field") }) }}
    </VCardTitle>

    <FilteredDataTable
      :searchByTextColumns="displayColumns"
      :exposedColumns="displayColumns"
      contextKey="fieldDialog"
      :rowItems="rowItems"
      :availableTags="[]"
      :sortBy="[{ key: columnNames.referenceName, order: 'asc' }]"
    />
  </AppDialog>
</template>

<style scoped></style>
