<script setup lang="ts">
import { computed, useTemplateRef } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import DocumentParameterTable from "@/app/document/components/DocumentParameterTable.vue";
import GenerateDocumentDialog from "@/app/document/components/generation/GenerateDocumentDialog.vue";
import GenerateDocumentVariantDialog from "@/app/document/components/generation/GenerateDocumentVariantDialog.vue";
import { useDocumentService } from "@/app/document/services/DocumentService";
import DocumentFileField from "@/app/process/components/field/DocumentFileField.vue";
import ReadonlyField from "@/app/process/components/field/ReadonlyField.vue";
import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseCard from "@/base/components/card/BaseCard.vue";
import AppDialog from "@/base/components/dialog/AppDialog.vue";
import TextDisplay from "@/base/components/form/TextDisplay.vue";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import BaseTieredMenu from "@/base/components/tieredmenu/BaseTieredMenu.vue";
import { useTitle } from "@/base/composables/useTitle";
import { translateEnum } from "@/base/i18n/i18n";
import { UI_DIALOG, useDialogService } from "@/base/services/DialogService";

const props = defineProps<{
  documentId: string;
}>();

const documentService = useDocumentService();
const dialogService = useDialogService();
const router = useRouter();
const { t } = useI18n();

const document = computed(() => documentService.getById(props.documentId));

useTitle(document.value?.name ?? t("document.document", 1));

const actionsMenuRef = useTemplateRef("actionsMenuRef");
const actionsMenuItems = computed<BaseMenuItem[]>(() => [
  {
    testId: "document-edit",
    label: t("action.edit"),
    icon: "mdi mdi-pencil",
    command: navigateToEditPage,
  },
  {
    separator: true,
    class: "my-1 opacity-50",
  },
  {
    testId: "document-generate",
    label: t("document.generate"),
    icon: "mdi mdi-file-sign",
    disabled: !document.value,
    command: () => {
      if (!document.value) {
        return;
      }
      dialogService.openGenerateDocumentDialog(
        document.value.entityType,
        document.value.id,
      );
    },
  },
  {
    testId: "document-variant",
    label: t("document.generateVariant"),
    icon: "mdi mdi-source-branch-plus",
    disabled: !document.value,
    command: () => {
      if (!document.value) {
        return;
      }
      dialogService.openCreateDocumentVariantDialog(
        document.value.id,
        document.value.entityType,
        document.value.name,
      );
    },
  },
  {
    separator: true,
    class: "my-1 opacity-50",
  },
  {
    testId: "document-delete",
    label: t("action.delete"),
    icon: "mdi mdi-trash-can-outline",
    severity: "danger",
    command: () => dialogService.openDialog(UI_DIALOG.DELETE_DOCUMENT),
  },
]);

async function navigateToEditPage() {
  await router.push({
    name: "documentEdit",
    params: { documentId: props.documentId },
  });
}

const isDeleteDialogOpen = computed(() =>
  dialogService.isDialogOpen(UI_DIALOG.DELETE_DOCUMENT),
);
</script>

<template>
  <div class="bg-surface-50 h-screen">
    <AppDialog
      v-if="document"
      v-model="isDeleteDialogOpen"
      :title="t('document.deleteDocument')"
      :confirmMessage="t('action.delete')"
      :cancelMessage="t('action.cancel')"
      destructive
      @confirm="
        () => {
          documentService.deleteAndGoToList(props.documentId);
          dialogService.closeDialog(UI_DIALOG.DELETE_DOCUMENT);
        }
      "
      @cancel="dialogService.closeDialog(UI_DIALOG.DELETE_DOCUMENT)"
    >
      <p>
        {{
          t("document.deleteDocumentText", {
            name: document?.name,
          })
        }}
      </p>
    </AppDialog>

    <div v-if="document" class="flex flex-col gap-4 p-4!">
      <BaseCard>
        <template #title>
          <div class="flex flex-row justify-between">
            <p data-testid="document-view-name">
              {{ document.name ?? t("document.document") }}
            </p>

            <div class="flex flex-row gap-2">
              <BaseButton
                data-testid="actions-button"
                icon="mdi mdi-chevron-down"
                iconPos="right"
                :label="t('ui.actions')"
                @click="actionsMenuRef?.toggle($event)"
              />

              <BaseTieredMenu
                ref="actionsMenuRef"
                :model="actionsMenuItems"
                :pt="{
                  itemIcon: ({ context }) => ({
                    class:
                      context.item.severity === 'danger' ? 'text-red-500!' : '',
                  }),
                  itemLabel: ({ context }) => ({
                    class:
                      context.item.severity === 'danger' ? 'text-red-500!' : '',
                  }),
                  item: ({ context }) => ({
                    'data-testid': context.item.testId,
                  }),
                }"
              />
            </div>
          </div>
        </template>

        <div class="flex flex-col gap-2">
          <ReadonlyField
            :label="t('document.entityType')"
            :value="translateEnum('entityTypes', document.entityType)"
            data-testid="document-view-entity-type"
          />

          <ReadonlyField
            v-if="document.iterator"
            :label="t('document.iterator.label')"
            :value="document?.iterator ?? ''"
            data-testid="document-view-iterator"
          />

          <TextDisplay
            v-if="document.description"
            :label="t('document.description')"
            :value="document?.description ?? ''"
            data-testid="document-view-description"
            markdown
          />

          <ReadonlyField :label="t('document.file')">
            <DocumentFileField
              readonly
              :entityId="document.id"
              :fileId="document.templateFileId"
              data-testid="document-view-template-file"
            />
          </ReadonlyField>
        </div>
      </BaseCard>

      <BaseCard noPadding>
        <DocumentParameterTable :entityId="document.id" :editable="false" />
      </BaseCard>
    </div>

    <GenerateDocumentDialog />
    <GenerateDocumentVariantDialog />
  </div>
</template>
