<script setup lang="ts">
import dayjs from "dayjs";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import { usePersonService } from "@/app/person/services/PersonService.ts";
import TextDisplay from "@/base/components/form/TextDisplay.vue";
import LoadingIndicator from "@/base/components/loading/LoadingIndicator.vue";
import {
  type ActivityOutputDto,
  ActivityOutputType,
  type InputMaybe,
  type ProcessOutputDto,
  type ValueDto,
} from "@/base/graphql/generated/types.ts";
import { useFileService } from "@/base/services/file/FileService.ts";
import { extractDomain } from "@/base/utils/string.ts";

const props = defineProps<{
  activityOutput: ActivityOutputDto;
  processOutput: ProcessOutputDto | undefined;
  showName?: boolean;
}>();

const { t, n } = useI18n();

const personService = usePersonService();
const fileService = useFileService();

onMounted(async () => {
  const valueEntityId = props.processOutput?.value?.valueEntityId;
  if (
    !valueEntityId ||
    !isFileBasedActivityOutputType(props.activityOutput.type)
  ) {
    return;
  }
  fetchingFile.value = true;

  await fileService.fetch(valueEntityId, valueEntityId);
  fetchingFile.value = false;
});

function isFileBasedActivityOutputType(
  type: InputMaybe<ActivityOutputType> | undefined,
): type is ActivityOutputType {
  return (
    type === ActivityOutputType.File || type === ActivityOutputType.Document
  );
}

const fetchingFile = ref(false);
const file = computed(() => {
  const valueEntityId = props.processOutput?.value?.valueEntityId;
  if (!valueEntityId) {
    return null;
  }

  return fileService.getById(valueEntityId);
});

const textConverterByActivityOutputType = {
  [ActivityOutputType.Boolean]: (value: ValueDto | null | undefined) => {
    return value?.valueBoolean ? t("boolean.yes") : t("boolean.no");
  },
  [ActivityOutputType.Number]: (value: ValueDto | null | undefined) => {
    return value?.valueNumber ? n(value.valueNumber) : "";
  },
  [ActivityOutputType.Date]: (value: ValueDto | null | undefined) => {
    return value?.valueDate ? dayjs(value.valueDate).format("DD.MM.YYYY") : "";
  },
  [ActivityOutputType.DateTime]: (value: ValueDto | null | undefined) => {
    return value?.valueDateTime
      ? dayjs(value.valueDateTime).format("DD.MM.YYYY, hh:mm")
      : "";
  },
  [ActivityOutputType.Person]: (value: ValueDto | null | undefined) => {
    if (!value?.valueEntityId) {
      return "";
    }
    return personService.getById(value.valueEntityId)?.name ?? "";
  },
  [ActivityOutputType.Json]: (value: ValueDto | null | undefined) => {
    return value?.valueJson ? t("boolean.yes") : t("boolean.no");
  },
};

const processOutputValueText = computed((): string => {
  if (!props.processOutput) {
    return "";
  }

  const defaultText = props.processOutput.value?.valueString ?? "";
  if (props.activityOutput.type) {
    const textConverter =
      textConverterByActivityOutputType[props.activityOutput.type];
    return textConverter
      ? textConverter(props.processOutput.value)
      : defaultText;
  }

  return defaultText;
});

const outputNameAndMaybeValue = computed((): string => {
  const outputName = props.activityOutput.name ?? "";
  const valueText = processOutputValueText.value;
  return valueText ? `${outputName} = ${valueText}` : outputName;
});

async function downloadFile() {
  if (!props.processOutput?.value?.valueEntityId) {
    return;
  }

  await fileService.download(props.processOutput.value.valueEntityId);
}
</script>

<template>
  <div v-if="props.activityOutput.type === ActivityOutputType.String">
    <span v-if="props.showName">{{ props.activityOutput.name ?? "" }}</span>
    <TextDisplay
      :value="props.processOutput?.value?.valueString ?? ''"
      markdown
    />
  </div>

  <div v-else-if="props.activityOutput.type === ActivityOutputType.Url">
    <span v-if="!props.processOutput?.value?.valueString">{{
      props.showName ? props.activityOutput.name : ""
    }}</span>
    <div v-else class="d-flex align-center ga-1">
      <a
        class="toastui-editor-contents url"
        :href="props.processOutput?.value?.valueString"
        target="_blank"
        >{{
          extractDomain(
            props.processOutput?.value?.valueString,
            props.activityOutput.name ??
              t("processes.singleView.outputTypes.URL"),
          )
        }}
      </a>
      <VIcon icon="mdi-open-in-new" size="tiny" />
    </div>
  </div>

  <div v-else-if="props.activityOutput.type === ActivityOutputType.File">
    <a @click="downloadFile">
      <LoadingIndicator v-if="fetchingFile" />
      <span v-else class="file-link">{{ file?.name }}</span>
    </a>
  </div>

  <span v-else>
    {{ props.showName ? outputNameAndMaybeValue : processOutputValueText }}
  </span>
</template>

<style scoped>
.url {
  color: rgb(var(--v-theme-caeli-link));
  text-wrap: nowrap;
}

.file-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
</style>
