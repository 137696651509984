<script lang="ts" setup>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import StartProcessButton from "@/app/process/components/action/start/StartProcessButton.vue";
import BaseButton from "@/base/components/button/BaseButton.vue";
import FloatingActionButton from "@/base/components/button/FloatingActionButton.vue";

const { t } = useI18n();

const activityService = useActivityService();

const dialog = ref(false);
const processName = ref("");

const props = defineProps<{
  rootActivityId: string;
  floating?: boolean;
}>();

const rootActivity = computed(() =>
  activityService.getActivity(props.rootActivityId),
);
</script>

<template>
  <VTooltip
    v-if="props.floating"
    location="start"
    :text="t('processes.startButton.startProcess')"
  >
    <template #activator="{ props: activator }">
      <FloatingActionButton
        v-bind="activator"
        icon="mdi-play"
        data-testid="start-process-button"
        @click="
          () => {
            processName = '';
            dialog = true;
          }
        "
      />
    </template>
  </VTooltip>

  <BaseButton
    v-else
    v-tooltip.left="t('processes.startButton.startProcess')"
    data-testid="start-process-button"
    variant="text"
    severity="contrast"
    icon="mdi mdi-play"
    @click="
      () => {
        processName = '';
        dialog = true;
      }
    "
  />

  <VDialog v-model="dialog" width="600" data-testid="start-process-dialog">
    <VCard>
      <VCardTitle class="text-wrap"
        >{{
          t("processes.startButton.startNamedProcess", {
            name: rootActivity?.name ?? "",
          })
        }}
      </VCardTitle>
      <VCardText>
        <VTextField
          v-model="processName"
          density="compact"
          variant="outlined"
          :label="t('processes.startButton.nameLabel')"
          hideDetails="auto"
        />
      </VCardText>
      <VCardActions class="d-flex flex-column">
        <StartProcessButton
          :processName="processName"
          :rootActivityId="rootActivityId"
        />
        <VBtn
          variant="flat"
          block
          @click.stop.prevent="
            () => {
              dialog = false;
            }
          "
          >{{ t("action.cancel") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
