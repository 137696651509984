import { DataTableContextKeys } from "@/base/components/filterdatatable/FilteredDataTableUtil.ts";
import {
  GetMessageDocument,
  MessageDeleteDocument,
  MessageDocument,
  type MessageDto,
  type MessageEto,
} from "@/base/graphql/generated/types.ts";
import { defineEntityStoreV2 } from "@/base/stores/entity/EntityStoreV2.ts";

export const useMessageStoreV2 = defineEntityStoreV2<MessageEto, MessageDto>({
  key: "MessageStoreV2",
  singleViewRouteParam: "messageId",
  contextKey: DataTableContextKeys.messageList,
  listQuery: GetMessageDocument,
  singleQuery: GetMessageDocument,
  createOrUpdateMutation: MessageDocument,
  deleteMutation: MessageDeleteDocument,
});
