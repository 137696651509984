<script lang="ts" setup>
import Column from "primevue/column";
import TreeTable from "primevue/treetable";
import { v4 as uuidv4 } from "uuid";
import { computed, ref, useTemplateRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useDocumentService } from "@/app/document/services/DocumentService";
import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseCard from "@/base/components/card/BaseCard.vue";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import BaseTieredMenu from "@/base/components/tieredmenu/BaseTieredMenu.vue";
import { useTitle } from "@/base/composables/useTitle";

const { t } = useI18n();
const router = useRouter();
const documentService = useDocumentService();

useTitle(t("document.document", 2));

const actionsMenuRef = useTemplateRef("actionsMenuRef");
const actionsMenuItems = computed<BaseMenuItem[]>(() => [
  {
    testId: "create-document",
    label: t("document.create"),
    icon: "mdi mdi-plus",
    command: createDocumentClicked,
  },
]);

const treeNodes = computed(() => documentService.getTreeNodes());

const expandedKeys = ref({});
const expandTreeNodeIfDocumentFound = (treeNode, document): boolean => {
  if (treeNode.key === document.id) {
    expandedKeys.value[treeNode.key] = true;
    return true;
  } else {
    const childExpanded = treeNode.children.some((child) =>
      expandTreeNodeIfDocumentFound(child, document),
    );
    if (childExpanded) {
      expandedKeys.value[treeNode.key] = true;
    }
    return childExpanded;
  }
};
watch(
  computed(() => documentService.documentVariantCreationResult),
  (value) => {
    if (value.document) {
      treeNodes.value.forEach((node) =>
        expandTreeNodeIfDocumentFound(node, value.document),
      );
      delete value.document;
    }
  },
);

const initialColumns = [
  {
    key: "name",
    fieldName: "name",
    expander: true,
    displayHeader: t("document.name"),
  },
  {
    key: "entityType",
    fieldName: "entityType",
    expander: false,
    displayHeader: t("document.entityType"),
  },
];

const createDocumentClicked = async () => {
  await router.push({
    name: "documentEdit",
    params: { documentId: uuidv4() },
  });
};
</script>

<template>
  <div class="h-screen">
    <BaseCard class="m-4!" flat>
      <template #title>
        <div class="flex flex-row justify-between">
          <p>{{ t("document.document", 2) }}</p>

          <BaseButton
            data-testid="actions-button"
            icon="mdi mdi-chevron-down"
            iconPos="right"
            :label="t('processes.singleView.actions.buttonTitle')"
            @click="actionsMenuRef?.toggle($event)"
          />

          <BaseTieredMenu
            ref="actionsMenuRef"
            :model="actionsMenuItems"
            :pt="{
              item: ({ context }) => ({
                'data-testid': context.item.testId,
              }),
            }"
          />
        </div>
      </template>

      <TreeTable
        v-model:expandedKeys="expandedKeys"
        rowHover
        size="small"
        selectionMode="single"
        :value="treeNodes"
      >
        <Column
          v-for="col of initialColumns"
          :key="col.key"
          :expander="col.expander"
          :pt="{
            columnHeaderContent: {
              class: 'h-6 text-[#3C707B] text-nowrap',
            },
            columnTitle: { class: 'grow' },
          }"
        >
          <template #header>
            <p class="pl-1 font-bold text-sm">{{ col.displayHeader }}</p>
          </template>

          <template #body="{ node }">
            <RouterLink
              class="w-full"
              :to="{ name: 'documentView', params: { documentId: node.key } }"
            >
              <p class="text-sm cursor-pointer">
                {{ node.data[col.fieldName] }}
              </p>
            </RouterLink>
          </template>
        </Column>
      </TreeTable>
    </BaseCard>
  </div>
</template>
