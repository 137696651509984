<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import {
  GenerationStatusEnum,
  useDocumentService,
} from "@/app/document/services/DocumentService.ts";
import { UI_DIALOG, useDialogService } from "@/base/services/DialogService.ts";

const documentService = useDocumentService();
const dialogService = useDialogService();

const { t } = useI18n();
const getVariantNameDefault = (originDocumentName: string) =>
  `${originDocumentName} - ${t("document.generateVariantDialog.variantNameInputDefaultValueSuffix")}`;

const variantName = ref<string>("");
watch(
  computed(() => dialogService.dialogEntityFileName),
  (value) => (variantName.value = getVariantNameDefault(value)),
);

const isDialogOpen = computed(() =>
  dialogService.isDialogOpen(UI_DIALOG.GENERATE_DOCUMENT_VARIANT),
);

const closeDialog = () => {
  dialogService.closeDialog(UI_DIALOG.GENERATE_DOCUMENT_VARIANT);
  documentService.resetVariantGeneration();
  variantName.value = getVariantNameDefault(dialogService.dialogEntityFileName);
};
const startVariantGeneration = async () => {
  await documentService.startVariantGeneration(
    dialogService.dialogEntityId ?? "",
    variantName.value,
  );
  closeDialog();
};
</script>

<template>
  <VDialog v-model="isDialogOpen" maxWidth="600">
    <VCard class="pa-4">
      <VCardTitle class="headline"
        >{{ t("document.generateVariantDialog.title") }}
      </VCardTitle>
      <VCardText>
        <p class="mb-4">
          {{
            t("document.generateVariantDialog.description", {
              fileName: dialogService.dialogEntityFileName,
            })
          }}
        </p>

        <VTextField
          v-model="variantName"
          :label="t('document.generateVariantDialog.variantNameInputLabel')"
          variant="outlined"
          density="compact"
        />
        <div
          v-if="documentService.generateStatus === GenerationStatusEnum.WARN"
          class="d-flex align-center mt-4 warn-alert"
        >
          <VIcon color="orange" class="mr-2" icon="mdi-alert" />
          {{
            t("document.generateVariantDialog.warn", {
              amount: documentService.documentGenerationRun?.results.length,
            })
          }}
        </div>
        <div
          v-if="documentService.generateStatus === GenerationStatusEnum.ERROR"
          class="d-flex align-center mt-4 error-alert"
        >
          <VIcon color="red" class="mr-2" icon="mdi-alert" />
          {{
            t("document.generateVariantDialog.error", {
              amount: documentService.documentGenerationRun?.results.length,
            })
          }}
        </div>

        <VTextarea
          v-if="documentService.validationMessages"
          v-model="documentService.validationMessages"
          readonly
          rows="4"
          class="mt-4 enhanced-textarea"
        />
      </VCardText>
      <VCardActions class="d-flex justify-space-between">
        <VBtn
          color="secondary"
          data-testid="document-dialog-close"
          outlined
          @click="closeDialog"
        >
          {{ t("action.close") }}
        </VBtn>
        <VBtn
          v-if="!documentService.generateStatus"
          :loading="documentService.isGenerating"
          color="primary"
          data-testid="generate-document-dialog-button"
          variant="elevated"
          class="ml-auto"
          @click="startVariantGeneration"
        >
          <template v-if="documentService.isGenerating">
            <VIcon class="mdi mdi-loading mdi-spin mr-2"></VIcon>
          </template>
          {{ t("document.generateVariantDialog.generate") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<style scoped>
.error-alert {
  background-color: #ffebee;
  border-radius: 4px;
  padding: 10px;
}

.warn-alert {
  background-color: #fff3e0;
  border-radius: 4px;
  padding: 10px;
}
</style>
