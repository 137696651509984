<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";

import AppHeader from "@/base/components/header/AppHeader.vue";
import AppMenu from "@/base/components/navigation/AppMenu.vue";

const router = useRouter();

const oldRoutes = [
  "dashboard",
  "activity",
  "customActivity",
  "processActivity",
  "personEdit",
  "personView",
  "messageEdit",
  "messageView",
];

// TODO: Remove this after the removal of Vuetify
const fallbackColor = computed(() => {
  const currentRoute = router.currentRoute.value.name?.toString();
  if (currentRoute && oldRoutes.includes(currentRoute)) {
    return "bg-surface-50!";
  }
  return "bg-surface-0!";
});
</script>

<template>
  <div class="flex flex-row min-h-0 h-screen">
    <AppMenu class="h-screen" />

    <div class="flex flex-col w-full overflow-auto">
      <AppHeader />

      <div class="flex-grow min-h-0 overflow-auto" :class="fallbackColor">
        <slot />
      </div>
    </div>
  </div>
</template>
